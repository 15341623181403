//	Header

.sticky-header {
    background-color: #fff;
    //border-bottom: 1px solid rgba(#000, .05);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    padding: 15px 0;
    transition: background-color $time;

    @media screen and (max-width: 1023px) {
        &.is-open {
            background-color: #fff;
            z-index: 10000;

            .nav-wrap {
                max-height: 600px;
            }

        }
    }

}

.logo {
    @extend %ibt;
    max-width: 100px;
    margin-top: -4px;
    margin-bottom: -4px;

    a {
        display: block;
        position: relative;
    }

    strong {
        position: absolute;
        left: -100000px;
        top: 0;
    }

    img {
        display: block;
        visibility: hidden;
    }
}

.nav {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    @media screen and(max-width: 1000px) {
        right: 15px;
    }

    .nav-trigger {
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateY(-40px);
        z-index: 3;
        width: 24px;
        height: 24px;
        overflow: hidden;

        span {
            display: block;
            position: absolute;
            top: 14px;
            left: 0;
            right: 0;
            height: 1px;
            background-color: rgba(#fff, .6);

            &:after,
            &:before {
                @extend %before;
                width: 100%;
                height: 1px;
                background-color: rgba(#fff, .6);
                transition-duration: 0.1s, 0.1s;
                transition-delay: 0.1s, 0s;
            }

            &:before {
                top: -6px;
                transition-property: top, transform;
            }

            &:after {
                bottom: -6px;
                transition-property: bottom, transform;
            }

        }

        &.is-active {
            span {
                background-color: rgba(#fff, 0);
                &:before {
                    top: 0;
                    transform: rotate(45deg);
                }
                &:after {
                    bottom: 0;
                    transform: rotate(-45deg);
                }
                &:before,
                &:after {
                    transition-delay: 0s, 0.1s;
                }
            }
        }

        //svg {
        //    fill: rgba(#fff, .6);
        //    width: 30px;
        //    height: 30px;
        //    transition: fill $time;
        //}

        //i {
        //    font-size: 30px;
        //    color: rgba(#fff, .6);
        //    transition: color $time;
        //}

        //&:active {
            //svg {
            //    fill: rgba(#fff, .7);
            //}
            //i {
            //    color: rgba(#fff, .7);
            //}
        //}

    }

    ul {
        @extend %tabl;
        width: auto;

        ul {
            display: block;
            margin: 0;
            width: 100%;

            li {
                display: block;
                margin: 0;
            }
        }
    }

    li {
        @extend %ibt;
        margin-left: 20px;
        font-size: 14px;

        &.login-user-margin {
            margin-left: 90px;
        }
    }

    a {
        color: #fff;
        display: block;
        height: 40px;
        line-height: 40px;
        position: relative;

        &.button-blue {
            height: 30px;
            line-height: 30px;
            margin-top: 5px;
            padding: 0;
            width: 130px;
            text-align: center;
        }

        i {
            font-size: 21px;
            width: 21px;
            text-align: center;
            margin: 0 8px;
            color: rgba(#fff, .5);
            vertical-align: top;
            transition: fill $time;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }

        &.nav-link-fave {
            i {
                color: $blue;
                top: 4px;
            }
            ins {
                display: block;
                position: absolute;
                top: 4px;
                right: 0;
                width: 14px;
                height: 14px;
                border-radius: 7px;
                background-color: $blue;
                line-height: 14px;
                text-align: center;
                text-decoration: none;
                font-size: 9px;
            }
        }
    }

    .select-lang {
        position: absolute;
        top: 50%;
        width: 60px;
        margin-top: -20px;
        right: calc(1000px - 240px);
        @extend %ibt;

        //@media screen and (max-width: 1000px) {
        //    right: calc(100vw - 310px);
        //}

        &:before {
            @extend %before;
            top: 50%;
            left: -17px;
            height: 38px;
            margin-top: -19px;
            width: 1px;
            background-color: rgba(#fff, .2);
        }

        .dt-is-list {
            border: 0;
            top: 40px;
            right: 18px;

            ul {
                width: 100%;
                box-shadow: 0 1px 5px rgba(#000, .3);
            }

            li {
                margin-left: 0;
                display: block;

                a {
                    font-size: 13px;
                }
                &:first-child {
                    a {
                        &:before,
                        &:after {
                            display: none;
                        }
                    }
                }
                @media screen {
                    a {
                        &:hover {
                            background-color: #f9f9f9 !important;
                        }
                    }
                }
            }

            &:after {
                @extend %before;
                border: 0;
                height: 2px;
                width: 100%;
                left: 0;
                top: -2px;
                background-color: $blue;
            }

            &:before {
                @extend %before;
                border-style: solid;
                border-width: 0 7px 6px 7px;
                border-color: rgba(#000, 0) rgba(#000, 0) $blue rgba(#000, 0);
                top: -7px;
                right: 5px;
                z-index: 10;
            }
        }
    }

    .select-inbox {

        .dt-is-trigger {
            height: 40px;
            width: 40px;
            position: relative;

            svg {
                width: 21px;
                height: 21px;
                margin: -11px 0 0 -11px;
                fill: rgba(#fff, .5);
                vertical-align: top;
                transition: fill $time;
                position: absolute;
                top: 50%;
                left: 50%;
            }

            ins {
                display: block;
                position: absolute;
                top: 4px;
                right: 0;
                width: 14px;
                height: 14px;
                border-radius: 7px;
                background-color: $blue;
                line-height: 14px;
                text-align: center;
                text-decoration: none;
                font-size: 9px;
                padding-left: 1px;
            }
        }

        .dt-is-list {
            left: auto;
            right: 0;
            width: 380px;
            top: 40px;

            li {
                display: block;
                position: relative;
                padding: 0;
                border-top: 1px solid lighten(#eee, 5%);

                &:first-child {
                    font-size: 14px;
                    color: rgba($base, .7);
                    background-color: #eee;
                    border-top: 0;
                    padding: 0 10px;

                    ins {
                        text-decoration: none;
                        font-style: normal;
                    }

                    &:before {
                        @extend %before;
                        border-style: solid;
                        border-width: 0 7px 6px 7px;
                        border-color: rgba(#000, 0) rgba(#000, 0) rgba(#000, .01) rgba(#000, 0);
                        top: -6px;
                        right: 11px;
                        z-index: 10;
                    }
                    &:after {
                        @extend %before;
                        border-style: solid;
                        border-width: 0 7px 6px 7px;
                        border-color: rgba(#000, 0) rgba(#000, 0) #eee rgba(#000, 0) !important;
                        top: -5px;
                        right: 11px;
                        z-index: 11;
                        transition: border-color $time;
                    }
                }

                .dt-is-end {
                    margin-left: -1px;
                    margin-right: -1px;
                    margin-bottom: -1px;
                    background-color: #40454c;
                    color: rgba(#fff, .6);
                    transition: color $time, background-color $time;
                }

            }

        }

        .si-message-item {
            @include row-grid(0);
            height: 53px;
            position: relative;
            line-height: 1;

            figure {
                display: inline-block;
                vertical-align: top;
                width: 228px;
                margin-left: 10px;

                img {
                    position: absolute;
                    top: 0;
                    left: 10px;
                    width: 53px;
                    height: 53px;

                    & + figcaption {
                        padding-left: 68px;
                        display: block;
                    }
                }

                figcaption {
                    padding: 12px 0 10px;

                    h5 {
                        font-weight: 700;
                        font-size: 14px;
                        margin-bottom: 5px;
                        color: #575757;
                    }
                    p {
                        font-size: 12px;
                        color: rgba(#9b9b9b, .6);
                    }
                }
            }

            .simi-link {
                display: inline-block;
                vertical-align: top;
                width: 130px;
                height: 53px;
                margin-left: 10px;
                line-height: 1;
                position: relative;

                ins {
                    text-decoration: none;
                    color: $blue;
                    font-size: 12px;
                    border-bottom: 1px dashed;
                    display: inline-block;
                    line-height: 1.2;
                    transition: color $time;
                    position: relative;
                    top: 50%;
                    transform: translateY(-6px);

                    &:hover {
                        color: darken($blue, 20%);
                    }
                }

                span {
                    position: absolute;
                    top: 50%;
                    left: 60px;
                    transform: translateY(-50%);
                    font-size: 11px;
                    color: #9b9b9b;
                    visibility: hidden;
                }

                &:hover {
                    span {
                        visibility: visible;
                    }
                }

            }

        }

    }

    .select-help {

        button {
            line-height: 40px;
        }

        .dt-is-list {
            left: -100px;
            border: 0;
            top: 40px;

            ul {
                box-shadow: 0 1px 5px rgba(#000, .3);
            }

            li {
                a {
                    font-size: 13px;
                }
                &:first-child {
                    a {
                        &:before,
                        &:after {
                            display: none;
                        }
                    }
                }
                @media screen {
                    a {
                        &:hover {
                            background-color: #f9f9f9 !important;
                        }
                    }
                }
            }

            &:after {
                @extend %before;
                border: 0;
                height: 2px;
                width: 100%;
                left: 0;
                top: -2px;
                background-color: $blue;
            }

            &:before {
                @extend %before;
                border-style: solid;
                border-width: 0 7px 6px 7px;
                border-color: rgba(#000, 0) rgba(#000, 0) $blue rgba(#000, 0);
                top: -7px;
                right: 5px;
                z-index: 10;
            }
        }
    }

    .select-user-zone {
        position: absolute;
        right: 150px;
        top: 0;

        .dt-is-trigger {
            img {
                height: 34px;
                width: 34px;
                display: inline-block;
                vertical-align: middle;
                border-radius: 17px;
                object-fit: cover;
                border: 2px solid rgba(#fff, .6);
            }

            .icon-new_user {
                font-size: 24px;
                //vertical-align: top;
                //position: relative;
                //top: -2px;

                & + i {
                    position: relative;
                    top: -4px;
                }
            }

            //svg {
            //    &.user-svg {
            //        width: 24px;
            //        height: 24px;
            //        vertical-align: top;
            //        position: relative;
            //        top: -2px;
            //    }
            //}
        }

        .dt-is-list {
            //left: -3px;
            left: -10px;
            min-width: 160px;
            border: 0;
            top: 51px;

            ul {
                box-shadow: 0 1px 3px rgba(#000, .3);
                padding: 0 10px;
            }

            li {
                a {
                    font-size: 13px;

                    &.dt-is-end {
                        border-top: 2px solid #f9f9f9;
                    }
                }
                &:first-child {
                    a {
                        &:before,
                        &:after {
                            display: none;
                        }
                    }
                }
                @media screen {
                    a {
                        &:hover {
                            background-color: #fff !important;

                            &.dt-is-end {
                                color: $mblue;
                            }
                        }
                    }
                }
            }

            &:after {
                @extend %before;
                border: 0;
                height: 2px;
                width: 100%;
                left: 0;
                top: -2px;
                background-color: $blue;
            }

            &:before {
                @extend %before;
                border-style: solid;
                border-width: 0 7px 6px 7px;
                border-color: rgba(#000, 0) rgba(#000, 0) $blue rgba(#000, 0);
                top: -7px;
                right: initial;
                left: 15px;
                z-index: 10;
            }
        }

        ul {
            display: block;

            li {
                margin: 0;
            }
        }
    }

}

.bg-white {

    .sticky-header {
        background-color: #fff;
        padding: 15px 0;
        height: 63px;

        .container {
            @extend %clear;
        }

        &.is-open {
            height: initial;
        }
    }

    nav {

        .nav-trigger {
            span {
                background-color: #3d3d3c;

                &:before,
                &:after {
                    background-color: #3d3d3c;
                }
            }
        }

        .link-hover {
            a {
                color: #3d3d3c;

                &:before {
                    background-color: rgba(#3d3d3c, .6) !important;
                }
            }
        }
    }

    .nav-link-fave {
        i {
            top: 4px;
        }
        &:hover {
            i {
                    color: #1d1d1d;
            }
        }
    }

    .dropdown-type {

        .dt-is-trigger {
            color: #3d3d3c;

            svg {
                fill: #3d3d3c;

                & + ins {
                    color: #fff;
                }
            }

            i {
                color: #3d3d3c;

                &.icon-arrow_down {
                    color: #3d3d3c;
                }

                & + ins {
                    color: #fff;
                }
            }

            span:before {
                background-color: rgba(#3d3d3c, .6) !important;
            }
        }

        &.select-inbox {
            .dt-is-trigger {
                svg {
                    fill: #3d3d3c;
                }
                i {
                    color: #3d3d3c;
                }
                &:hover {
                    svg {
                        fill: #1d1d1d;
                    }
                    i {
                        color: #1d1d1d;
                    }
                }
            }
            .dt-is-list li:first-child {
                &:before {
                    border-bottom-color: rgba(0, 0, 0, .2);
                }
                &:after {
                    border-bottom-color: #eee;
                }
            }

        }

        .dt-is-list {
            li:first-child a:before {
                border-bottom-color: rgba(#000, .2);
            }
        }
    }

    .logo {
        background: url(../../img/logo-new-bg.png) no-repeat;
        background-size: cover;
        width: 100px;
        height: 40px;
    }

    .select-lang {
        &:before {
            height: 63px;
            margin-top: -31px;
            background-color: #eee;
        }
    }

}

.bg-opacity {

    .sticky-header {
        background-color: rgba(#000, 0);
        //border-bottom: 1px solid rgba(#000, 0);
        margin-bottom: -63px;
        position: relative;
        top: initial;
        left: initial;

        .container {
            @extend %clear;
        }

        &.is-open {
            background-color: #fff;
        }
    }

    .dt-is-list-arrow {
        &:before {
            border-bottom-color: rgba(#000, .01);
        }
    }

    .logo {
        background: url(../../img/logo-new.png) no-repeat;
        background-size: cover;
    }

    &.bg-white {
        .sticky-header {
            padding: 15px 0;
            height: initial;
            transition-duration: 0s;
        }

        .logo {
            background: url(../../img/logo-new-bg.png) no-repeat;
            background-size: cover;
        }
    }

}

@media screen and (max-width: 1023px) {

    .nav {
        position: relative;
        float: right;
        width: 100%;
        top: 0;
        right: auto;
        transform: translateY(10px);

        .select-help {
            display: inline-block;
            min-width: 160px;
            height: initial;

            .dt-is-trigger {
                width: 100%;
                text-align: left;

                svg {
                    display: none;
                    //position: absolute;
                    //top: 50%;
                    //right: 0;
                    //margin-top: -4px;
                }

            }

            .dt-is-list {
                left: 0;
                opacity: 1;
                visibility: visible;
                transform: none;
                position: relative;
                right: initial;
                top: 0;

                ul {
                    box-shadow: none;
                }

                &:after,
                &:before {
                    display: none;
                }
            }
        }

        .nav-trigger {
            display: block;
        }

        ul {
            display: none;
        }

        .nav-wrap {
            transform: none;
            //opacity: 0;
            //visibility: hidden;
            //transform-origin: top;
            //transition: transform $time, visibility $time, opacity $time;
            text-align: left;
            max-height: 0;
            overflow: hidden;
        }

        .select-lang {
            display: none;
            margin-top: 0;
            right: -20px;
            top: 9px;

            &:before {
                display: none;
            }
            .dt-is-list {
                a {
                    display: block;
                }
            }
            //.dt-is-trigger {
            //    svg {
            //        margin-left: 30px;
            //    }
            //}
        }

        .select-user-zone {
            right: 45px;
            top: -45px;

            .dt-is-trigger {
                img {
                    margin-right: 4px;
                }
                //span {
                //    display: inline-block;
                //    min-width: 95px;
                //    text-align: left;
                //}
            }

            .dt-is-list {
                left: -60px;
                z-index: 10;

                &:before {
                    left: 73px;
                }
            }

            a {
                display: block !important;
            }
        }

        .select-inbox {
            .dt-is-list {
                //width: 285px;
                left: 0;

                li:first-child {
                    &:before {
                        right: auto;
                        left: 10px;
                    }
                    &:after {
                        right: auto;
                        left: 10px;
                    }
                }
            }
            li {
                text-align: left;
            }
            .si-message-item {
                figure {
                    width: 205px;
                }
                .simi-link {
                    width: 60px;
                    margin-left: 5px;

                    &:hover {
                        background-color: #fff;
                    }

                    span {
                        display: none;
                    }
                }
            }
        }

        &.is-open {
            margin-bottom: 10px;
            padding-top: 10px;
            margin-top: 4px;

            .nav-trigger {
                svg {
                    fill: #fff;
                }
            }

            ul {
                display: table;
                width: 100%;
                text-align: left;

                li {
                    margin-left: 0;
                    display: block;

                    &.link-hover {
                        display: inline-block;
                        width: auto;
                        margin-right: 15px;
                    }

                    a {
                        display: inline-block;

                        &.button-blue {
                            margin-top: 10px;
                        }
                    }
                }
                ul {
                    display: block;
                    li a {
                        display: block;
                    }
                }
            }

            .select-lang {
                //display: inline-block;
                //position: relative;
                //top: 0;
                //left: auto;
                //right: auto;
                //margin-top: 0;
                display: block;
            }

            //.select-user-zone {
            //    position: relative;
            //    top: 0;
            //    right: auto;
            //}

        }

    }

}

@media screen and (max-width: 480px) {
    .nav {
        .select-inbox {
            .dt-is-list {
                width: 285px;
            }
            .si-message-item {
                figure {
                    width: 205px;
                }
                .simi-link {
                    width: 60px;
                    margin-left: 5px;

                    &:hover {
                        background-color: #fff;
                    }

                    span {
                        display: none;
                    }
                }
            }
        }

    }
}

@-moz-document url-prefix(){
    .nav .nav-link-fave svg {
        transform: none;
        top: 0;
        vertical-align: middle;
    }
    .nav .select-help .dt-is-list:before {
        right: 9px;
    }
    .nav .select-lang .dt-is-list:before {
        right: 2px;
    }
}