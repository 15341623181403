//////	Filter page

.section-filter {
    min-height: 600px;
    background-color: #f3f3f3;

    &.section-super-filter {
        padding: 15px 0;
        border-top: 1px solid #ededed;
        min-height: initial;
        width: 100%;
        //display: none;
        position: relative;
        z-index: 50;
        background-color: #fff;
    }

    @media screen and (max-width: 1000px) {
        width: 100%;
        padding-right: 0;
    }

    .post-row {

        .post-excerpt {
            @include grid-item(4, 20px, 20px);
        }

        @media screen and (max-width: 1050px) {

            .post-excerpt {
                @include grid-item(3, 20px, 20px);
            }
        }

        @media screen and (max-width: 800px) {
            @include row-grid(20px);

            .post-excerpt {
                @include grid-item(2, 20px, 20px);
                margin-top: 0;
            }
        }

        @media screen and (max-width: 530px) {
            @include row-grid(0);
            text-align: left;

            .post-excerpt {
                @include grid-item(1, 20px, 20px);
                width: 335px;
                text-align: left;
                margin-left: 0;
            }
        }

        @media screen and (max-width: 374px) {
            .post-excerpt {
                width: 100%;
            }
        }

    }

    .search-params {
        padding: 15px 0;
        position: relative;
        z-index: 100;
    }

    .button-params {
        border: 1px solid #ccc;
    }

    @media screen and (max-width: 700px) {
        .select-type-guest {
            margin-bottom: 20px;
        }
        .search-params {
            padding: 15px 0 0;
        }
    }

}

.section-filter-header {
    padding: 30px 210px 25px 0;
    position: relative;
    z-index: 6;

    h1 {
        font-size: 24px;
        line-height: 30px;
        font-weight: 300;
        color: $base;
        display: inline;
        margin-right: 10px;
    }

    p {
        font-weight: 300;
        font-size: 16px;
        color: #575757;
        line-height: 30px;
        display: inline-block;

        span {
            color: $blue;
        }
    }

    .dropdown-type {
        position: absolute;
        top: 30px;
        right: 0;
        height: 28px;
        line-height: 28px;
        z-index: 5;

        .dt-is-trigger {
            background-color: #fff;
            color: rgba($text_color, .6);
            height: 28px;
            padding: 0 15px;
            font-size: 12px;

            span {
                line-height: 28px;
            }

            svg {
                fill: rgba($text_color, .6);
            }

            i {
                &.icon-arrow_down {
                    color: #6e6e6e;
                }
            }
        }

        .dt-is-list {
            right: 0;
            left: 0;
            font-size: 12px;

            a {
                &.checked {
                    color: $blue;
                }
            }
        }

    }

    .breadcrumbs {
        padding: 20px 0 0;

        a {
            color: $blue;
            font-size: 14px;
        }

        span {
            display: inline-block;
            vertical-align: top;
            padding-left: 20px;
            position: relative;

            &:before {
                @extend %before;
                top: 2px;
                left: 8px;
                width: 4px;
                height: 13px;
                opacity: .6;
                background-size: contain;
                background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTAiIGhlaWdodD0iMjgiIHZpZXdCb3g9IjAgMCAxMCAyOCI+PHBhdGggZmlsbD0iIzQ0NDQ0NCIgZD0iTTkuMjk3IDE1cTAgMC4yMDMtMC4xNTYgMC4zNTlsLTcuMjgxIDcuMjgxcS0wLjE1NiAwLjE1Ni0wLjM1OSAwLjE1NnQtMC4zNTktMC4xNTZsLTAuNzgxLTAuNzgxcS0wLjE1Ni0wLjE1Ni0wLjE1Ni0wLjM1OXQwLjE1Ni0wLjM1OWw2LjE0MS02LjE0MS02LjE0MS02LjE0MXEtMC4xNTYtMC4xNTYtMC4xNTYtMC4zNTl0MC4xNTYtMC4zNTlsMC43ODEtMC43ODFxMC4xNTYtMC4xNTYgMC4zNTktMC4xNTZ0MC4zNTkgMC4xNTZsNy4yODEgNy4yODFxMC4xNTYgMC4xNTYgMC4xNTYgMC4zNTl6Ij48L3BhdGg+PC9zdmc+);
            }

            span {
                padding: 0;
                &:before {
                    display: none;
                }
            }

            &:first-child {
                padding-left: 0;

                &:before {
                    display: none;
                }

                a {
                    color: $blue;

                    &:hover {
                        color: darken($blue, 10%);
                    }
                }
            }

            &:last-child {
                a {
                    color: $base;
                }
            }
        }

    }

    @media screen and (max-width: 570px) {
        padding-right: 0;

        .dropdown-type {
            position: relative;
            top: auto;
            right: auto;
            margin-top: 15px;
            max-width: 45%;

            .dt-is-trigger {
                width: 100%;
                text-align: left;

                svg {
                    position: absolute;
                    top: 50%;
                    right: 8px;
                    margin-top: -4px;
                }
            }
        }

    }

    @media screen and (max-width: 355px) {
        h1{
            display: block;
            margin-bottom: 15px;
        }
        .dropdown-type {
            max-width: 100%;
        }
    }

}

.filter-section-cat {
    padding-top: 15px;
    padding-bottom: 5px;
    position: relative;
    z-index: 10;
    border-top: 1px solid #eee;
}

.filter-detail {
    @include row-grid(0);

    .dropdown-detail {
        &:empty {
            display: none;
        }

        .checkbox-list {
            .ff-checkbox {
                backface-visibility: hidden;
            }
        }

        .checked-number {
            background-color: $blue;
            position: relative;
            top: -2px;
            padding: 0 3px;
            font-size: 10px;
            color: #fff;
            text-decoration: none;
            font-style: normal;

            &:empty {
                display: none;
            }
        }
    }

    &-home {
        position: relative;

        .button-search-submit {
            width: 130px;
            padding: 0;
            font-size: 16px;
            font-weight: 700;
            text-align: center;
            border-radius: 0 2px 2px 0;
        }

        &.is-one-open {

            .dropdown-detail {
                z-index: 5;

                .dt-is-trigger {
                    background-color: #f3f3f3;

                    .select2-search__field {
                        background-color: #f3f3f3 !important;
                    }
                }

                &.dropdown-detail-price {
                    background-color: #f3f3f3;

                    &.is-open {
                        background-color: #fff;
                        z-index: 6;
                    }
                }

                &.dt-is-open {
                    z-index: 4;

                    .dt-is-trigger {
                        background-color: #fff;

                        .select2-search__field {
                            background-color: #fff !important;
                        }
                    }
                }

            }

        }

        .dropdown-detail {
            display: inline-block;
            vertical-align: top;
            width: auto;
            margin-left: 0;
            margin-bottom: 10px;
            box-sizing: border-box;
            position: relative;
            z-index: auto;

            &.dropdown-detail-town {
                position: static;
                width: calc(100% - 130px - 185px - 185px - 130px);
                border-radius: 2px 0 0 2px;

                &:before {
                    display: none;
                }

                .icon-arrow_down {
                    position: absolute;
                    top: 50%;
                    right: 10px;
                    left: initial;
                    margin-top: -4px !important;
                    z-index: 5;
                    float: none;
                }

                .dt-is-trigger {
                    padding: 0;
                    width: 100%;
                    border-radius: 2px 0 0 2px;

                    &:before {
                        display: none;
                    }

                    .select2-search--dropdown {
                        padding: 0;
                        height: 40px;

                        input {
                            padding: 0 5px;
                            font-size: 14px;
                            height: 40px;
                            box-shadow: none !important;
                            -webkit-appearance: none;
                            border: 0;
                            border-radius: 2px 0 0 2px;
                            color: $text_color;
                            background-color: #fff;

                            &:-ms-input-placeholder {
                                color: rgba($text_color, .8);
                                transition: opacity $time;
                            }
                            &:-moz-placeholder {
                                color: rgba($text_color, .8);
                                transition: opacity $time;
                            }
                            &::-moz-placeholder {
                                color: rgba($text_color, .8);
                                transition: opacity $time;
                            }
                            &::-webkit-input-placeholder {
                                color: rgba($text_color, .8);
                                transition: opacity $time;
                            }

                            &:focus {
                                &:-ms-input-placeholder {
                                    opacity: 0;
                                }
                                &:-moz-placeholder {
                                    opacity: 0;
                                }
                                &::-moz-placeholder {
                                    opacity: 0;
                                }
                                &::-webkit-input-placeholder {
                                    opacity: 0;
                                }
                            }
                        }
                    }
                }

                .select2-selection__rendered {
                    position: absolute;
                    top: 0;
                    left: -10000px;
                }

                .dt-is-list {
                    right: 130px;
                    max-height: 300px;
                    overflow-y: scroll;

                    .checkbox-list {
                        label {
                            padding: 0;
                            width: 100%;
                            display: block;

                            &:after {
                                top: 15px;
                            }
                            &:before {
                                display: none;
                            }

                            a {
                                display: block;
                                padding: 0 10px;
                                line-height: 40px;
                                color: #7c8084;
                                font-size: 14px;
                                @extend %clear;

                                b {
                                    color: #3d3d3c;
                                }

                                span {
                                    display: block;
                                    float: left;
                                    width: 66%;

                                    & + span {
                                        width: 30%;
                                    }
                                }

                                @media screen {
                                    transition: background-color $time*2;

                                    &:hover {
                                        background-color: #f3f3f3;
                                    }
                                }

                            }

                        }
                        input {
                            &:checked + label {
                                a {
                                    background-color: #f3f3f3;
                                }
                            }
                        }
                    }
                }

                &.dt-is-open {
                    .dt-is-list {
                        top: 41px;
                    }
                }

            }

            &.dropdown-detail-price {
                width: 185px;
                background-color: #fff;
                height: 40px;
                line-height: 40px;
                color: rgba($text-color, .8);
                padding-left: 10px;

                span {
                    font-size: 14px;
                }

                button {
                    padding: 0;
                    border: 0;
                    height: 26px;
                    width: 50px;
                    margin: 0 8px;
                    font-size: 14px;
                    text-align: center;
                    color: rgba($text-color, .8);
                    background-color: transparent;

                    &.is-select {
                        background-color: rgba($green, .2);
                    }
                }

                p {
                    position: relative;
                    margin-bottom: 15px;
                    line-height: 1;
                    margin-left: -10px;

                    & + p {
                        margin-left: 0;
                        padding-right: 10px;
                        text-align: right;
                        line-height: 35px;
                        margin-bottom: 10px;

                        input {
                            width: 60px;
                            height: 35px;
                            border: 1px solid #f3f3f3;
                            border-radius: 2px;
                            margin-left: 10px;
                            padding-left: 10px;
                        }

                        label {
                            font-size: 12px;
                            color: #7c8084;
                        }

                    }

                    span {
                        display: block;
                        font-size: 12px;
                        color: #7c8084;
                        margin-bottom: 10px;
                        padding-left: 10px;
                    }
                }

                input[type="text"] {
                    border: 0;
                    padding: 0;
                    height: 26px;
                    width: 50px;
                    margin: 0 8px;
                    font-size: 14px;
                    text-align: center;
                    color: rgba($text-color, .8);
                    background-color: transparent;

                    &:focus,
                    &.is-select {
                        background-color: rgba($green, .2);
                    }
                }

                .label-hidden {
                    display: none;
                }

                input[type=radio] {
                    position: absolute;
                    top: 0;
                    left: -10000px;

                    & + label {
                        display: inline-block;
                        vertical-align: top;
                        margin-left: 10px;
                        cursor: pointer;
                        border-radius: 2px;
                        width: 42px;
                        line-height: 30px;
                        font-size: 14px;
                        text-align: center;
                        background-color: #e9e8e8;
                        color: rgba($text-color, .8);

                        @media screen {
                            transition: bacground-color $time, color $time;

                            &:hover {
                                background-color: lighten(#e9e8e8, 2%);
                            }
                        }
                    }

                    &:checked {
                        & + label {
                            background-color: $green;
                            color: #fff;
                        }
                    }
                }

                .dt-price-detail {
                    position: absolute;
                    top: 48px;
                    width: 130%;
                    left: 0;
                    background-color: #fff;
                    border-radius: 2px;
                    box-shadow: 0 1px 2px rgba(#000, .5);
                    padding: 15px 12px 1px;
                    opacity: 0;
                    visibility: hidden;
                    transform: rotateY(90deg);
                    transition: transform $time, opacity $time, visibility $time;

                    &:before {
                        @extend %before;
                        top: -5px;
                        left: 108px;
                        width: 0;
                        height: 0;
                        border: 5px solid transparent;
                        border-bottom-color: #fff;
                        border-top: 0;
                    }
                }

                .dt-price-close {
                    position: absolute;
                    top: 0;
                    right: -8px;
                    width: 30px;
                    height: 30px;

                    font-size: 10px;

                    //svg {
                    //    width: 10px;
                    //    height: 10px;
                    //    fill: $text-color;
                    //}
                }

                &.is-open {
                    .dt-price-detail {
                        transform: rotateY(0deg);
                        opacity: 1;
                        visibility: visible;

                        &[data-show="lowerPrice"] {
                            .upperPrice {
                                display: none;
                            }
                        }

                        &[data-show="upperPrice"] {
                            .lowerPrice {
                                display: none;
                            }
                        }
                    }
                }

            }

            &[id="1_1"] {
                width: 130px;
            }

            &[id="1_2"] {
                width: 185px;
            }

            &:before {
                @extend %before;
                top: 0;
                left: 0;
                width: 1px;
                height: 40px;
                background-color: #f6f6f6;
                z-index: 2;
            }

            .dt-is-trigger {
                border: 0;
                background-color: #ffffff;
                border-radius: 0;
                padding: 0 10px;
                color: rgba($text_color, .8);
                width: 100%;
                text-align: left;

                &:before {
                    display: none;
                }

                .icon-arrow_down {
                    float: right;
                    margin-top: 6px;
                    color: #6e6e6e;
                }

                //svg {
                //    fill: currentColor;
                //    float: right;
                //    margin-top: 5px;
                //}

                &:hover {
                    //background-color: darken(#e9e7e7, 5%);
                }

                &:active {
                    box-shadow: none;
                }

            }

            &.dt-is-open {
                .dt-is-trigger {
                    background-color: #fff;
                    border: 0;
                }

            }

            .dt-is-list {
                right: 0;
                top: 41px;
                left: 0;
                box-shadow: 0 1px 2px rgba(#000, .6);
                z-index: 10;
                border: 0;
                max-height: 300px;
                overflow-y: scroll;

                .checkbox-list {
                    margin-top: -1px;

                    label {
                        padding: 0 13px;
                        line-height: 30px;
                        width: 100%;
                        display: block;
                        color: #3d3d3c;
                        border-top: 1px solid #f6f6f6;

                        @media screen {
                            transition: background-color $time;

                            &:hover {
                                background-color: #f9f9f9;
                            }
                        }

                        a {
                            color: currentColor;
                        }

                        &:before {
                            display: none;
                        }
                        &:after {
                            right: 13px;
                            left: initial;
                            border: 2px solid $blue;
                            border-left: 0;
                            border-top: 0;
                        }
                    }
                    input[type="checkbox"]:checked + label {
                        color: $blue;
                        font-weight: 700;
                    }
                }
            }

            .dd-is-close {
                display: none;
            }

        }

        .dropdown-detail[id="1_3"],
        .dropdown-detail[id="1_4"],
        .dropdown-detail[id="1_5"],
        .dropdown-detail[id="2_6"],
        .dropdown-detail[id="3_7"],
        .dropdown-detail[id="4_8"],
        .dropdown-detail[id="5_9"],
        #flt_category,
        #flt_subcategory {
            display: none !important;
        }

        @media screen and (min-width: 769px) {
            .search-sep {
                display: none;
            }
        }

        @media screen and (max-width: 768px) {
            text-align: center;

            .dropdown-detail {
                text-align: left;

                &.dropdown-detail-town {
                    width: 50%;
                    display: block;
                    margin: 0 auto 10px;

                    .dt-is-trigger {
                        border-radius: 2px;
                        .select2-search--dropdown {
                            input {
                                text-align: center;
                                border-radius: 2px;
                            }
                        }
                    }
                    .dt-is-list {
                        left: 25%;
                        right: 25%;
                    }
                }

                &.dropdown-detail-price {
                    display: block;
                    margin: 0 auto 10px;

                    .dt-price-detail {
                        z-index: 100;
                    }
                }

                &[id="1_1"] {
                    margin-left: 10px;
                }

            }

            .search-sep {
                display: initial;
            }
        }

        @media screen and (max-width: 600px) {
            .dropdown-detail {
                &.dropdown-detail-town {
                    width: 100%;
                    display: block;
                    margin: 0 auto 10px;

                    .dt-is-trigger {
                        border-radius: 2px;
                        .select2-search--dropdown {
                            input {
                                text-align: center;
                                border-radius: 2px;
                            }
                        }
                    }

                    .dt-is-list {
                        left: 0;
                        right: 0;

                        .checkbox-list label a span {
                            width: 65%;
                            font-size: 13px;

                            &:first-child {
                                font-size: 0;

                                b {
                                    font-size: 13px;
                                }
                            }

                            & + span {
                                width: 30%;
                                font-size: 11px;
                                white-space: nowrap;
                            }
                        }
                    }
                }
            }
        }

    }

    &-single {

        .button-search-submit {
            width: 130px;
            height: 42px;
            padding: 0;
            font-size: 16px;
            font-weight: 700;
            text-align: center;
            border-radius: 0 2px 2px 0;
        }

        .dropdown-detail {
            display: inline-block;
            vertical-align: top;
            width: auto;
            margin-left: 0;
            margin-bottom: 10px;
            box-sizing: border-box;
            position: relative;
            z-index: auto;
            border: 1px solid #eee;
            border-right: 0;
            min-height: 42px;

            &:before {
                display: none !important;
            }

            &.dropdown-detail-town {
                position: static;
                width: calc(100% - 130px - 185px - 185px - 130px);
                border-radius: 2px 0 0 2px;

                &:before {
                    display: none;
                }

                .icon-arrow_down {
                    position: absolute;
                    top: 50%;
                    right: 10px;
                    left: initial;
                    margin-top: -4px !important;
                    z-index: 5;
                    float: none;
                }

                .dt-is-trigger {
                    padding: 0;
                    width: 100%;
                    border-radius: 2px 0 0 2px;

                    &:before {
                        display: none;
                    }

                    .select2-search--dropdown {
                        padding: 0;
                        height: 40px;

                        input {
                            padding: 0 5px;
                            font-size: 14px;
                            height: 40px;
                            box-shadow: none !important;
                            -webkit-appearance: none;
                            border: 0;
                            border-radius: 2px 0 0 2px;
                            color: $text_color;
                            background-color: #fff;

                            &:-ms-input-placeholder {
                                color: rgba($text_color, .8);
                                transition: opacity $time;
                            }
                            &:-moz-placeholder {
                                color: rgba($text_color, .8);
                                transition: opacity $time;
                            }
                            &::-moz-placeholder {
                                color: rgba($text_color, .8);
                                transition: opacity $time;
                            }
                            &::-webkit-input-placeholder {
                                color: rgba($text_color, .8);
                                transition: opacity $time;
                            }

                            &:focus {
                                &:-ms-input-placeholder {
                                    opacity: 0;
                                }
                                &:-moz-placeholder {
                                    opacity: 0;
                                }
                                &::-moz-placeholder {
                                    opacity: 0;
                                }
                                &::-webkit-input-placeholder {
                                    opacity: 0;
                                }
                            }
                        }
                    }
                }

                .select2-selection__rendered {
                    position: absolute;
                    top: 0;
                    left: -10000px;
                }

                .dt-is-list {
                    right: 0;
                    max-height: 300px;
                    overflow-y: scroll;
                    border-top: 1px solid #eee;

                    .checkbox-list {

                        div {
                            &:first-child label {
                                border-top: 0;
                            }
                        }

                        label {
                            padding: 0;
                            width: 100%;
                            display: block;

                            &:after {
                                top: 15px;
                            }
                            &:before {
                                display: none;
                            }

                            a {
                                display: block;
                                padding: 0 10px;
                                line-height: 40px;
                                color: #7c8084;
                                font-size: 14px;
                                @extend %clear;

                                b {
                                    color: #3d3d3c;
                                }

                                span {
                                    display: block;
                                    float: left;
                                    width: 70%;

                                    & + span {
                                        width: 30%;
                                    }
                                }

                                @media screen {
                                    transition: background-color $time*2;

                                    &:hover {
                                        background-color: #f3f3f3;
                                    }
                                }

                            }

                        }
                        input {
                            &:checked + label {
                                a {
                                    background-color: #f3f3f3;
                                }
                            }
                        }
                    }
                }

                &.dt-is-open {
                    .dt-is-list {
                        top: 41px;
                        box-shadow: 0 1px 2px -1px rgba(#000,.6);
                    }
                }

            }

            &.dropdown-detail-price {
                width: 185px;
                background-color: #fff;
                height: 40px;
                line-height: 40px;
                color: rgba($text-color, .8);
                padding-left: 10px;

                span {
                    font-size: 14px;
                }

                button {
                    padding: 0;
                    border: 0;
                    height: 26px;
                    width: 50px;
                    margin: 0 8px;
                    font-size: 14px;
                    text-align: center;
                    color: rgba($text-color, .8);
                    background-color: transparent;

                    &.is-select {
                        background-color: rgba($green, .2);
                    }
                }

                p {
                    position: relative;
                    margin-bottom: 15px;
                    line-height: 1;
                    margin-left: -10px;

                    & + p {
                        margin-left: 0;
                        padding-right: 10px;
                        text-align: right;
                        line-height: 35px;
                        margin-bottom: 10px;

                        input {
                            width: 60px;
                            height: 35px;
                            border: 1px solid #f3f3f3;
                            border-radius: 2px;
                            margin-left: 10px;
                            padding-left: 10px;
                        }

                        label {
                            font-size: 12px;
                            color: #7c8084;
                        }

                    }

                    span {
                        display: block;
                        font-size: 12px;
                        color: #7c8084;
                        margin-bottom: 10px;
                        padding-left: 10px;
                    }
                }

                input[type="text"] {
                    border: 0;
                    padding: 0;
                    height: 26px;
                    width: 50px;
                    margin: 0 8px;
                    font-size: 14px;
                    text-align: center;
                    color: rgba($text-color, .8);
                    background-color: transparent;

                    &:focus,
                    &.is-select {
                        background-color: rgba($green, .2);
                    }
                }

                .label-hidden {
                    display: none;
                }

                input[type=radio] {
                    position: absolute;
                    top: 0;
                    left: -10000px;

                    & + label {
                        display: inline-block;
                        vertical-align: top;
                        margin-left: 10px;
                        cursor: pointer;
                        border-radius: 2px;
                        width: 42px;
                        line-height: 30px;
                        font-size: 14px;
                        text-align: center;
                        background-color: #e9e8e8;
                        color: rgba($text-color, .8);

                        @media screen {
                            transition: bacground-color $time, color $time;

                            &:hover {
                                background-color: lighten(#e9e8e8, 2%);
                            }
                        }
                    }

                    &:checked {
                        & + label {
                            background-color: $green;
                            color: #fff;
                        }
                    }
                }

                .dt-price-detail {
                    position: absolute;
                    top: 48px;
                    width: 130%;
                    left: 0;
                    background-color: #fff;
                    border-radius: 2px;
                    box-shadow: 0 1px 2px rgba(#000, .5);
                    padding: 15px 12px 1px;
                    opacity: 0;
                    z-index: 100;
                    visibility: hidden;
                    transform: rotateY(90deg);
                    transition: transform $time, opacity $time, visibility $time;

                    &:before {
                        @extend %before;
                        top: -5px;
                        left: 108px;
                        width: 0;
                        height: 0;
                        border: 5px solid transparent;
                        border-bottom-color: #fff;
                        border-top: 0;
                    }
                }

                .dt-price-close {
                    position: absolute;
                    top: 0;
                    right: -8px;
                    width: 30px;
                    height: 30px;
                    font-size: 10px;
                    //svg {
                    //    width: 10px;
                    //    height: 10px;
                    //    fill: $text-color;
                    //}
                }

                &.is-open {
                    .dt-price-detail {
                        transform: rotateY(0deg);
                        opacity: 1;
                        visibility: visible;

                        &[data-show="lowerPrice"] {
                            .upperPrice {
                                display: none;
                            }
                        }

                        &[data-show="upperPrice"] {
                            .lowerPrice {
                                display: none;
                            }
                        }
                    }
                }

            }

            &[id="1_1"] {
                width: 130px;
            }

            &[id="1_2"] {
                width: 185px;
            }

            &:before {
                @extend %before;
                top: 0;
                left: 0;
                width: 1px;
                height: 40px;
                background-color: #f6f6f6;
                z-index: 2;
            }

            .dt-is-trigger {
                border: 0;
                background-color: #ffffff;
                border-radius: 0;
                padding: 0 10px;
                color: rgba($text_color, .8);
                width: 100%;
                text-align: left;

                &:before {
                    display: none;
                }

                //svg {
                //    fill: currentColor;
                //    float: right;
                //    margin-top: 5px;
                //}

                .icon-arrow_down {
                    float: right;
                    margin-top: 6px;
                    color: #6e6e6e;
                }

                &:active {
                    box-shadow: none;
                }

            }

            &.dt-is-open {
                .dt-is-trigger {
                    background-color: #fff;
                    border: 0;
                }
            }

            .dt-is-list {
                left: 0;
                box-shadow: 0 1px 2px rgba(#000, .6);
                right: 0;
                top: 41px;
                z-index: 10;
                border: 0;
                max-height: 300px;
                overflow-y: scroll;

                .checkbox-list {
                    margin-top: -1px;

                    label {
                        padding: 0 13px;
                        line-height: 30px;
                        width: 100%;
                        display: block;
                        color: #3d3d3c;
                        border-top: 1px solid #f6f6f6;

                        @media screen {
                            transition: background-color $time;

                            &:hover {
                                background-color: #f9f9f9;
                            }
                        }

                        a {
                            color: currentColor;
                        }

                        &:before {
                            display: none;
                        }
                        &:after {
                            right: 13px;
                            left: initial;
                            border: 2px solid $blue;
                            border-left: 0;
                            border-top: 0;
                        }
                    }
                    input[type="checkbox"]:checked + label {
                        color: $blue;
                        font-weight: 700;
                    }
                }
            }

            .dd-is-close {
                display: none;
            }

        }

        .dropdown-detail[id="1_3"],
        .dropdown-detail[id="1_4"],
        .dropdown-detail[id="1_5"],
        .dropdown-detail[id="2_6"],
        .dropdown-detail[id="3_7"],
        .dropdown-detail[id="4_8"],
        .dropdown-detail[id="5_9"],
        #flt_category,
        #flt_subcategory {
            display: none !important;
        }

        @media screen and (min-width: 900px) {
            .search-sep {
                display: none;
            }
        }
        @media screen and (max-width: 899px) {

            .button-search-submit {
                border-radius: 2px;
            }

            .dropdown-detail {

                &.dropdown-detail-town {
                    width: 100%;
                    border-radius: 2px;
                    border-right: 1px solid #eee;

                    .dt-is-list {
                        left: 16px;
                        right: 16px;
                        width: initial;
                    }
                }

                &.dropdown-detail-price,
                &[id="1_1"],
                &[id="1_2"] {
                    margin-right: 10px;
                    border-right: 1px solid #eee;
                }

            }
        }
        @media screen and (max-width: 375px) {
            .dropdown-detail.dropdown-detail-town {
                .dt-is-list {
                    .checkbox-list label a {
                        span {
                            width: calc(100% - 110px);
                            font-size: 0;

                            b {
                                font-size: 14px;
                            }

                            & + span {
                                width: 110px;
                                white-space: nowrap;
                                font-size: 11px;
                            }
                        }
                    }
                }
            }
        }

    }

    &-cat {
        position: relative;

        .button-search-submit {
            width: 130px;
            height: 42px;
            padding: 0;
            font-size: 16px;
            font-weight: 700;
            text-align: center;
            border-radius: 0 2px 2px 0;
        }

        .dropdown-detail {
            display: inline-block;
            vertical-align: top;
            width: auto;
            margin-left: 0;
            margin-bottom: 10px;
            box-sizing: border-box;
            position: relative;
            z-index: auto;
            border: 1px solid #eee;
            border-right: 0;
            min-height: 42px;

            &:before {
                display: none !important;
            }

            &.dropdown-detail-town {
                position: static;
                width: calc(100% - 125px - 185px - 185px - 130px);
                max-width: 300px;
                border-radius: 2px 0 0 2px;

                @media screen and (max-width: 1190px) and (min-width: 1100px) {
                    max-width: 220px;
                }

                @media screen and (max-width: 1099px) and (min-width: 1024px) {
                    max-width: 175px;
                }

                .icon-arrow_down {
                    position: absolute;
                    top: 50%;
                    right: 10px;
                    left: initial;
                    margin-top: -4px !important;
                    z-index: 5;
                    float: none;
                }

                &:before {
                    display: none;
                }

                .dt-is-trigger {
                    padding: 0;
                    width: 100%;
                    border-radius: 2px 0 0 2px;

                    &:before {
                        display: none;
                    }

                    .select2-search--dropdown {
                        padding: 0;
                        height: 40px;

                        input {
                            padding: 0 5px;
                            font-size: 14px;
                            height: 40px;
                            box-shadow: none !important;
                            -webkit-appearance: none;
                            border: 0;
                            border-radius: 2px 0 0 2px;
                            color: $text_color;
                            background-color: #fff;

                            &:-ms-input-placeholder {
                                color: rgba($text_color, .8);
                                transition: opacity $time;
                            }
                            &:-moz-placeholder {
                                color: rgba($text_color, .8);
                                transition: opacity $time;
                            }
                            &::-moz-placeholder {
                                color: rgba($text_color, .8);
                                transition: opacity $time;
                            }
                            &::-webkit-input-placeholder {
                                color: rgba($text_color, .8);
                                transition: opacity $time;
                            }

                            &:focus {
                                &:-ms-input-placeholder {
                                    opacity: 0;
                                }
                                &:-moz-placeholder {
                                    opacity: 0;
                                }
                                &::-moz-placeholder {
                                    opacity: 0;
                                }
                                &::-webkit-input-placeholder {
                                    opacity: 0;
                                }
                            }
                        }
                    }
                }

                .select2-selection__rendered {
                    position: absolute;
                    top: 0;
                    left: -10000px;
                }

                .dt-is-list {
                    right: 0;
                    max-height: 300px;
                    overflow-y: scroll;
                    border-top: 1px solid #eee;

                    .checkbox-list {

                        div {
                            &:first-child label {
                                border-top: 0;
                            }
                        }

                        label {
                            padding: 0;
                            width: 100%;
                            display: block;

                            &:before {
                                display: none;
                            }
                            &:after {
                                top: 15px;
                            }

                            a {
                                display: block;
                                padding: 0 10px;
                                line-height: 40px;
                                color: #7c8084;
                                font-size: 14px;
                                @extend %clear;

                                b {
                                    color: #3d3d3c;
                                }

                                span {
                                    display: block;
                                    float: left;
                                    width: 67%;

                                    & + span {
                                        width: 30%;
                                    }
                                }

                                @media screen {
                                    transition: background-color $time*2;

                                    &:hover {
                                        background-color: #f3f3f3;
                                    }
                                }

                            }

                        }
                        input {
                            &:checked + label {
                                a {
                                    background-color: #f3f3f3;
                                }
                            }
                        }
                    }
                }

                &.dt-is-open {
                    .dt-is-list {
                        top: 41px;
                        box-shadow: 0 1px 2px -1px rgba(#000,.6);
                    }
                }

            }

            &.dropdown-detail-price {
                width: 185px;
                background-color: #fff;
                height: 40px;
                line-height: 40px;
                color: rgba($text-color, .8);
                padding-left: 10px;

                span {
                    font-size: 14px;
                }

                button {
                    padding: 0;
                    border: 0;
                    height: 26px;
                    width: 50px;
                    margin: 0 8px;
                    font-size: 14px;
                    text-align: center;
                    color: rgba($text-color, .8);
                    background-color: transparent;

                    &.is-select {
                        background-color: rgba($green, .2);
                    }
                }

                p {
                    position: relative;
                    margin-bottom: 15px;
                    line-height: 1;
                    margin-left: -10px;

                    & + p {
                        margin-left: 0;
                        padding-right: 10px;
                        text-align: right;
                        line-height: 35px;
                        margin-bottom: 10px;

                        input {
                            width: 60px;
                            height: 35px;
                            border: 1px solid #f3f3f3;
                            border-radius: 2px;
                            margin-left: 10px;
                            padding-left: 10px;
                        }

                        label {
                            font-size: 12px;
                            color: #7c8084;
                        }

                    }

                    span {
                        display: block;
                        font-size: 12px;
                        color: #7c8084;
                        margin-bottom: 10px;
                        padding-left: 10px;
                    }
                }

                input[type="text"] {
                    border: 0;
                    padding: 0;
                    height: 26px;
                    width: 50px;
                    margin: 0 8px;
                    font-size: 14px;
                    text-align: center;
                    color: rgba($text-color, .8);
                    background-color: transparent;

                    &:focus,
                    &.is-select {
                        background-color: rgba($green, .2);
                    }
                }

                .label-hidden {
                    display: none;
                }

                input[type=radio] {
                    position: absolute;
                    top: 0;
                    left: -10000px;

                    & + label {
                        display: inline-block;
                        vertical-align: top;
                        margin-left: 10px;
                        cursor: pointer;
                        border-radius: 2px;
                        width: 42px;
                        line-height: 30px;
                        font-size: 14px;
                        text-align: center;
                        background-color: #e9e8e8;
                        color: rgba($text-color, .8);

                        @media screen {
                            transition: bacground-color $time, color $time;

                            &:hover {
                                background-color: lighten(#e9e8e8, 2%);
                            }
                        }
                    }

                    &:checked {
                        & + label {
                            background-color: $green;
                            color: #fff;
                        }
                    }
                }

                .dt-price-detail {
                    position: absolute;
                    top: 48px;
                    width: 130%;
                    left: 0;
                    background-color: #fff;
                    border-radius: 2px;
                    box-shadow: 0 1px 2px rgba(#000, .5);
                    padding: 15px 12px 1px;
                    opacity: 0;
                    visibility: hidden;
                    z-index: 100;
                    transform: rotateY(90deg);
                    transition: transform $time, opacity $time, visibility $time;

                    &:before {
                        @extend %before;
                        top: -5px;
                        left: 108px;
                        width: 0;
                        height: 0;
                        border: 5px solid transparent;
                        border-bottom-color: #fff;
                        border-top: 0;
                    }
                }

                .dt-price-close {
                    position: absolute;
                    top: 0;
                    right: -8px;
                    width: 30px;
                    height: 30px;
                    font-size: 10px;

                }

                &.is-open {
                    .dt-price-detail {
                        transform: rotateY(0deg);
                        opacity: 1;
                        visibility: visible;

                        &[data-show="lowerPrice"] {
                            .upperPrice {
                                display: none;
                            }
                        }

                        &[data-show="upperPrice"] {
                            .lowerPrice {
                                display: none;
                            }
                        }
                    }
                }

            }

            //&.dd-type-comfort {
            //
            //    .dt-is-list {
            //        width: 440px;
            //        left: 50% !important;
            //        padding: 0 20px;
            //        margin-left: -220px;
            //        max-height: initial;
            //
            //        .checkbox-list {
            //            column-count: 2;
            //            column-gap: 20px;
            //
            //            div:last-child {
            //                label {
            //                    border-bottom: 1px solid #eee;
            //                }
            //            }
            //        }
            //    }
            //
            //}

            &.dd-type-metro,
            &.dd-type-comfort,
            &.dd-type-sub-category,
            &.dd-type-category {

                .dt-is-list {
                    width: 440px;
                    left: 0 !important;
                    padding: 0 20px;
                    max-height: initial;

                    .checkbox-list {
                        column-count: 2;
                        column-gap: 20px;

                        div:last-child {
                            label {
                                border-bottom: 1px solid #eee;
                            }
                        }
                    }
                }

            }

            &.dd-type-perfect {

                .dt-is-list {
                    width: 480px;
                    left: 50%;
                    margin-left: -240px;
                    padding: 0 20px;

                    .checkbox-list {
                        column-count: 2;
                        column-gap: 20px;

                        div:last-child {
                            label {
                                border-bottom: 1px solid #eee;
                            }
                        }
                    }
                }

            }

            &.dd-type-metro,
            &.dd-type-perfect,
            &.dd-type-comfort,
            &.dd-type-metro,
            &.dd-type-sub-category,
            &.dd-type-category {
                margin-right: 15px;
                border-right: 1px solid #eee;

                &.dt-is-open {
                    .dt-is-trigger {
                        background-color: rgba(#f9f9f9, .5);
                    }
                }
            }

            &.dd-type-metro {
                .dt-is-list {
                    min-width: 200px;
                }
            }

            &[id="1_1"] {
                width: 125px;
            }

            &[id="1_2"] {
                width: 185px;
            }

            //&[id="1_3"] {
            //    display: none;
            //}

            &:before {
                @extend %before;
                top: 0;
                left: 0;
                width: 1px;
                height: 40px;
                background-color: #f6f6f6;
                z-index: 2;
            }

            .dt-is-trigger {
                border: 0;
                background-color: #ffffff;
                border-radius: 0;
                padding: 0 10px;
                color: rgba($text_color, .8);
                width: 100%;
                text-align: left;

                &:before {
                    display: none;
                }

                .icon-arrow_down {
                    float: right;
                    margin-top: 6px;
                    color: #6e6e6e;
                }

                //svg {
                //    fill: currentColor;
                //    float: right;
                //    margin-top: 5px;
                //}

                &:hover {
                    //background-color: darken(#e9e7e7, 5%);
                }

                &:active {
                    box-shadow: none;
                }

            }

            &.dt-is-open {
                .dt-is-trigger {
                    background-color: #fff;
                    border: 0;
                }
            }

            .dt-is-list {
                left: 0;
                right: 0;
                top: 43px;
                z-index: 10;
                border: 0;
                max-height: 300px;
                box-shadow: 0 1px 2px rgba(#000, .6);
                overflow-y: scroll;

                .checkbox-list {
                    margin-top: -1px;

                    label {
                        padding: 0 13px;
                        line-height: 30px;
                        width: 100%;
                        display: block;
                        color: #3d3d3c;
                        border-top: 1px solid #f6f6f6;
                        backface-visibility: hidden;

                        @media screen {
                            transition: background-color $time;

                            &:hover {
                                background-color: #f9f9f9;
                            }
                        }

                        a {
                            color: currentColor;
                        }

                        &:before {
                            display: none;
                        }
                        &:after {
                            right: 13px;
                            left: initial;
                            border: 2px solid $blue;
                            border-left: 0;
                            border-top: 0;
                        }
                    }
                    input[type="checkbox"]:checked + label {
                        color: $blue;
                        font-weight: 700;
                    }
                }
            }

            .dd-is-close {
                display: none;
            }

        }

        @media screen and (min-width: 1024px) {
            .search-sep {
                display: none;
            }
        }

        @media screen and (max-width: 1023px) {
            padding-bottom: 60px;

            .button-search-submit {
                position: absolute;
                bottom: 10px;
                left: 0;
                border-radius: 2px;
            }

            .dropdown-detail {
                &.dropdown-detail-town {
                    width: calc(100% - 220px - 125px);
                }
                &.dd-type-perfect {
                    .dt-is-list {
                        right: 0;
                        left: initial !important;
                        margin-left: 0;
                    }
                }
                &[id="1_2"] {
                    border-right: 1px solid #eee;
                    border-radius: 0 2px 2px 0;
                }
                &[id="1_3"] {
                    width: 190px;
                    margin-right: 15px;
                    border-right: 1px solid #eee;
                    border-radius: 2px;
                }
            }
            .br-after-search {
                display: none;
            }
        }

        @media screen and (max-width: 900px) {
            .dropdown-detail {
                &[id="1_1"] {
                    border-right: 1px solid #eee;
                    border-radius: 0 2px 2px 0;
                }
                &[id="1_2"],
                &[id="1_3"] {
                    border-radius: 2px;
                    border-right: 1px solid #eee;
                    margin-right: 15px;
                }
            }
            .br-after-search {
                display: initial;
            }
        }

        @media screen and (max-width: 660px) {
            .dropdown-detail {
                &.dropdown-detail-town {
                    width: 100%;
                    border-right: 1px solid #eee;
                    border-radius: 2px;
                    margin-right: 15px;

                    .dt-is-trigger {
                        border-radius: 2px;

                        .select2-search--dropdown {
                            input {
                                border-radius: 2px;
                            }
                        }
                    }
                }

                &.dd-type-guest,
                &.dd-type-flat,
                &.dropdown-detail-price {
                    border-right: 1px solid #eee;
                    border-radius: 2px;
                    margin-right: 15px;

                    .dt-is-trigger {
                        border-radius: 2px;
                    }
                }
            }

            .br-after-search {
                display: none;
            }
        }

        @media screen and (max-width: 580px) {
            .dropdown-detail {
                &.dropdown-detail-town {
                    margin-right: 0;
                    max-width: initial;

                    .dt-is-list {
                        .checkbox-list label a span {
                            width: 65%;
                            font-size: 13px;

                            &:first-child {
                                font-size: 0;

                                b {
                                    font-size: 13px;
                                }
                            }

                            & + span {
                                width: 30%;
                                font-size: 11px;
                                white-space: nowrap;
                            }
                        }
                    }
                }

                &.dd-type-sub-category,
                &.dd-type-category,
                &.dd-type-comfort,
                &.dd-type-perfect {

                    .dt-is-list {
                        width: 100%;
                        left: 0 !important;
                        margin-left: 0;
                        padding: 0 0 0;

                        .checkbox-list {
                            column-count: 1;
                            column-gap: 0;
                        }
                    }
                }

                .dt-is-list {
                    position: fixed;
                    opacity: 1;
                    visibility: visible;
                    left: 0;
                    width: 100%;
                    height: initial;
                    padding-top: 0;
                    top: 40px;
                    background-color: #fff;
                    z-index: 10000;
                    bottom: 0;
                    max-height: initial !important;
                    transform: translateX(-110%);
                    transition: transform $time;
                }

                .dd-is-close {
                    width: 50px;
                    height: 40px;
                    left: 100%;
                    transform: translateX(0);

                    position: fixed;
                    top: 0;
                    z-index: 10010;
                    padding: 0 15px;
                    background-color: #fff;
                    text-align: right;
                    display: block;
                    transition: transform $time;
                    font-size: 12px;
                    color: $base;

                    //svg {
                    //    width: 12px;
                    //    height: 12px;
                    //    fill: $base;
                    //}
                }

                &.dt-is-open {

                    .dt-is-trigger {
                        position: fixed;
                        top: 0;
                        left: 0;
                        width: 100%;
                        z-index: 10005;
                        box-shadow: 0 0 0 1px #eee;
                        font-size: 16px;
                        background-color: #fff !important;
                    }

                    .dd-is-close {
                        transform: translateX(-100%);
                    }

                    .dt-is-list {
                        transform: translateX(0);
                        top: 40px;

                        .checkbox-list {
                            label {
                                line-height: 36px;
                                font-size: 14px;

                                &:after {
                                    top: 13px;
                                }
                            }
                        }
                    }
                }
            }

        }



    }

}

.remove-filter-select {
    font-size: 12px;
    padding: 10px 0 15px;
    line-height: 20px;

    label {
        display: inline-block;
        vertical-align: top;
        margin-right: 15px;

        a {
            display: block;
            padding-right: 17px;
            position: relative;
            color: $blue;

            i {
                font-size: 7px;
                position: absolute;
                top: 0;
                line-height: 12px;
                right: 5px;
                color: #999;
            }

            @media screen {
                transition: color $time;

                &:hover {
                    color: darken($blue,10%);

                    i {
                        color: $red;
                    }
                }
            }
        }

        &:last-child {
            a {
                padding-right: 0;
                color: $red;
                border-bottom: 1px solid rgba($red,.5);


                i {
                    display: none;
                }

                @media screen {
                    transition: color $time, border-color $time;

                    &:hover {
                        color: $blue;
                        border-color: rgba($blue,.5);
                    }
                }
            }
        }
    }

    &:empty {
        display: none;
    }

}


#select2-ao-city-results {
    /* li:first-child {
         display: none;
     }*/

}

#ao-city {
    & + .select2 {
        .select2-search__field {
            padding-left: 35px !important;
        }
    }
}

.filter-tag {
    font-size: 12px;
    line-height: 22px;
    color: rgba($text_color, .6);
    margin-bottom: 15px;

    .f-tag-item {
        @extend %ibt;
        font-size: 12px;
        margin-left: 5px;
        transition: color $time;
        height: 22px;
        color: rgba($text_color, .6);

        & + button {
            margin-left: 10px;
        }

        i {
            margin: 0 0 0 3px;
            font-size: inherit;
            vertical-align: text-bottom;
            transform: translateY(-1px);
            display: inline-block;
        }

        //svg {
        //    width: 5px;
        //    height: 5px;
        //    fill: rgba($text_color, .6);
        //    transition: fill $time;
        //}

        &:hover {
            color: $blue;

            //svg {
            //    fill: $blue;
            //}
        }

    }

    .ft-remove-all {
        vertical-align: top;
        font-size: 12px;
        color: rgba($text_color, .6);
        margin-left: 10px;
        border-bottom: 1px solid rgba($text_color, .3);
        transform: translateY(2px);
        height: initial;
        transition: color $time;

        &:hover {
            color: $blue;
            border-bottom-color: $blue;
        }
    }

}

.filter-result {
    position: relative;

    .post-row,
    .post-row-nav {
        position: relative;
        z-index: 2;
    }

    .post-row-nav {
        margin-top: 20px;
    }

    &:before {
        @extend %before;
        top: 0;
        right: -10000px;
        left: -10000px;
        bottom: 0;
        box-shadow: inset 0 1px 2px rgba(#000, .2);
        background-color: #eeeeee;
        z-index: 1;
    }
}

.dd-mini-region {
    padding: 20px 10px;

    .checkbox-list {
        column-count: 4;
        column-gap: 20px;

        .ff-checkbox {
            position: relative;

            label {
                white-space: nowrap;
                display: block;
                overflow: hidden;
            }

            &:before {
                @extend %before;
                top: 0;
                bottom: 0;
                width: 20px;
                right: 0;
                z-index: 1;
                background-image: linear-gradient(90deg, rgba(#fff, 0), rgba(#fff, 1));
            }
        }

    }

    @media screen and (max-width: 600px) {
        .checkbox-list {
            column-count: 1;
        }
    }

}

.dd-region-box {
    padding: 20px 20px;

    .dd-region-filter {
        border-bottom: 1px solid #ececec;
        margin-bottom: 20px;

        button {
            height: initial;
            font-size: 12px;
            color: rgba($text_color, .6);
            padding-bottom: 15px;
            position: relative;
            margin-left: 15px;

            &:first-child {
                margin-left: 0;
            }

            &:before {
                @extend %before;
                bottom: -2px;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: rgba($blue, 0);
                transition: background-color $time;
            }

            &.is-active {
                &:before {
                    background-color: rgba($blue, 1);
                }
            }

        }

    }

    .checkbox-list {
        column-count: 2;
        column-gap: 20px;

        .form-field {
            &.is-hidden {
                display: none;
            }
        }

    }
    @media screen and (max-width: 600px) {
        .checkbox-list {
            column-count: 1;
        }
    }
    @media screen and (max-width: 400px) {
        .dd-region-filter {
            display: none;
        }
    }

}

.dd-comfort,
.dd-showplace {
    padding: 20px 10px;

    .checkbox-list {
        column-count: 3;
        column-gap: 20px;

        .ff-checkbox {
            position: relative;

            label {
                white-space: nowrap;
                display: block;
                overflow: hidden;
            }

            &:before {
                @extend %before;
                top: 0;
                bottom: 0;
                width: 20px;
                right: 0;
                z-index: 1;
                background-image: linear-gradient(90deg, rgba(#fff, 0), rgba(#fff, 1));
            }
        }

        @media screen and (max-width: 600px) {
            column-count: 1;
        }

    }
}

.dd-metro {
    padding: 20px;

    .checkbox-list {
        @include row-grid(20px);
    }

    .metro-line {
        padding-left: 20px;
        position: relative;
        @include grid-item(3, 20px, 0);

        &:before {
            @extend %before;
            top: 10px;
            left: 5px;
            width: 2px;
            bottom: 13px;
            z-index: 1;
        }

        .ff-checkbox {
            &:before {
                @extend %before;
                top: 9px;
                left: -19px;
                width: 10px;
                height: 10px;
                box-sizing: border-box;
                border: 2px solid;
                border-radius: 8px;
                background-color: #fff;
                z-index: 2;
            }
        }

        &.ml-red {
            &:before {
                background-color: $mred;
            }
            .ff-checkbox:before {
                border-color: $mred;
            }
        }

        &.ml-blue {
            &:before {
                background-color: $mblue;
            }
            .ff-checkbox:before {
                border-color: $mblue;
            }
        }

        &.ml-green {
            &:before {
                background-color: $mgreen;
            }
            .ff-checkbox:before {
                border-color: $mgreen;
            }
        }

    }

    @media screen and (max-width: 700px) {
        .metro-line {
            @include grid-item(1, 20px, 0);
        }
    }

}

.post-row-nav {
    @include row-grid(0);
    text-align: center;
    padding: 20px 100px 30px;
    position: relative;

    .prn-link {
        @extend %ibt;
        margin: 0;
        font-size: 14px;
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        color: $grey;
        position: relative;
        transition: color $time;

        &:before {
            @extend %before;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 2px solid $blue;
            opacity: 0;
            visibility: hidden;
            transition: opacity $time, visibility $time;
            border-radius: 35px;
        }

        &.is-active {
            color: $blue;

            &:before {
                opacity: 1;
                visibility: visible;
            }
        }

        &.prn-dot {
            margin: 0 5px;

            &:hover {
                color: $grey;
            }
        }

        &.prn-prev,
        &.prn-next {
            position: absolute;
            top: 20px;
            width: auto;
            text-transform: uppercase;
            font-size: 11px;
            color: $grey;

            &:before {
                display: none;
            }

            i {
                vertical-align: initial !important;
            }
        }

        &.prn-prev {
            left: 0;
        }

        &.prn-next {
            right: 0;
        }

        &:hover {
            color: $blue;
        }

    }

    @media screen and (max-width: 600px) {
        padding-left: 0;
        padding-right: 0;

        .prn-link {
            &.prn-prev,
            &.prn-next {
                display: none;
            }
        }
    }

}

.section-filter-text {
    .scp-wrap {
        width: 660px;
        margin-top: 30px;
    }
    @media screen and (max-width: 659px) {
        .scp-wrap {
            width: 100%;
        }
    }
}
//added sion
.unit {
    float: left;
}
.half-quarter {
    width: 49%;
}
.one-quarter {
    width: 24.5%;
}
