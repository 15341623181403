////////////////////////////////////////
//		Reset
////////////////////////////////////////

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin:         0;
	padding:        0;
	border:         0;
	font:           inherit;
	font-size:      100%;
	vertical-align: baseline;
	box-sizing:     border-box;
}

html {
	line-height: 1;
	background-color: #fff;
}

body {
	font-family:             $fontName, Arial, sans-serif;
	font-size: 14px;
	line-height:             1;
	color:                   $base;
	-webkit-font-smoothing:  antialiased;
	-moz-osx-font-smoothing: grayscale;
	min-width: 320px;
}

ol, ul {
	list-style: none;
}

table {
	border-collapse: collapse;
	border-spacing:  0;
}

caption, th, td {
	text-align:     left;
	font-weight:    normal;
	vertical-align: middle;
}

q, blockquote {
	quotes: none;

	&:before,
	&:after {
		content: "";
		content: none;
	}
}

b,
strong {
	font-weight: 700;
}

img {
	max-width: 100%;
	height:    auto;
	border:    none;
	border: 0;
	display: inline-block;
}

a {
	text-decoration: none;
}

article, aside, details, figcaption, figure, footer, header, main, menu, nav, section, summary {
	display:    block;
	box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
	font-weight: normal;
}

:focus {
	outline: none;
}

:after,
:before {
	box-sizing: border-box;
}

.t-center {
	text-align: center;
}

.t-right {
	text-align: right;
}

.grey-text {
	color: $grey;
}

.red-text {
	color: #bf392b;
}

//.green-text {
//	color: #27ad5f;
//
//	svg {
//		fill: #27ad5f;
//	}
//}