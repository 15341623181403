//-> 	Land home

//->

.section {

	.section-footer {
		text-align: center;
		padding:    30px 0 50px;
	}

	.section-heading {
		padding:    58px 0 35px;
		text-align: center;

		h3 {
			color:       rgba($base, .9);
			font-size:   20px;
			font-weight: 700;
			margin:      0;

			& + p {
				margin-top: 13px;
			}
		}

		p {
			font-size: 16px;
			color:     rgba(#7b7f84, .9);
		}

	}

	&.bg-grey {
		background-color: #eee;
	}

}


//->Land

.section-search {
	background:      url(../img/cover-dark.jpg) center no-repeat;
	background-size: cover;
	position:        relative;


	.container {
		padding:  180px 0 100px;
		position: relative;
		z-index:  3;

		@media screen and (max-width: 1000px) {
			padding:  180px 15px 100px;
		}
	}

	.section-heading {
		color:      #fff;
		text-align: center;

		h1, h2 {
			font-size:   42px;
			font-weight: 300;
			margin:      0 0 25px;
		}

		p {
			font-weight:   700;
			font-size:     16px;
			margin-bottom: 65px;
		}

	}

}

.search-house {

	fieldset {
		@include row-grid(20px);
	}

	.ff-town {
		@extend %ibt;
		margin-left:   20px;
		margin-bottom: 25px;
		width:         calc(100% - 175px - 140px - 60px);
	}

	.button-search-submit {
		width:          175px;
		padding:        0;
		font-size:      16px;
		font-weight:    700;
		margin:         0 0 0 20px;
		vertical-align: top;
		position: relative;

		.svg-icon {
			display: none;
			width:      20px;
			height:     20px;
			position:   absolute;
			top:        50%;
			left: 50%;
			margin-top: -10px;
			margin-left: -10px;
			fill: #fff;
		}
	}

	.select2-container {
		display: block;
		width: 100% !important;
		font-size: 14px;
	}

	@media screen and (max-width: 800px) {

		fieldset {
			@include row-grid(10px);
		}

		.ff-town {
			margin-left: 10px;
			width:         calc(100% - 60px - 40px - 30px);
			margin-bottom: 10px;
		}

		//.button-params {
		//	width: 40px;
		//	height: 40px;
		//	margin:         0 0 0 10px;
		//	padding: 0;
        //
		//	span,
		//	.svg-arrow {
		//		display: none;
		//	}
        //
		//	.svg-icon {
		//		display: block;
		//	}
        //
		//}

		.button-search-submit {
			text-align: center;
			margin:         0 0 0 10px;
			width: 60px;
			height: 40px;

			span {
				display: none;
			}
			.svg-icon {
				display: inline-block;
			}
		}

	}

}

.section-town-ua {

	.section-heading {
		padding-top: 55px;

		h3 {
			font-weight: 400;
			font-size: 30px;
			margin-bottom: 10px;
		}
	}

	hr {
		border: 0;
		background-color: #eee;
		height: 1px;
		margin: 60px 0 0;
		display: block;
	}

	ul {
		@include row-grid(20px);

		div {
			@include grid-item(7, 20px, 0);

			@media screen and (max-width: 880px) {
				@include grid-item(4, 20px, 0);
			}
			@media screen and (max-width: 700px) {
				@include grid-item(3, 20px, 0);
			}
			@media screen and (max-width: 500px) {
				@include grid-item(2, 20px, 0);
			}
		}

	}

	li {
		display: block;

		a {
			display: block;
			font-size: 14px;
			color: #7c8084;
			line-height: 1.3;
			padding: 0 0 10px;
		}
	}

	@media screen and (max-width: 425px) {
		display: none;
	}

}

.section-about {
	padding: 45px 0;

	.container {
		border: 1px solid #fff;
		text-align: center;
		padding-bottom: 35px;

		@media screen and (max-width: 1000px) {
			margin: 0 10px;
			padding-left: 0;
			padding-right: 0;
			padding-bottom: 35px;
			max-width: calc(100% - 20px);
		}
	}

	.section-heading {
		padding: 40px 0 24px;

		h3 {
			text-transform: uppercase;
			font-size: 20px;
			font-weight: 700;
			margin-bottom: 0;
		}
	}

	p {
		max-width: 845px;
		margin: 0 auto;
		font-size: 12px;
		line-height: 32px;
		color: #323232;

		@media screen and (max-width: 900px) {
			padding: 0 20px;
		}
	}


}

.section-content-page {
	position: absolute;
	top: -100000px;
	left: 0;
	width: 100%;

	&.is-complete {
		position: relative;
		top: 0;
		width: auto;
		padding-bottom: 40px;
	}

	.section-heading {
		padding-top: 55px;

		h2 {
			font-weight: 200;
			font-size: 24px;
		}
		@media screen and (max-width: 800px) {
			text-align: left;

		}
	}

	.scp-content {
		line-height: 22px;
		color: #7c8084;
		padding-bottom: 10px;

		p {
			font-size: 12px;
			line-height: 22px;
			color: currentColor;
			margin-bottom: 20px;
		}

		h2,
		h3,
		h4 {
			font-weight: 300;
			margin-bottom: 20px;
			color: $base;
		}

		h2 {
			font-size: 24px;
		}

		h3 {
			font-size: 20px;
		}

		h4 {
			font-size: 18px;
		}

		ul {
			margin: 0 0 20px 20px;
			font-size: 12px;
			line-height: 22px;
			color: currentColor;
			list-style-type: disc;
		}

	}

	.scp-wrap {
		max-height: 88px;
		overflow: hidden;
		transition: max-height $time;
	}

	.scp-trigger {
		font-size: 12px;
		color: $blue;
		height: initial;
		margin-top: 2px;

		i {
			display: inline-block;
			position: relative;
			font-size: 9px;
			//vertical-align: middle;
		}

		@media screen  {
			transition: color $time;

			&:hover {
				color: darken($blue,10%);
			}
		}

		//svg {
		//	width: 9px;
		//	height: 9px;
		//	margin-left: 2px;
		//	vertical-align: middle;
		//	fill: $blue;
		//	transition: fill $time;
		//}

		&.is-open {
			i {
				transform: rotate(180deg);
			}
			//svg {
			//	transform: rotate(180deg);
			//}
		}

	}



}

.fave-section {
	min-height: calc(100vh - 330px);
	padding-bottom: 20px;

	.section-heading {

		p {
			max-width: 300px;
			margin: 20px auto 15px !important;
			font-size: 14px;
			line-height: 22px;
		}

		.flat-share {
			@include row-grid(5px);
			width: 300px;
			margin: 0 auto 20px;

			a {
				position: relative;
				width: auto;
				padding: 0 12px;

				&:before {
					@extend %before;
					top: 0;
					bottom: 0;
					left: 0;
					width: 1px;
					background-color: $grey;
				}

				&:first-child {
					margin-left: 0;

					&:before {
						display: none;
					}
				}


			}
		}

	}

}
#section-content-page:empty {
	display:none;
}


