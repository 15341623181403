.bg-aqua {
    background-color: $aqua;
}

.bg-green {
    background-color: $green;
}

.bg-blue {
    background-color: $blue;
}

.bg-red {
    background-color: $red;
}

.bg-yellow {
    background-color: $yellow;
}