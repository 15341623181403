//////	Text page

.section-textpage {
    padding: 30px 0;

    .row {
        @include row-grid(40px);
    }

    .text-sidebar {
        @include grid-item(3, 40px, 30px);

        & + .text-content {
            @include grid-item(3/2, 40px, 30px);
            font-size: 12px;
        }
    }

    .text-content {
        &.white-bg {
            background-color: #fff;
        }
    }

    @media screen and (max-width: 600px) {
         .text-sidebar {
             @include grid-item(1, 40px, 20px);

             & + .text-content {
                 @include grid-item(1, 40px, 20px);
             }
         }
    }

}

.widget-nav {
    font-size: 14px;

    a {
        display: block;
        font-weight: 700;
        color: #9b9b9b;
        padding: 19px 0 16px;
        border-bottom: 1px solid #d6d6d6;
        transition: color $time;

        &:hover {
            color: $blue;
        }

        &:first-child {
            border-top: 1px solid #d6d6d6;
        }

        &.is-active {
            color: $base;
        }
    }
}

.styling {
    color: #575757;

    h2, h3, h4, h5, h6 {
        font-weight: 700;
        margin: 10px 0 20px;
        color: $base;
    }

    h3 {
        font-size: 14px;
    }

    p {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 20px;
    }

    ol {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 5px;
        counter-reset: listnum;

        li {
            padding-left: 25px;
            color: #6e6e6e;
            position: relative;
            margin-bottom: 15px;

            &:before {
                display: block;
                line-height: 20px;
                position: absolute;
                left: 0;
                top: 0;
                font-weight: 700;
                font-size: 14px;
                color: #6e6e6e;
                counter-increment: listnum;
                content: counter(listnum) ". ";
            }

        }
    }

    ul {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 5px;

        li {
            padding-left: 20px;
            color: #6e6e6e;
            position: relative;
            margin-bottom: 15px;

            &:before {
                @extend %before;
                top: 8px;
                left: 0;
                width: 4px;
                height: 4px;
                background-color: $blue;
                border-radius: 4px;
            }
        }
    }

    img {
        display: block;
    }

    a {
        display: inline;
        color: darken($blue, 15%);
        border-bottom: 1px solid $blue;
        transition: background-color $time;

        &:hover {
            background-color: rgba($blue, .1);
        }
    }

}

.section-textpage {
    padding-bottom: 50px;

    .styling {
        section {
            border-bottom: 1px solid #ededed;
            padding: 40px 100px;
            color: #6e6e6e;
        }

        h2 {
            text-align: center;
            font-size: 16px;
            margin-bottom: 30px;
        }

        @media screen and (max-width: 600px) {
            section {
                padding: 30px;
            }
        }

    }

    &.section-about-us {
        padding-bottom: 0;

        .text-content {
            padding-top: 25px;
        }

        .subtitle-head {
            font-weight: 700;
            color: #cdcdcd;
            font-size: 20px;
            text-align: center;
            padding: 30px 0 25px;

            & + p {
                font-size: 13px;
                line-height: 18px;
                padding: 0 30px 30px;
                text-align: center;
                margin-bottom: 0;
            }
        }

    }

    .section-heading {
        padding-top: 10px;

        h1 {
            display: inline-block;
            font-weight: 700;
            color: $base;
            font-size: 20px;
        }

        p {
            font-size: 16px;
            line-height: 24px;
            color: #7c8084;
            max-width: 600px;
            margin: 15px auto 0;
        }

    }

    .border-quote {
        border: 1px dashed rgba($base, .3);
        border-radius: 2px;
        font-size: 16px;
        color: $blue;
        font-weight: 700;
        padding: 30px 30px;
        text-align: center;
        margin: 20px 0 0;
    }

}

.benefits-row {
    padding: 40px 0;
    position: relative;
    border-top: 1px solid #ededed;
    border-bottom: 1px solid #ededed;

    h3 {
        text-align: center;
    }

    ul {
        margin-bottom: 0;
    }

    &:before {
        @extend %before;
        top: 0;
        bottom: 0;
        left: 50%;
        width: 1px;
        background-color: #ededed;
    }

    .row {
        width: 100% !important;
        margin-left: 0 !important;
    }

    .grid-2 {
        @include grid-item(2, 0px, 0px);
        text-align: left;
        padding: 0 50px;
    }

    @media screen and (max-width: 600px) {
        padding: 20px;

        &:before {
            display: none;
        }

        .grid-2 {
            @include grid-item(1, 0px, 0px);
            padding: 0;
        }

    }

}