//-> 	Excerpt style


.post-row {
	@include row-grid(20px);

	.post-excerpt {
		@include grid-item(3, 20px, 20px);
	}

	@media screen and (max-width: 800px) {
		//@include row-grid(20px);

		.post-excerpt {
			@include grid-item(2, 20px, 20px);
		}
	}

	@media screen and (max-width: 550px) {
		@include row-grid(0);
		text-align: center;

		.post-excerpt {
			@include grid-item(1, 20px, 20px);
			width: 300px;
			margin: 20px auto 0 ;
			text-align: left;

			&:first-child {
				margin-top: 0;
			}
		}
	}

	@media screen and (max-width: 320px) {
		.post-excerpt {
			width: 290px;
		}
	}

}


.post-excerpt-town {

	figure {
		display: block;
		position: relative;

		&:before {
			@extend %before;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(#000, .25);
			border-radius: 2px;
			z-index: 1;
		}

		img {
			display: block;
			width: 100%;
			border-radius: 2px;
		}
	}

	figcaption {
		display: block;
		position: absolute;
		left: 0;
		width: 100%;
		text-align: center;
		top: 50%;
		color: #fff;
		z-index: 2;
		transform: translateY(-15%);

		h4 {
			font-size: 30px;
			font-weight: 700;
			margin: 0 0 10px;
		}

		p {
			font-size: 14px;
			font-weight: 700;
			margin: 0;
		}

	}

	@media screen {
		figure {
			overflow: hidden;

			&:before {
				transition: background-color $time*2;
			}
			img {
				filter: blur(.25px);
				transition: transform $time*10, filter $time*2;
			}

		}
		figcaption {
			transition: transform $time*2;

			p {
				opacity: 0;
				transition: opacity $time*2;
			}
		}

		&:hover {
			figure {
				&:before {
					background-color: rgba(#000, .45);
				}
				img {
					//transform: scale(1.1);
					filter: blur(0);
				}
			}
			figcaption {
				transform: translateY(-50%);

				p {
					opacity: 1;
				}
			}
		}
	}

	@media screen and (max-width: 1024px) {
		figcaption {
			transform: translateY(-50%);
		}
	}

}


.post-excerpt-object {
	background-color: #fff;
	padding: 0 15px 15px;
	position: relative;

	header {
		position: relative;

		h4 {
			font-weight: 700;
			font-size: 16px;
			margin: 3px 0 8px;

			a {
				display: inline-block;
				color: #575757;
				transition: color $time;

				&:hover {
					color: $blue;
				}
			}

			& + p {
				font-size: 14px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				color: #9b9b9b;
				margin: 0;
				max-width: 250px;
			}

		}

	}

	figure {
		display: block;
		margin-left: -15px;
		margin-right: -15px;
		margin-bottom: 15px;

		img {
			display: block;
			width: 100%;
			max-height: 215px;
			min-height: 215px;
			object-fit: cover;
		}

		a {
			display: block;
			position: relative;

			&:before {
				@extend %before;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(#000, .4);
				transition: background-color $time;
			}
		}
	}

	footer {
		padding-top: 18px;
		height: 32px;
		@extend %clear;

		.show-on-map {
			float: right;
			height: 30px;
			padding: 0 10px;
			margin-right: -5px;
			margin-top: -20px;
			position: relative;
			top: 10px;
			font-size: 13px;
			background-color: transparent;
			box-shadow: inset 0 0 0 1px #eee;

			@media screen {
				transition: background-color $time;

				&:hover {
					background-color: #eee;
				}
			}
		}
	}

	.pe-meta {

		.pe-author {
			position: absolute;
			right: 0;
			z-index: 5;
			bottom: 23px;
			border-radius: 30px;
			padding: 3px;
			background-color: #fff;

			img {
				display: block;
				height: 58px;
				width: 58px;
				object-fit: cover;
				border-radius: 30px;
				transition: opacity $time;
			}

			a:hover {
				img {
					opacity: .8;
				}
			}

		}

		.pe-fave {
			position: absolute;
			top: 15px;
			right: 0;
			z-index: 5;

			@media screen and (min-width: 1025px) {
				opacity: 0;
				visibility: hidden;
				transition: opacity $time*2, visibility $time*2;
			}
		}

		.pe-price {
			position: absolute;
			left: -15px;
			bottom: 75px;
			background-color: rgba(#000, .4);
			padding: 12px 15px;
			z-index: 5;
			font-weight: 700;
			color: #fff;
			border-right: 3px solid $blue;

			ins {
				text-decoration: none;
				font-size: 20px;
			}

			small {
				font-size: 16px;
				text-transform: lowercase;
				margin-left: 6px;
			}

		}

	}



	@media screen {
		transition: box-shadow $time*2;

		figure {
			a:before {
				transition: background-color $time*2;
			}
			img {
				filter: grayscale(80%);
				transition: filter $time*2;
			}
		}

		&:hover {
			box-shadow: 0 1px 10px rgba(#000, .3);

			.pe-meta {
				.pe-fave {
					opacity: 1;
					visibility: visible;
				}
			}

			figure {
				a:before {
					background-color: rgba(#000, .3);
				}
				img {
					filter: grayscale(0);
				}
			}
		}
	}

	&.post-in-top {
		box-shadow: 0 0 0 2px $yellow;

		figcaption {
			display: block;
			position: absolute;
			top: 16px;
			left: -17px;
			padding: 0 15px;
			width: auto;
			background-color: $yellow;
			height: 24px;
			line-height: 24px;
			font-size: 13px;
			font-weight: 700;
			color: #fff;
			text-transform: uppercase;
			transform: rotate(-45deg);

			&:before,
			&:after {
				@extend %before;
				width: 0;
				height: 0;
				top: 0;
				border: 12px solid rgba($yellow, 0);
			}

			&:before {
				right: -23px;
				border-left-color: $yellow;
				border-bottom-color: $yellow;
				transform: rotate(1deg);
			}

			&:after {
				left: -23px;
				border-right-color: $yellow;
				border-bottom-color: $yellow;
				transform: rotate(-1deg);
			}

		}

		.pe-price {
			background-color: $yellow;
			border-right-color: rgba(#050506,.8);
		}

	}

}


//	Common

.add-to-fave {
	height: 32px;
	width: 32px;
	text-align: center;
	background-color: rgba(#000, .4);
	border-radius: 17px;
	position: relative;
	transition: background-color $time, opacity $time;

	i {
		font-size: 20px;
		color: rgba(#fff, .8);
		vertical-align: text-bottom;
		transform: translateY(1px);
	}

	//svg {
	//	width: 20px;
	//	height: 20px;
	//	stroke-width: 2px;
	//	fill: rgba(#fff, .8);
	//	vertical-align: text-bottom;
	//	transform: translateY(1px);
	//	transition: color $time, stroke-width $time;
	//}

	&.is-load {
		opacity: .5;
	}

	&.is-active,
	&:hover {
		background-color: rgba(#000, .8);
	}

	&.is-active {
		background-color: $yellow;
		i {
			color: $base;
		}
		//svg {
		//	fill: $yellow;
		//}
	}

	&.clone-fave {
		position: absolute;
		z-index: 10000;
		height: 32px;
		width: 32px;
		text-align: center;

		@media screen and (max-width: 999px) {
			display: none;
		}
	}

}


.kill-this-fave {
	height: 32px;
	width: 32px;
	text-align: center;
	background-color: rgba(#000, .4);
	border-radius: 17px;
	position: relative;
	transition: background-color $time;

	i {
		font-size: 12px;
		color: rgba(#fff, .6);
		transform: translateY(1px);
	}

	//
	//svg {
	//	width: 12px;
	//	height: 12px;
	//	fill: rgba(#fff, .6);
	//}

	&:hover {
		background-color: rgba(#000, .8);
	}

}

