//	Plugins

//	Slick
.slick-slider {
    position: relative;
    padding: 0;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;

    &:before {
        @extend %before;
        top: 0;
        left: -20px;
        width: 20px;
        height: 100%;
        background-color: #eee;
        z-index: 4;
    }

    @media screen and (max-width: 1070px) {
        margin: 0 40px;
    }
    @media screen and (max-width: 550px) {
        margin: 0;
    }

}

.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    padding: 0;
    margin: 0 0 0 -20px;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}

.slick-slide {
    float: left;
    height: 100%;
    margin: 0 0 0 20px;
    min-height: 1px;
    position: relative;

    [dir="rtl"] & {
        float: right;
    }

    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }

}

.slick-arrow {
    cursor: pointer;
    position: absolute;
    font-size: 72px;
    height: 72px;
    line-height: 1;
    top: 100px;
    border: 0;
    background: rgba(#000, 0);
    z-index: 5;

    &.slick-prev-button {
        left: -60px;
    }

    &.slick-next-button {
        right: -60px;
    }

    svg {
        width: 1em;
        height: 1em;
        fill: #575757;
        transition: fill $time;
    }

    &:hover {
        svg {
            fill: $blue;
        }
    }

    &.slick-hidden {
        display: none;
    }

}

.slick {

    .slick-content {
        width: inherit;
        text-align: center;

        img {
            width: inherit;
            display: block;
        }

    }

    .slick-bottom {
        height: 30px;
        width: inherit;
        color: #DDD;
        background: #0F0F0F;
        line-height: 21px;

        .slick-controls {
            padding-top: 5px;

            img {
                width: 20px;
                margin-left: 5px;
                opacity: 0.7;
                transition: opacity 0.2s ease-in-out;

                &:hover {
                    opacity: 1;
                }
            }

        }

        .slick-no {
            float: right;
            padding-right: 10px;
            position: relative;
            padding-top: 5px;

            .skip {
                width: 16px;
                text-align: right;
                height: 20px;
                margin-right: 3px;
                padding: 1px 2px 0px 0px;
                border: 1px solid #666;
                background: #1E1E1E;
                color: #DDD;
                position: absolute;
                left: -20px;
                top: 5px;
                transition: all 0.2s ease-in-out;

                &:hover,
                &:focus,
                &:active {
                    background: #DDD;
                    color: black;
                    outline: none;
                }
            }

            .fullscr {
                padding-left: 10px;
                width: 20px;
                cursor: pointer;
                opacity: 0.7;
                transition: opacity 0.2s ease-in-out;

                &:hover {
                    opacity: 1;
                }

            }

            div {
                float: left;
                font-size: 0.9em;

                &:nth-child(1) span {
                    padding-left: 3px;
                }
            }

        }

    }

    .length {
        height: 1px;
        width: 37%;
        background: #7F8C8D;
    }

    .overlay-controls {

        .overlay-next {
            height: calc(100% - 35px);
            width: 50%;
            display: block;
            position: absolute;
            top: 0;
            right: 0;
        }

        .overlay-prev {
            height: calc(100% - 35px);
            width: 50%;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
        }

    }

}

.fullscreen-width {
    width: initial;
    border: none;
    box-shadow: none;
    height: 100%;

    .slick-content {
        width: initial;
        height: calc(100% - 35px);

        img {
            width: initial;
            height: 100%;
        }
    }

    .length {
        height: 2px;
    }

}

.fullscreen-height {
    width: 100%;
    border: none;
    box-shadow: none;
    height: initial;

    .slick-content {
        width: initial;
        height: initial;

        img {
            width: initial;
            height: 100%;
        }
    }

    .length {
        height: 2px;
    }

}

.slick-dots {
    @include row-grid(0);
    padding: 30px 0 40px;
    text-align: center;

    li {
        display: inline-block;
        vertical-align: top;
        margin: 0 3px;

        &.slick-active {
            button {
                &:before {
                    transform: translateY(-50%) translateX(-50%) scale(1.8);
                    border-color: $blue;
                    background-color: rgba($blue, 0);
                }
            }
        }
    }

    button {
        display: block;
        width: 14px;
        height: 14px;
        position: relative;
        text-indent: -99999px;

        &:before {
            @extend %before;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%) scale(1);
            width: 8px;
            height: 8px;
            border: 1px solid rgba(#070707, .2);
            border-radius: 50%;
            background-color: rgba(#070707, .5);
            transition: background-color $time, border-color $time, transform $time;
        }

        &:hover {
            &:before {
                background-color: $blue;
                border-color: $blue;
            }
        }

    }

}

//	Star Rate

.star-rating {
    @extend %tabl;
    white-space: nowrap;
    width: 70px;
    height: 14px;
    overflow: hidden;
    position: relative;
    background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIxMDI0cHgiIGhlaWdodD0iMTAyNHB4IiB2aWV3Qm94PSIwIDAgMTAyNCAxMDI0IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxMDI0IDEwMjQiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxnPjxwYXRoIGZpbGw9IiNjYmNiY2IiIGQ9Ik05OTYuNzk2LDM5Ny45OWMtMi43My04LjA2OS0xMC42NzItMjIuODQtMzYuMjQyLTI2LjgxMmwtMjg1LjQ4Ny00MS41ODNMNTUwLjQ0NSw3Ny42MjVjLTEyLjA0MS0yNC4wOC0yNi44MTEtMjguMTc2LTM4Ljg1MS0yOC4xNzZjLTI0LjA4MywwLTM3LjQ4OCwyNC4wNzgtNDAuMjE2LDI4LjE3NkwzNDYuNzU2LDMyOS41OThMNjUuMjQyLDM2OS44MTRjLTE4Ljc0MywyLjczLTMzLjUxNSwxMy40MDQtMzcuNDg2LDI4LjE3NmMtNS4zMzgsMTYuMTM2LDEuMzY0LDMyLjE0NywxNy4zNzYsNDguMjgzbDIwMS4wODIsMTk0LjM4MmwtNDYuOTE3LDI3Ny41NDFjLTEuMzY1LDYuNzA1LTUuMzM3LDI4LjE3Niw4LjA2OCw0Mi45NDljMTAuNjc0LDEzLjQwNSwyOS41NDIsMTYuMTM2LDQ5LjY0OSw2LjcwMmMwLDAsMS4zNjUsMCwxLjM2NS0xLjM2NWwyNTMuMzM4LTEzNC4wNTVsMjUzLjMzOSwxMzQuMDU1YzkuNDM0LDUuMzM5LDE4Ljc0NCw4LjA2OCwyNi44MSw4LjA2OGMxMC42NzYsMCwxOC43NDUtMy45NywyNS40NDYtMTAuNjc0YzUuMzM5LTUuMzM3LDEwLjY3Ni0xNi4xMzcsOC4wNjktMzYuMjQybC00OC40MDYtMjg2Ljk3OWwyMDUuMDU0LTE5OS43MTlDOTk5LjQwNCw0MjMuNDM2LDk5OS40MDQsNDA4Ljc5LDk5Ni43OTYsMzk3Ljk5eiIvPjwvZz48L3N2Zz4=');
    background-size: contain;

    i {
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        height: 14px;
        width: 14px;
        z-index: 1;
        background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIxMDI0cHgiIGhlaWdodD0iMTAyNHB4IiB2aWV3Qm94PSIwIDAgMTAyNCAxMDI0IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxMDI0IDEwMjQiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxnPjxwYXRoIGZpbGw9IiNmZmMwMDAiIGQ9Ik05OTYuNzk2LDM5Ny45OWMtMi43My04LjA2OS0xMC42NzItMjIuODQtMzYuMjQyLTI2LjgxMmwtMjg1LjQ4Ny00MS41ODNMNTUwLjQ0NSw3Ny42MjVjLTEyLjA0MS0yNC4wOC0yNi44MTEtMjguMTc2LTM4Ljg1MS0yOC4xNzZjLTI0LjA4MywwLTM3LjQ4OCwyNC4wNzgtNDAuMjE2LDI4LjE3NkwzNDYuNzU2LDMyOS41OThMNjUuMjQyLDM2OS44MTRjLTE4Ljc0MywyLjczLTMzLjUxNSwxMy40MDQtMzcuNDg2LDI4LjE3NmMtNS4zMzgsMTYuMTM2LDEuMzY0LDMyLjE0NywxNy4zNzYsNDguMjgzbDIwMS4wODIsMTk0LjM4MmwtNDYuOTE3LDI3Ny41NDFjLTEuMzY1LDYuNzA1LTUuMzM3LDI4LjE3Niw4LjA2OCw0Mi45NDljMTAuNjc0LDEzLjQwNSwyOS41NDIsMTYuMTM2LDQ5LjY0OSw2LjcwMmMwLDAsMS4zNjUsMCwxLjM2NS0xLjM2NWwyNTMuMzM4LTEzNC4wNTVsMjUzLjMzOSwxMzQuMDU1YzkuNDM0LDUuMzM5LDE4Ljc0NCw4LjA2OCwyNi44MSw4LjA2OGMxMC42NzYsMCwxOC43NDUtMy45NywyNS40NDYtMTAuNjc0YzUuMzM5LTUuMzM3LDEwLjY3Ni0xNi4xMzcsOC4wNjktMzYuMjQybC00OC40MDYtMjg2Ljk3OWwyMDUuMDU0LTE5OS43MTlDOTk5LjQwNCw0MjMuNDM2LDk5OS40MDQsNDA4Ljc5LDk5Ni43OTYsMzk3Ljk5eiIvPjwvZz48L3N2Zz4=');
        background-size: contain;
        transition: opacity .001s;

        & ~ i {
            width: 28px;

            & ~ i {
                width: 42px;

                & ~ i {
                    width: 56px;

                    & ~ i {
                        width: 70px;
                    }
                }
            }
        }

    }

    &[data-rate="1"]{
        i {
            opacity: 1;

            & ~ i {
                opacity: 0;

                & ~ i {
                    opacity: 0;

                    & ~ i {
                        opacity: 0;

                        & ~ i {
                            opacity: 0;
                        }
                    }
                }
            }
        }
    }

    &[data-rate="2"]{
        i {
            opacity: 0;

            & ~ i {
                opacity: 2;

                & ~ i {
                    opacity: 0;

                    & ~ i {
                        opacity: 0;

                        & ~ i {
                            opacity: 0;
                        }
                    }
                }
            }
        }
    }

    &[data-rate="3"]{
        i {
            opacity: 0;

            & ~ i {
                opacity: 0;

                & ~ i {
                    opacity: 1;

                    & ~ i {
                        opacity: 0;

                        & ~ i {
                            opacity: 0;
                        }
                    }
                }
            }
        }
    }

    &[data-rate="4"]{
        i {
            opacity: 0;

            & ~ i {
                opacity: 0;

                & ~ i {
                    opacity: 0;

                    & ~ i {
                        opacity: 1;

                        & ~ i {
                            opacity: 0;
                        }
                    }
                }
            }
        }
    }

    &[data-rate="5"]{
        i {
            opacity: 0;

            & ~ i {
                opacity: 0;

                & ~ i {
                    opacity: 0;

                    & ~ i {
                        opacity: 0;

                        & ~ i {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    input {
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        cursor: pointer;
        opacity: 0;
        display: inline-block;
        width: 20%;
        height: 100%;
        margin: 0;
        padding: 0;
        z-index: 2;
        position: relative;
        transition: opacity .001s;

        //&:hover,
        &:checked {
            opacity: 0;

            & + i {
                opacity: 1;
            }
        }

    }

    &.no-rate {
        input {
            &:hover,
            &:checked {
                opacity: 0;

                & + i {
                    opacity: 1;
                }
            }
        }
    }

}

/* perfect-scrollbar v0.6.5 */

.ps-container {
    -ms-touch-action: none;
    overflow: hidden !important;

    &.ps-active-x > .ps-scrollbar-x-rail,
    &.ps-active-y > .ps-scrollbar-y-rail {
        display: block;
    }

    &.ps-in-scrolling {
        pointer-events: none;

        &.ps-x > .ps-scrollbar-x-rail {
            background-color: #eee;
            opacity: 0.9;

            & > .ps-scrollbar-x {
                background-color: #999;
            }
        }

        &.ps-y > .ps-scrollbar-y-rail {
            background-color: #eee;
            opacity: 0.9;

            & > .ps-scrollbar-y {
                background-color: #999;
            }
        }

    }

    & > .ps-scrollbar-x-rail {
        display: none;
        position: absolute;
        /* please don't change 'position' */
        border-radius: 4px;
        opacity: 0;
        transition: background-color .2s linear, opacity .2s linear;
        bottom: 3px;
        /* there must be 'bottom' for ps-scrollbar-x-rail */
        height: 8px;

        & > .ps-scrollbar-x {
            position: absolute;
            /* please don't change 'position' */
            background-color: #aaa;
            border-radius: 4px;
            transition: background-color .2s linear;
            bottom: 0;
            /* there must be 'bottom' for ps-scrollbar-x */
            height: 8px;
        }
    }

    & > .ps-scrollbar-y-rail {
        display: none;
        position: absolute;
        /* please don't change 'position' */
        border-radius: 4px;
        opacity: 0;
        transition: background-color .2s linear, opacity .2s linear;
        right: 3px;
        /* there must be 'right' for ps-scrollbar-y-rail */
        width: 8px;

        & > .ps-scrollbar-y {
            position: absolute;
            /* please don't change 'position' */
            background-color: #aaa;
            border-radius: 4px;
            transition: background-color .2s linear;
            right: 0;
            /* there must be 'right' for ps-scrollbar-y */
            width: 8px;
        }
    }

    &:hover {

        .ps-in-scrolling {
            pointer-events: none;

            &.ps-x > .ps-scrollbar-x-rail {
                background-color: #eee;
                opacity: 0.9;

                & > .ps-scrollbar-x {
                    background-color: #999;
                }
            }

            &.ps-y > .ps-scrollbar-y-rail {
                background-color: #eee;
                opacity: 0.9;

                & > .ps-scrollbar-y {
                    background-color: #999;
                }
            }

        }

        & > .ps-scrollbar-x-rail {
            opacity: 0.6;

            &:hover {
                background-color: #eee;
                opacity: 0.9;

                & > .ps-scrollbar-x {
                    background-color: #999;
                }
            }
        }

        & > .ps-scrollbar-y-rail {
            opacity: 0.6;

            &:hover {
                background-color: #eee;
                opacity: 0.9;

                & > .ps-scrollbar-y {
                    background-color: #999;
                }
            }
        }

    }

}

//	Select Replace

.select2-container {
    box-sizing: border-box;
    display: inline-block;
    margin: 0;
    position: relative;
    vertical-align: middle;
}

.select2-container .select2-selection--single {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    height: 28px;
    user-select: none;
    -webkit-user-select: none;
}

.select2-container .select2-selection--single .select2-selection__rendered {
    display: block;
    padding-left: 8px;
    padding-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
    padding-right: 8px;
    padding-left: 20px;
}

.select2-container .select2-selection--multiple {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    min-height: 32px;
    user-select: none;
    -webkit-user-select: none;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
    display: inline-block;
    overflow: hidden;
    padding-left: 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.select2-container .select2-search--inline {
    float: left;
}

.select2-container .select2-search--inline .select2-search__field {
    box-sizing: border-box;
    border: none;
    font-size: 100%;
    margin-top: 5px;
    -webkit-appearance: none;
}

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
    -webkit-appearance: none;
}

.select2-dropdown {
    background-color: white;
    border: 1px solid #aaa;
    border-radius: 4px;
    box-sizing: border-box;
    display: block;
    position: absolute;
    left: -100000px;
    width: 100%;
    z-index: 1051;
}

.select2-results {
    display: block;
}

.select2-results__options {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
}

.select2-results__option {
    padding: 10px 15px;
    user-select: none;
    -webkit-user-select: none;
}

.select2-results__option[aria-selected] {
    cursor: pointer;
}

.select2-container--open .select2-dropdown {
    left: 0;
    position: relative;
    max-height: 200px;
}

.select2-container--open .select2-dropdown--above {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.select2-search--dropdown {
    display: block;
    padding: 4px;
}

.select2-search--dropdown .select2-search__field {
    padding: 4px;
    width: 100%;
    box-sizing: border-box;
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
    -webkit-appearance: none;
}

.select2-search--dropdown.select2-search--hide {
    display: none;
}

.select2-close-mask {
    border: 0;
    margin: 0;
    padding: 0;
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    min-height: 100%;
    min-width: 100%;
    height: auto;
    width: auto;
    opacity: 0;
    z-index: 99;
    background-color: #fff;
    filter: alpha(opacity=0);
}

.select2-hidden-accessible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.select2-container--default .select2-selection--single {
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 4px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 28px;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: 400;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #999;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 26px;
    position: absolute;
    top: 1px;
    right: 1px;
    width: 20px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: #888 transparent transparent transparent;
    border-style: solid;
    border-width: 5px 4px 0 4px;
    height: 0;
    left: 50%;
    margin-left: -4px;
    margin-top: -2px;
    position: absolute;
    top: 50%;
    width: 0;
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
    float: left;
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
    left: 1px;
    right: auto;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
    background-color: #eee;
    cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
    display: none;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent #888 transparent;
    border-width: 0 4px 5px 4px;
}

.select2-container--default .select2-selection--multiple {
    background-color: white;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: text;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
    box-sizing: border-box;
    list-style: none;
    margin: 0;
    padding: 0 5px;
    width: 100%;
}

.select2-container--default .select2-selection--multiple .select2-selection__placeholder {
    color: #999;
    margin-top: 5px;
    float: left;
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: 400;
    margin-top: 5px;
    margin-right: 10px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: #e4e4e4;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: default;
    float: left;
    margin-right: 5px;
    margin-top: 5px;
    padding: 0 5px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    color: #999;
    cursor: pointer;
    display: inline-block;
    font-weight: 400;
    margin-right: 2px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
    color: #333;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__placeholder {
    float: right;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
    margin-left: 5px;
    margin-right: auto;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
    margin-left: 2px;
    margin-right: auto;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
    border: solid black 1px;
    outline: 0;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
    background-color: #eee;
    cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
    display: none;
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
    border: 1px solid #aaa;
}

.select2-container--default .select2-search--inline .select2-search__field {
    background: transparent;
    border: none;
    outline: 0;
}

.select2-container--default .select2-results > .select2-results__options {
    max-height: 180px;
    position: relative;
    overflow-y: auto;
}

.select2-container--default .select2-results__option[role=group] {
    padding: 0;
}

.select2-container--default .select2-results__option[aria-disabled=true] {
    color: #999;
}

.select2-container--default .select2-results__option[aria-selected=true] {
    background-color: #ddd;
}

.select2-container--default .select2-results__option .select2-results__option {
    padding-left: 1em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
    padding-left: 0;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -1em;
    padding-left: 2em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -2em;
    padding-left: 3em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -3em;
    padding-left: 4em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -4em;
    padding-left: 5em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -5em;
    padding-left: 6em;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #5897fb;
    color: white;
}

.select2-container--default .select2-results__group {
    cursor: default;
    display: block;
    padding: 6px;
}

.select2-container--classic .select2-selection--single {
    background-color: #f6f6f6;
    border: 1px solid #aaa;
    border-radius: 4px;
    outline: 0;
    background-image: -webkit-linear-gradient(top, #ffffff 50%, #eeeeee 100%);
    background-image: -o-linear-gradient(top, #ffffff 50%, #eeeeee 100%);
    background-image: linear-gradient(to bottom, #ffffff 50%, #eeeeee 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#eeeeee', GradientType=0);
}

.select2-container--classic .select2-selection--single:focus {
    border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 28px;
}

.select2-container--classic .select2-selection--single .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: 400;
    margin-right: 10px;
}

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
    color: #999;
}

.select2-container--classic .select2-selection--single .select2-selection__arrow {
    background-color: #ddd;
    border: none;
    border-left: 1px solid #aaa;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    height: 26px;
    position: absolute;
    top: 1px;
    right: 1px;
    width: 20px;
    background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
    background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
    background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#cccccc', GradientType=0);
}

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
    border-color: #888 transparent transparent transparent;
    border-style: solid;
    border-width: 5px 4px 0 4px;
    height: 0;
    left: 50%;
    margin-left: -4px;
    margin-top: -2px;
    position: absolute;
    top: 50%;
    width: 0;
}

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear {
    float: left;
}

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
    border: none;
    border-right: 1px solid #aaa;
    border-radius: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    left: 1px;
    right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--single {
    border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
    background: transparent;
    border: none;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent #888 transparent;
    border-width: 0 4px 5px 4px;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-image: -webkit-linear-gradient(top, #ffffff 0%, #eeeeee 50%);
    background-image: -o-linear-gradient(top, #ffffff 0%, #eeeeee 50%);
    background-image: linear-gradient(to bottom, #ffffff 0%, #eeeeee 50%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#eeeeee', GradientType=0);
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-image: -webkit-linear-gradient(top, #eeeeee 50%, #ffffff 100%);
    background-image: -o-linear-gradient(top, #eeeeee 50%, #ffffff 100%);
    background-image: linear-gradient(to bottom, #eeeeee 50%, #ffffff 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#ffffff', GradientType=0);
}

.select2-container--classic .select2-selection--multiple {
    background-color: white;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: text;
    outline: 0;
    box-sizing: border-box;
}

.select2-container--classic .select2-selection--multiple:focus {
    border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--multiple .select2-selection__rendered {
    list-style: none;
    margin: 0;
    padding: 0 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
    display: none;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
    background-color: #e4e4e4;
    border: 1px solid #aaa;
    //border-radius:    4px;
    cursor: default;
    float: left;
    //margin-right:     5px;
    //margin-top:       5px;
    //padding:          0 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
    color: #888;
    cursor: pointer;
    display: inline-block;
    font-weight: 400;
    margin-right: 2px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
    color: #555;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
    float: right;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
    margin-left: 5px;
    margin-right: auto;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
    margin-left: 2px;
    margin-right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--multiple {
    border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.select2-container--classic .select2-search--dropdown .select2-search__field {
    //border:  1px solid #aaa;
    outline: 0;
}

.select2-container--classic .select2-search--inline .select2-search__field {
    outline: 0;
}

.select2-container--classic .select2-dropdown {
    background-color: white;

}

.select2-container--classic .select2-dropdown--above {
    border-bottom: none;
}

.select2-container--classic .select2-dropdown--below {
    border-top: none;
}

.select2-container--classic .select2-results > .select2-results__options {
    max-height: 180px;
    overflow-y: auto;
    position: relative;
}

.select2-container--classic .select2-results__option[role=group] {
    padding: 0;
}

.select2-container--classic .select2-results__option[aria-disabled=true] {
    color: $base;
}

.select2-container--classic .select2-results__option--highlighted[aria-selected] {
    background-color: #ededed;
    color: $base;
}

.select2-container--classic .select2-results__group {
    cursor: default;
    display: block;
    padding: 8px 15px;
}

.select2-container--classic.select2-container--open .select2-dropdown {
    //border-color: #5897fb;
}


.ps-scrollbar-y-rail {
    background-color: rgba($base,.2) !important;
    opacity: 1 !important;
    visibility: visible;
    display: block !important;
    width: 2px !important;
    right: 5px !important;
}

.ps-scrollbar-y {
    width: 4px !important;
    right: -1px !important;
}

.select2-results__option {
    &[aria-selected="true"]{
        color: $blue !important;
    }
}


//.ps-container > .ps-scrollbar-x-rail,
//.ps-container {
//    overflow: auto !important;
//
//    & > .ps-scrollbar-y-rail {
//        opacity: 0.6 !important;
//    }
//}