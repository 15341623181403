////////////////////////////////////////
//		Sdat
////////////////////////////////////////

.form-select {
    margin-bottom: 20px;

    .svg-arrow-down {
        width: 10px;
        height: 9px;
        position: absolute;
        bottom: 14px;
        right: 14px;
        fill: rgba($base,.2);
    }

    .icon-arrow_down {
        font-size: 9px;
        position: absolute;
        bottom: 14px;
        right: 14px;
        color: rgba($base,.2);
    }


    .select2 {
        display: block;
        width: 100% !important;
    }

    .select2-search--inline {
        display: block;
        float: none;
        font-size: 14px;
    }

    .select2-selection__rendered {
        display: block !important;
        padding: 0 !important;

        li:not(.select2-search--inline) {
            display: none;
        }
    }

    input[type="search"] {
        border: 1px solid #d6dcdf !important;
        width: 100% !important;
        padding: 0 15px !important;
        height: 40px;
        margin-top: 0 !important;

        &:focus {
            border: 1px solid darken(#d6dcdf, 10%) !important;
        }
    }

    &.form-many-select {
        .select2-selection__rendered {
            position: relative;
            padding-top: 40px !important;
            white-space: normal !important;
            overflow: visible;

            li {
                &:not(.select2-search--inline) {
                    display: inline-block !important;
                    padding: 0 13px 0 5px !important;
                    line-height: 1.5;
                    cursor: pointer;
                    font-size: 12px;
                    margin: 10px 10px 0 0;
                    position: relative;
                    border: 0;
                    background: #eee;
                    transition: color $time, background-color $time;

                    .select2-selection__choice__remove {
                        position: absolute;
                        top: 1px;
                        right: 2px;
                        color: $grey;
                        display: block;
                        transition: color $time;
                    }

                    &:hover {
                        //color: $blue;
                        color: #fff;
                        background-color: $mblue;

                        .select2-selection__choice__remove {
                            //color: $blue;
                            color: #fff;
                        }
                    }

                }

                &.select2-search--inline {
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 40px;
                }
            }

        }
    }

}

.nav-surrender {
    margin-bottom: 20px;
    @include row-grid(3px);

    div {
        @include grid-item(3, 3px, 0);
        height: 78px;
        border: 1px solid #cdcdcd;
        border-left: 0;
        border-right: 0;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 700;
        padding-left: 40px;
        position: relative;
        color: rgba($base, .2);

        span {
            display: block;
            font-size: 40px;
            line-height: 1;
            position: absolute;
            top: 50%;
            margin-top: -20px;
            left: 0;
        }

        b {
            display: block;
            position: absolute;
            top: 50%;
            left: 40px;
            right: 0;
            line-height: 1.25;
            transform: translateY(-50%);
        }

        &.is-active {
            border-color: $blue;
            color: $blue;
        }
    }

    @media screen and (max-width: 600px) {
        div {

            &:not(.is-active){
                width: 78px;

                span {
                    width: 100%;
                    text-align: center;
                }
                b {
                    display: none;
                }
            }

            &.is-active {
                width: calc(100% - (78px * 2) - 9px);
            }
        }

    }

    @media screen and (max-width: 400px) {
        div {

            &:not(.is-active){
                width: 60px;

                span {
                    width: 100%;
                    text-align: center;
                }
                b {
                    display: none;
                }
            }

            &.is-active {
                width: calc(100% - (60px * 2) - 9px);
            }
        }

    }

}

.section-surrender {
    padding-bottom: 1px;

    .form-submit {
        padding: 10px 0 30px;
        position: relative;

        .button-border {
            text-transform: none;
            position: absolute;
            top: 10px;
            left: 0;
        }

        .ff-checkbox {
            display: block;
            width: auto;
            position: absolute;
            top: 15px;
            margin-bottom: 0;
            left: 50%;
            transform: translateX(-50%);

            label {
                font-size: 14px;
            }

            a {
                color: $blue;
                transition: color $time;

                &:hover {
                    color: darken($blue, 15%);
                }
            }
        }

        @media screen and (max-width: 600px) {
            .ff-checkbox {
                transform: translateX(0);
                left: 0;
                top: 0;

                & + .button-blue {
                    margin-top: 30px;

                    & + .button-border {
                        top: 40px;
                    }
                }
            }
        }

    }

    p {
        font-size: 12px;
        color: $grey;
        line-height: 1.5;
        margin: 5px 0 20px;
    }

    .label-common {
        margin-bottom: 8px;
    }

    .fieldset-name {
        font-weight: 700;
        color: #cdcdcd;
        font-size: 20px;
        text-align: center;
        margin-bottom: 25px;
    }

    .fieldset-subname {
        font-weight: 700;
        color: rgba($base, .8);
        text-transform: uppercase;
        font-size: 14px;

        & + .checkbox-list {
            margin-top: 25px;
        }
    }

    .checkbox-list {

        &.cl-comfort {
            .form-field {
                @include grid-item(3, 25px, 5px);
            }
            @media screen and (max-width: 600px) {
                .form-field {
                    @include grid-item(2, 25px, 5px);
                }
            }
            @media screen and (max-width: 480px) {
                .form-field {
                    @include grid-item(1, 25px, 5px);
                }
            }
        }

        &.cl-rules {
            label {
                line-height: 18px;
                font-size: 14px;

                &:before {
                    top: 3px;
                }

                &:after {
                    top: 4px;
                }
            }
        }

    }

    .container {
        max-width: 780px;
    }

    .form-field {
        margin-bottom: 20px;

        &.ff-textarea {
            textarea {
                height: 200px;
            }
        }
    }

    .form-map-view {
        margin-bottom: 25px;
    }

    .row {
        @include row-grid(25px);

        .grid-1 {
            @include grid-item(1, 25px, 0);
        }

        .grid-2 {
            @include grid-item(2, 25px, 0);
        }

        .grid-3 {
            @include grid-item(3, 25px, 0);
        }

        @media screen and (max-width: 600px){
            .grid-3 {
                @include grid-item(2, 25px, 0);
            }
        }

        @media screen and (max-width: 480px){
            .grid-1,
            .grid-2,
            .grid-3 {
                @include grid-item(1, 25px, 0);
            }
        }
    }

    fieldset {
        background-color: #fff;
        padding: 30px 60px;
        margin-bottom: 20px;

        @media screen and (max-width: 600px) {
            padding: 30px;
        }
    }

    .button-link {
        font-size: 14px;
        padding: 0;
        height: 25px;
        color: $blue;
        border-radius: 0;
        border-bottom: 1px solid $blue;
        transition: color $time;

        &:hover {
            color: darken($blue, 15%);
        }
    }
}

.load-post-photo {
    .row {
        .row {
            @include row-grid(5px);

            .grid-2 {
                @include grid-item(2, 5px, 0);

                .form-field {
                    margin-bottom: 5px;
                }
            }
        }
    }
}

.ff-upload {
    position: relative;
    height: 90px;
    overflow: hidden;

    svg {
        width: 30px;
        height: 24px;
        fill: rgba(#000, .1);
        transition: fill $time;
    }

    button {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 10;
        height: 30px;
        width: 30px;
        border-radius: 15px;
        background-color: rgba(#000, .5);
        opacity: 0;
        visibility: hidden;
        transition: background-color $time, opacity $time, visibility $time;

        svg {
            width: 14px;
            height: 14px;
            fill: #fff;
        }

        &:hover {
            background-color: #000;
        }
    }

    &.ffu-cover {
        height: 190px;

        svg {
            width: 69px;
            height: 55px;
        }

        button {
            svg {
                width: 14px;
                height: 14px;
            }
        }

        label div span {
            font-size: 14px;
        }

    }

    input[type="file"]{
        opacity: 0;
        z-index: 5;
        position: absolute;
        top: 0;
        right: 0;
        font-size: 600px;
    }

    label {
        display: block;
        position: absolute;
        overflow: hidden;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 6;
        cursor: pointer;
        border: 1px dashed rgba(#000, .1);
        transition: border-color $time;

        div {
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            text-align: center;
            color: rgba(#000, .1);
            transform: translateY(-50%);
            transition: color $time;

            span {
                display: block;
                font-size: 12px;
                margin-top: 8px;
            }
        }
    }

    &:hover {
        label {
            border-color: rgba(#000, .3);
        }
        .svg-photo {
            fill: rgba(#000, .3);
        }
        div {
            color: rgba(#000, .3);
        }
    }

    &.ff-img-load {
        &:hover {
            button {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    &.is-load {

        label div {
            display: none;
        }

        .ffu-remove {
            opacity: 1;
            background-color: $base;
            visibility: visible;
            color: #9ccaec;

            @media screen {
                transition: color $time;

                &:hover {
                    color: #fff;
                }
            }
        }

    }

}



