//  Popups


.reveal-modal-bg {
    position: fixed;
    height: 100%;
    width: 100%;
    background: #000;
    z-index: 100;
    display: none;
    top: 0;
    left: 0;
}

.reveal-modal {
    visibility: hidden;
    top: 100px;
    left: 50%;
    margin-left: -300px;
    width: 520px;
    background-color: #fff;
    //background: #eee url(modal-gloss.png) no-repeat -200px -80px;
    position: absolute;
    z-index: 101;
    padding: 30px;
    border-radius: 2px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .4);

    &.small {
        width: 200px;
        margin-left: -140px;
    }

    &.medium {
        width: 360px;
        margin-left: -180px;
    }

    &.large {
        width: 600px;
        margin-left: -300px;
    }

    &.xlarge {
        width: 800px;
        margin-left: -440px;
    }

    .close-modal {
        position: absolute;
        top: 0;
        right: 10px;

        svg {
            width: 13px;
            height: 13px;
            fill: $grey;
        }

        @media screen {
            svg {
                transition: fill $time;
            }
            &:hover {
                svg {
                    fill: darken($grey, 15%);
                }
            }
        }
    }

    &.reveal-map {
        width: 800px;
        margin-left: -400px;

        .close-modal {
            background-color: transparent;
            width: 40px;
            right: -40px;
            color: #fff;
        }

        .modal-map-box {
            height: 400px;

            @media screen and (max-height: 320px) {
                height: 90vh;
            }
        }
    }

    &.reveal-sign {
        .close-modal {
            background-color: transparent;
            width: 40px;
            right: -40px;
            color: #fff;
        }
    }

    @media screen and (max-width: 610px) {
        &.large {
            width: 90%;
            margin-left: 5%;
            left: 0;
        }
        &.reveal-map {
            padding: 0;
             .close-modal {
                 display: none;
             }
        }
    }

    @media screen and (max-width: 480px) {
        width: 320px;
        padding: 20px;
        margin-left: -160px;

        &.medium {
            width: 320px;
            padding: 20px;
            margin-left: -160px;
        }

        .close-modal {
            right: 5px;
        }
    }

    .pop_up_error {
        display: none;
        font-size: 14px;
        line-height: 18px;
        color: $red;

        &:before {
            content: '';
            display: block;
            height: 15px;
            position: relative;
            width: 100%;
        }
    }

    &.reveal-write-review {
        .form-field {
            span {
                display: none;
            }
        }
    }
}

/*

NOTES

Close button entity is &#215;

Example markup

<div id="myModal" class="reveal-modal">
    <h2>Awesome. I have it.</h2>
    <p class="lead">Your couch.  I it's mine.</p>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In ultrices aliquet placerat. Duis pulvinar orci et nisi euismod vitae tempus lorem consectetur. Duis at magna quis turpis mattis venenatis eget id diam. </p>
    <a class="close-reveal-modal">&#215;</a>
</div>

*/

.reveal-style {

    h3 {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 30px;
        color: $blue;
        text-align: center;

        &.color-grey {
            color: rgba($base,.5);
        }
    }

    img {
        display: block;
        margin: 0 auto 20px;
    }

    p {
        font-size: 14px;
        line-height: 20px;
        color: rgba($base, .5);
        text-align: center;
        margin-bottom: 20px;
    }

    .form-field {
        margin-bottom: 20px;
    }

}

.reveal-sign {
    padding: 0;

    .sign-tab {
        @include row-grid(0);

        button {
            @include grid-item(2,0px,0px);
            font-size: 18px;
            font-weight: 700;
            height: 60px;
            line-height: 60px;
            text-align: center;
            padding: 0;
            background-color: #eeeeee;
            color: rgba($base,.5);
            transition: color $time, background-color $time;

            &:hover {
                color: $blue;
            }

            &.is-active {
                background-color: #fff;
                color: $blue;
            }
        }
    }

    .form-submit,
    .form-field {
        margin-bottom: 20px;
    }

    form {
        padding: 20px;
        background-color: #fff;
        display: none;

        &.is-active {
            display: block;
        }
    }

    .social-login {
        @include row-grid(20px);

        p {
            margin-left: 20px;
            text-align: center;
            position: relative;
            padding-top: 25px;
            margin-bottom: 20px;
            font-size: 14px;
            color: rgba($base,.5);

            &:before {
                @extend %before;
                top: 5px;
                left: 0;
                right: 0;
                height: 1px;
                background-color: #eee;
                z-index: 1;
            }

            small {
                display: block;
                position: absolute;
                font-size: 12px;
                top: 0;
                padding: 0 20px;
                background-color: #fff;
                left: 50%;
                transform: translateX(-50%);
                z-index: 2;
            }

        }


        button {
            @include grid-item(2, 20px, 0px);
            font-size: 12px;
            padding: 0;
            color: #fff;
            text-transform: uppercase;

            svg {
                fill: #fff;
                width: 17px;
                height: 17px;
                vertical-align: text-bottom;
                margin-right: 5px;
            }

            &.af-fb-link {
                background-color: $fbcolor;

                svg {
                    width: 13px;
                    height: 13px;
                    transform: translateY(-2px);
                }

                &:hover {
                    background-color: darken($fbcolor, 10%);
                }
            }

            &.af-vk-link {
                background-color: $vkcolor;

                &:hover {
                    background-color: darken($vkcolor, 10%);
                }
            }

        }

    }

}