////////////////////////////////////////
//		Mixin
////////////////////////////////////////


@mixin row-grid($ml: 35px) {
	display: table;
	font-size: 0 !important;
	margin-left: (-1)*$ml;
	@if $ml == 0 {
		width: 100%;
	} @else {
		width: calc(100% + #{$ml});
	}
}


@mixin grid-item($gicol: '3', $giml: 35px, $gimb: 35px){
	display: inline-block;
	vertical-align: top;
	margin-left: $giml;
	margin-bottom: $gimb;

	@if $gicol == 0 {
		width: calc(100% - #{$giml});
	} @else {
		width: calc((100% / #{$gicol}) - #{$giml});
	}
    //
	//@if $ml == 0 {
	//	width: 100%;
	//} @else {
	//	width: calc(100% + #{$ml});
	//}

}

@mixin fade-up-hide(){
	transition: margin $time, opacity $time, visibility $time;
	margin-top: 20px;
	opacity: 0;
	visibility: hidden;
}

@mixin fade-up-show(){
	margin-top: 0;
	opacity: 1;
	visibility: visible;
}


@mixin button-fill($bgcolor: '#ed0', $linkcolor: '#fff', $height: '30px', $padding: '0 15px') {
	background-color: #{$bgcolor};
	height: #{$height};
	padding: #{$padding};
	color: #{$linkcolor};
	transition: background-color $time, color $time;

	&:hover {
		background-color: darken(#{$bgcolor}, 10%);
		color: lighten(#{$linkcolor}, 5%);
	}

}


@mixin link-border($clr: #fff){

	display: inline;
	color: $clr;
	border-bottom: 1px solid transparent;
	transition: border-color $time;

	&:hover {
		border-color: rgba($clr, .3);
	}

}

@mixin link-bordered($clr: #fff){

	display: inline;
	color: $clr;
	border-bottom: 1px solid rgba($clr, .5);
	transition: border-color $time;

	&:hover {
		border-color: rgba($clr, 0);
	}

}


@mixin mail-bg($bgcolor: #fff){
	background: $bgcolor url(../img/mail-bg.png) 0 0 no-repeat;
	background-size: cover;
}


@mixin p-style($mb: 35px){
	font-size: 16px;
	line-height: 24px;
	margin-bottom: $mb;
}




