//////	Product page

.subhead-nav {
    background-color: #fff;
    height: 60px;
    text-align: right;
    @include row-grid(0);

    &-single {
        border-top: 1px solid #eee;
    }

    &.for-page-filter {
        height: 36px;
        position: relative;
        z-index: 10;
    }

    .breadcrumbs {
        display: inline-block;
        vertical-align: top;
        width: calc(100% - 390px);
        margin-right: 10px;
        text-align: left;
        font-size: 11px;
        color: $text_color;
        line-height: 1.2;
        position: relative;
        top: 30px;
        transform: translateY(-50%);

        &.bc-long {
            width: calc(100% - 70px);
        }

        &.bc-half {
            width: 65%;
            margin-right: 0;
            display: block;
            margin-top: 10px;
            transform: none;
            top: 0;

            @media screen and (max-width: 1000px) {
                width: 100%;
            }
        }

        a {
            display: inline-block;
            vertical-align: top;
            position: relative;
            color: rgba($text_color, .8);
            transition: opacity $time;

            &:hover {
                color: rgba($text_color, 1);
            }

        }

        span {
            display: inline-block;
            vertical-align: top;
            padding-left: 20px;
            position: relative;

            &:before {
                @extend %before;
                top: 2px;
                left: 8px;
                width: 4px;
                height: 13px;
                opacity: .6;
                background-size: contain;
                background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTAiIGhlaWdodD0iMjgiIHZpZXdCb3g9IjAgMCAxMCAyOCI+PHBhdGggZmlsbD0iIzQ0NDQ0NCIgZD0iTTkuMjk3IDE1cTAgMC4yMDMtMC4xNTYgMC4zNTlsLTcuMjgxIDcuMjgxcS0wLjE1NiAwLjE1Ni0wLjM1OSAwLjE1NnQtMC4zNTktMC4xNTZsLTAuNzgxLTAuNzgxcS0wLjE1Ni0wLjE1Ni0wLjE1Ni0wLjM1OXQwLjE1Ni0wLjM1OWw2LjE0MS02LjE0MS02LjE0MS02LjE0MXEtMC4xNTYtMC4xNTYtMC4xNTYtMC4zNTl0MC4xNTYtMC4zNTlsMC43ODEtMC43ODFxMC4xNTYtMC4xNTYgMC4zNTktMC4xNTZ0MC4zNTkgMC4xNTZsNy4yODEgNy4yODFxMC4xNTYgMC4xNTYgMC4xNTYgMC4zNTl6Ij48L3BhdGg+PC9zdmc+);
            }

            span {
                padding: 0;
                &:before {
                    display: none;
                }
            }

            &:first-child {
                padding-left: 0;

                &:before {
                    display: none;
                }

                a {
                    color: $blue;

                    &:hover {
                        color: darken($blue, 10%);
                    }
                }
            }
        }

        @media screen and (max-width: 600px) {

            &.open-bread {
                display: none;
            }

            &.bc-long {
                width: 100%;

                & + .button-settings {
                    display: none;
                }
            }
        }
    }

    .button-settings {
        height: 60px;
        width: 60px;
        margin: 0;
        text-align: center;
        border-left: 1px solid #ececec;
        border-right: 1px solid #ececec;

        svg {
            width: 12px;
            height: 12px;
            fill: rgba(#40454c, .6);
            transform: translateY(1px);
            transition: fill $time;
        }

        &:hover {
            svg {
                fill: $blue;
            }
        }
    }

    .link-prev-flat,
    .link-next-flat {
        display: inline-block;
        vertical-align: top;
        text-align: left;
        height: 60px;
        line-height: 60px;
        color: rgba($gray, .5);
        transition: color $time;
        font-size: 11px;
        text-transform: uppercase;
        padding: 0 20px;
        border-right: 1px solid #ececec;

        svg {
            width: 11px;
            height: 11px;
            transform: translateY(1px);
            fill: rgba($gray, .5);
            transition: fill $time;
        }

        &:hover {
            color: $blue;

            svg {
                fill: $blue;
            }
        }
    }

    .link-next-flat {
        padding: 0 20px 0 35px;
        width: 157px;

        svg {
            margin-left: 15px;
        }
    }

    .link-prev-flat {
        padding: 0 35px 0 20px;
        width: 163px;

        svg {
            margin-right: 15px;
        }
    }

    @media screen and (max-width: 600px) {

        .link-prev-flat,
        .link-next-flat {
            padding: 0;
            text-align: center;
            width: calc((100% - 60px) / 2);
        }

        .link-prev-flat {
            svg {
                margin-right: 5px;
            }
        }

        .link-next-flat {
            svg {
                margin-left: 5px;
            }
        }

    }

}

.section-relate {

    .post-row {

        .post-excerpt {

            @media screen and (min-width: 800px) {
                &:last-child {
                    display: none;
                }
            }

        }

    }

}

.post-flat {
    background-color: #eee;
    padding: 20px 0 25px;

    .row {
        @include row-grid(20px);
    }

    .flat-info {
        @extend %ibt;
        width: 320px;
        margin-left: 20px;
    }

    .fi-widget {
        background-color: #fff;
        margin-top: 20px;

        &:first-child {
            margin-top: 0;
        }
    }

    .flat-preview {
        @extend %ibt;
        width: calc(100% - 360px);
        margin-left: 20px;

        img {
            display: inline-block;
            max-width: 100%;
        }

        .ft-view {
            margin-bottom: 4px;
            position: relative;
            line-height: 440px;
            text-align: center;

            svg {
                width: 60px;
                height: 60px;
                position: absolute;
                top: 50%;
                left: 50%;
                margin-left: -30px;
                margin-top: -30px;
                position: absolute;
                z-index: 1;
            }

            img {
                display: inline-block;
                vertical-align: middle;
                position: relative;
                z-index: 2;
                transition: opacity .2s;

                &.is-load {
                    opacity: 0;
                }
            }

        }

        .ft-thumb {
            margin-left: -4px;
            a {
                @include grid-item(4, 4px, 0);
                position: relative;

                &:before {
                    @extend %before;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border: 2px solid rgba($blue, 0);
                    z-index: 1;
                    transition: border-color $time;
                }

                &:hover {
                    &:before {
                        border-color: rgba($blue, .5);
                    }
                }

                &.is-active {
                    &:before {
                        border-color: $blue;
                    }
                }

                &.ft-slider-run {
                    &:after {
                        @extend %before;
                        top: 0;
                        left: 0;
                        background-color: rgba(#000, .4);
                        width: 100%;
                        height: 100%;
                        z-index: 1;
                        transition: background-color $time;
                    }
                    span {
                        position: absolute;
                        top: 50%;
                        left: 0;
                        width: 100%;
                        line-height: 1.45;
                        font-size: 14px;
                        font-weight: 700;
                        text-align: center;
                        z-index: 2;
                        transform: translateY(-50%);
                        color: #fff;
                    }

                    &:hover {
                        &:after {
                            background-color: rgba(#000, .6);
                        }
                    }
                }

            }
        }
    }

    @media screen and (max-width: 990px) {

        .row {
            width: 100%;
            margin-left: 0;
        }

        .flat-preview {
            display: block;
            width: 660px;
            margin: 0 auto;

            .ft-view {
                line-height: 1;
            }
        }

        .flat-info {
            width: 100%;
            max-width: 660px;
            display: block;
            margin: 20px auto 0;
        }

    }

    @media screen and (max-width: 659px) {
        .flat-preview {
            width: 100%;
            text-align: center;
            position: relative;

            .ft-thumb {

                a {

                    &:not(.ft-slider-run) {
                        display: none;
                    }

                    &.ft-slider-run {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        margin: 0;
                        z-index: 3;
                        cursor: pointer;

                        &:before {
                            display: none;
                        }

                        img {
                            display: none;
                        }

                        &:after {
                            display: none;
                        }

                        span {
                            display: none;
                        }
                    }
                }

            }
        }
        .flat-info {
            max-width: 100%;
        }
    }

}

.flat-info {

    .flat-day-price {
        background-color: rgba(#2a3138, .9);
        color: #fff;
        position: relative;
        padding: 14px 20px 13px;

        b {
            font-weight: 700;
            font-size: 16px;

            span {
                font-size: 24px;
            }
        }

        ins {
            text-decoration: none;
            font-size: 16px;
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
        }

    }

    .button-fdp-down {
        background-color: rgba(#2a3138, .8);
        color: rgba(#fff, .5);
        //height: 32px;
        height: 38px;
        transition: color $time;
        border-radius: 0;
        width: 100%;
        font-size: 12px;

        svg {
            width: 16px;
            height: 16px;
            fill: rgba(#fff, .5);
            margin-right: 2px;
            vertical-align: text-bottom;
            transition: fill $time;
        }

        &:hover {
            color: #fff;

            svg {
                fill: #fff;
            }
        }

    }

    .flat-author {
        display: block;
        position: relative;
        height: 90px;
        background-color: #d7d7d7;
        padding-left: 20px;

        &.is-thumb {
            padding-left: 90px;
        }

        img {
            width: 53px;
            max-height: 53px;
            object-fit: cover;
            display: block;
            position: absolute;
            top: 50%;
            margin-top: -29px;
            left: 20px;
            border-radius: 50%;
            border: 3px solid #fff;
        }

        figcaption {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;

            b {
                font-size: 16px;
                color: #575757;
                display: block;
                margin-bottom: 4px;
            }

            span {
                color: #9a9a9a;
                font-size: 14px;
            }

        }

    }

    .flat-author-phone {
        background-color: $blue;
        color: #fff;
        height: 50px;
        line-height: 50px;
        position: relative;
        padding: 0 20px 0 45px;

        i {
            font-size: 24px;
            color: #fff;
            position: absolute;
            top: 50%;
            left: 20px;
            margin-top: -12px;
        }
        //
        //svg {
        //    width: 14px;
        //    height: 24px;
        //    fill: #fff;
        //    position: absolute;
        //    top: 50%;
        //    left: 20px;
        //    margin-top: -12px;
        //}

        span {
            font-weight: 700;
            font-size: 14px;
            text-transform: uppercase;
            line-height: 51px;
        }

        button {
            position: absolute;
            top: 50%;
            margin-top: -15px;
            right: 10px;
            padding: 0 10px;
            height: 30px;
            color: #fff;

            @media screen {
                transition: background-color $time;

                &:hover {
                    background-color: darken($blue, 10%);
                }
                &:active {
                    background-color: darken($blue, 20%);
                }
            }
        }

    }

    .flat-adres {
        padding: 15px 20px 10px;

        i {
            font-size: 21px;
            color: $blue;
            position: relative;
            margin-left: -4px;
            float: left;
        }
        //
        //svg {
        //    width: 21px;
        //    height: 21px;
        //    fill: $blue;
        //    position: relative;
        //    margin-left: -4px;
        //    float: left;
        //}

        b {
            color: $base;
            font-size: 14px;
            margin-bottom: 7px;
            display: block;
            margin-left: 25px;
        }

        span {
            color: #bbb;
            display: block;
            margin-left: 25px;
            font-size: 14px;
        }

        .flat-button-groupe {
            clear: both;
            width: 100%;
            border: 1px solid rgba(#6e6e6e, .4);
            border-radius: 2px;
            @include row-grid(0);
            padding: 5px 0;
            margin-top: 10px;
            margin-bottom: 15px;

            button {
                height: 26px;
                width: calc((100% - 15px) / 2);
                margin-left: 5px;

                @media screen {
                    transition: background-color $time;

                    &:hover {
                        background-color: rgba(#6e6e6e, .2);
                    }

                    &:active {
                        background-color: rgba(#6e6e6e, .3);
                    }
                }



                &:first-child {
                    position: relative;

                    &:before {
                        @extend %before;
                        top: 0;
                        bottom: 0;
                        right: -3px;
                        width: 1px;
                        background-color: rgba(#6e6e6e, .2);
                    }
                }

            }
        }

        p {
            text-align: center;
            margin-bottom: 5px;

            a {
                display: inline-block;
                vertical-align: top;
                position: relative;
                font-size: 11px;
                color: $blue;
                line-height: 1.2;

                &:before {
                    @extend %before;
                    bottom: -2px;
                    left: 0;
                    width: 0;
                    height: 1px;
                    background-color: rgba($blue, .4);
                    transition: width $time;
                }

                &:hover {
                    &:before {
                        width: 100%;
                    }
                }

            }
        }

    }

    .flat-fave {
        margin: 15px 20px;
        width: calc(100% - 40px);
        border: 1px solid rgba(#6e6e6e, .4);
        color: #575757;
        height: 38px;

        i {
            font-size: 21px;
            vertical-align: text-bottom;
            margin-right: 4px;
        }

        //svg {
        //    width: 21px;
        //    height: 21px;
        //    fill: #575757;
        //    vertical-align: text-bottom;
        //    margin-right: 4px;
        //    transition: fill $time;
        //}

        @media screen {
            transition: color $time, border-color $time, background-color $time;

            &:hover {
                background-color: rgba(#6e6e6e, .05);
            }

            &:active {
                background-color: rgba(#6e6e6e, .1);
            }
        }

        &.is-active {
            color: $blue;
            border-color: $blue;
            //
            //svg {
            //    fill: $blue;
            //}

        }

    }

    .flat-rate {
        text-align: right;
        position: relative;
        padding: 0 20px;
        height: 50px;
        border-top: 1px solid rgba(#000, .05);

        span {
            display: block;
            position: absolute;
            top: 50%;
            left: 20px;
            line-height: 49px;
            font-size: 12px;
            color: $gray;
            transform: translateY(-50%);
        }

    }

}

.flat-share {
    //margin: 0 20px 14px;
    margin: 0 20px;
    padding: 0 0 15px;

    a {
        display: inline-block;
        vertical-align: top;
        width: calc((100% - 20px) / 3);
        position: relative;
        padding: 5px 0;
        margin-left: 5px;
        font-size: 12px;
        color: $gray;
        white-space: nowrap;

        i {
            font-size: 20px;
            vertical-align: text-bottom;
        }

        //svg {
        //    width: 20px;
        //    height: 20px;
        //    fill: $gray;
        //    vertical-align: text-bottom;
        //    transition: fill $time;
        //}

        @media screen {
            transition: color $time;

            &:hover {
                &.fb-share {
                    color: $fbcolor;
                }
                &.vk-share {
                    color: $vkcolor;
                }
                &.tw-share {
                    color: $twcolor;
                }
            }
        }

    }

}

.nav-wrapper {
    position: relative;
    height: 52px;
    @media screen and (max-width: 659px) {
        display: none;
    }
}
.nav-black {
    background-color: $base;
    height: 52px;

    a {
        display: inline-block;
        vertical-align: top;
        height: 52px;
        line-height: 52px;
        color: rgba(#fff, .4);
        transition: color $time;
        position: relative;
        padding: 0 20px;
        font-size: 14px;

        &:hover {
            color: #fff;
        }

        &:before {
            @extend %before;
            bottom: 0;
            height: 0;
            left: 0;
            width: 100%;
            background-color: $blue;
            transition: height $time;
        }

        &.is-active {
            color: #fff;

            &:before {
                height: 4px;
            }
        }

    }

    &.nav-post-flat {
        position: absolute;
        width: 100%;
        left: 0;
        z-index: 1;
        transition: transform $time;

        &.is-fixed {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 100;
            transform: translateY(0);

            &.is-fixed-stop {
                transform: translateY(-120%);
            }
        }

        @media screen and (max-width: 659px) {
            display: none;
        }
    }

    &.nav-for-textpage {

        @media screen and (max-width: 770px) {
            min-height: 52px;
            height: auto;

            a {
                display: block;
                padding-left: 0;

                &:not(.is-active) {
                    display: none;
                }
            }
        }

    }
}

.nav-post-content {
    //padding-top: 52px;
    //position: relative;

    .npc-tab {
        //width: 660px;
        //position: relative;
        //position: absolute;
        //top: -100000px;
        //left: -100000px;

        &.npc-map {
            height: 480px;
            width: 100%;
        }

        //&.is-active {
        //position: relative;
        //top: 0;
        //left: 0;
        //}
    }

    @media screen and (max-width: 659px) {
        padding-top: 0;
    }
}

.flat-description {
    width: 660px;

    .fd-heading {
        font-size: 18px;
        color: $base;
        padding: 40px 0 15px;
        font-weight: 700;
    }

    .fd-sub-heading {
        color: $blue;
        font-size: 16px;
        margin-bottom: 35px;
    }

    .fd-heading,
    .fd-sub-heading {
        h1, h2, h3, h4, h5, h6 {
            font-size: inherit;
            margin: 0;
            padding: 0;
            color: inherit;
            font-weight: inherit;
        }
    }

    .fd-content {
        font-size: 14px;
        color: $gray;

        b, a {
            color: #6e6e6e;
        }

        dl {
            border-top: 1px solid #ececec;
            padding: 25px 0 25px 150px;
            position: relative;

            dt {
                position: absolute;
                top: 25px;
                left: 0;
                font-weight: 700;
                color: #6e6e6e;
                line-height: 22px;
            }

            dd {
                line-height: 22px;
            }

        }

        .two-column {
            column-count: 2;
            columns-gap: 20px;
        }

        .icons-list {
            padding-top: 15px;
            dt {
                line-height: 44px;
                top: 15px;
            }

            ul {
                column-count: 2;
                column-gap: 20px;

                li {
                    line-height: 44px;
                    position: relative;
                }

                i {
                    font-size: 18px;
                    margin-right: 4px;
                    vertical-align: text-bottom;
                    position: relative;
                    top: -1px;
                }

                //svg {
                //    display: inline-block;
                //    vertical-align: text-bottom;
                //    margin-right: 5px;
                //    width: 24px;
                //    height: 24px;
                //    fill: $gray;
                //}

            }

        }

        .common-list {
            list-style-type: none;

            li {
                position: relative;
                padding-left: 15px;

                &:before {
                    @extend %before;
                    top: 9px;
                    left: 0;
                    width: 3px;
                    height: 3px;
                    border-radius: 3px;
                    background-color: $gray;
                }
            }

        }

    }

    .fdc-adres {
        @include row-grid(20px);

        div {
            @include grid-item(3, 20px, 25px);
            font-size: 14px;
        }
    }

    @media screen and (max-width: 659px) {
        width: 100%;

        .fd-content {
            dl {
                padding-left: 0;
                dt {
                    position: relative;
                    display: block;
                    left: 0;
                    top: 0;
                }
            }
        }
    }

    @media screen and (max-width: 580px) {
        .fd-content {
            //.icons-list ul,
            .two-column {
                column-gap: 0;
                column-count: 1;
            }
        }
        .fdc-adres div {
            @include grid-item(1, 20px, 10px);
        }
    }
}

.text-show-wrap {
    max-height: 88px;
    overflow: hidden;
    transition: max-height $time;
}

.ts-trigger {
    font-size: 12px;
    color: $blue;
    height: initial;
    margin-top: 2px;
    transition: color $time;

    i {
        font-size: 9px;
        margin-left: 2px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
    }
    //
    //svg {
    //    width: 9px;
    //    height: 9px;
    //    margin-left: 2px;
    //    vertical-align: middle;
    //    fill: $blue;
    //    transition: fill $time, transform $time;
    //}

    @media screen {
        &:hover {
            color: darken($blue, 8%);
        }
    }

    &.is-open {
        i {
            transform: rotate(180deg);
        }
    }

}

.picker-block {
    .is-hidden {
        display: none;
    }
}

//  Reviews

.reviews-heading {
    position: relative;
    padding: 50px 0 0;
    margin-bottom: 55px;
    width: 660px;

    h3 {
        font-size: 18px;
        color: rgba($base, .7);
        margin: 0;
        font-weight: 700;
        line-height: 1.35;

        ins {
            text-decoration: none;
        }
    }

    button {
        position: absolute;
        top: 55px;
        border-radius: 0;
        right: 0;
        height: initial;
        font-size: 14px;
        color: $blue;
        padding: 0;
        line-height: 1.35;
        border-bottom: 1px solid;
        font-weight: 700;
        transition: color $time;

        &:hover {
            color: darken($blue, 10%);
        }
    }

    @media screen and (max-width: 659px) {
        width: 100%;
    }

}

.review-list {
    width: 660px;

    & + .button {
        margin-top: 30px;
        margin-bottom: 50px;
    }

    @media screen and (max-width: 659px) {
        width: 100%;
    }
}

//  Some Comment
.comment-item {
    position: relative;
    display: block;
    padding: 25px 0 25px 130px;
    min-height: 105px;
    border-top: 1px solid #ececec;

    &:first-child {
        border-top: 0;
        padding-top: 0;

        .comment-author {
            top: 0;
        }
    }

    .comment-author {
        position: absolute;
        top: 25px;
        left: 0;
        width: 80px;

        div {
            width: 80px;
            padding: 3px;
            border-radius: 50%;
            margin-bottom: 10px;
            background-color: $gray;

            img {
                display: block;
                width: 100%;
                max-width: 80px;
                border-radius: 50%;
            }
        }

        figcaption {
            width: 80px;
            white-space: nowrap;
            color: $base;
            font-weight: 700;
            font-size: 14px;
            text-align: center;

            a {
                color: $base;
                display: inline-block;
                position: relative;

                &:before {
                    @extend %before;
                    bottom: -2px;
                    left: 0;
                    width: 0;
                    height: 2px;
                    background-color: rgba($base, .7);
                    transition: width $time;
                }

                &:hover {
                    &:before {
                        width: 100%;
                    }
                }

            }
        }

    }

    .comment-content {
        p {
            color: $gray;
            font-size: 14px;
            line-height: 22px;
            margin-bottom: 10px;
        }
    }

    .comment-meta {
        @include row-grid(0);

        time,
        .star-rate,
        .comment-reply {
            display: inline-block;
            vertical-align: top;
            margin-left: 30px;
            width: auto;
            font-size: 12px;
            color: $gray;
            line-height: 1;
        }

        time {
            margin-left: 0;
        }

        .comment-reply {
            height: initial;
            border-radius: 0;
            padding: 0;
            font-weight: 700;
            text-transform: lowercase;
            transition: color $time;

            &:hover {
                color: darken($gray, 15%);
            }
        }

    }

    .comment-reply-list {
        margin-top: 25px;
        margin-bottom: -25px;

        .comment-item {
            padding-top: 25px;
            padding-left: 100px;
            border-top: 1px solid #ececec;

            .comment-author {
                top: 25px;

                div {
                    width: 60px;

                    img {
                        max-width: 60px;
                    }
                }
            }
        }

    }

    .comment-response {
        font-size: 14px;
        color: #575757;
        font-weight: 700;
        margin-bottom: 15px;
    }

    @media screen and (max-width: 480px) {
        padding-left: 100px;

        .comment-meta {
            time,
            .star-rate,
            .comment-reply {
                display: block;
                margin: 5px 0 0;
            }
        }

        .comment-reply-list {
            .comment-item {
                padding-left: 0;

                .comment-author {
                    display: none;
                }
            }
        }
    }
}

//  Calendar
.price-picker {
    width: 660px;
    min-height: 500px;
    padding: 40px 0;
    background-color: #fff;
    transition: all $time;

    .datepick {
        width: 100% !important;
    }

    table {
        width: 100%;
        table-layout: fixed;

        th {
            padding: 20px 0;
            font-weight: 700;
            color: rgba($base, .7);
            font-size: 12px;
            text-align: center;
        }

        td {
            border: 1px solid #cccccc;

            span {
                display: block;
                height: 72px;
                position: relative;
                color: rgba($base, .6);
                text-decoration: none;
                cursor: default;

                ins {
                    display: block;
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    text-decoration: none;
                    font-size: 12px;
                    font-weight: 700;
                }

                b {
                    position: absolute;
                    font-weight: 400;
                    bottom: 18px;
                    left: 0;
                    width: 100%;
                    text-align: center;
                    color: rgba($base, .4);
                    font-size: 14px;
                }

            }
        }

    }

    .datepick-nav {
        position: relative;
        min-height: 23px;

        .datepick-cmd {
            display: block;
            position: absolute;
            bottom: 0;
            width: 30px;
            height: 30px;
            border-radius: 23px;
            border: 1px solid $grey;
            text-align: center;
            line-height: 30px;

            svg {
                width: 23px;
                height: 23px;
                fill: $grey;
                vertical-align: text-bottom;
                transform: translateY(1px);
            }

            &.datepick-cmd-today {
                display: none;
            }

            &.datepick-cmd-next {
                right: 0;
            }

            &.datepick-cmd-prev {
                left: 0;
            }

        }
    }

    .datepick-month-header {
        text-align: center;
        margin: 0 30px;
        transform: translateY(-23px);

        select {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            background-color: transparent;
            border: 0;
            color: $base;
            font-weight: 700;
            font-size: 14px;

            & + select {
                margin-left: 5px;
            }

        }

    }

    .datepick-month-year {

    }

    @media screen and (max-width: 659px) {
        width: 100% !important;
        table {
            td {
                span {
                    height: 60px;

                    b {
                        bottom: 8px;
                    }
                }

            }
        }
    }

    //@media screen and (max-width: 425px) {
    //    display: none;
    //}
}

//  Slider

body {
    &.is-open {
        height: 100vh;
        width: 100vw;
        overflow: hidden;
    }
}

.post-photo-slider {
    display: block;
    position: fixed;
    top: 0;
    left: -150%;
    width: 100vw;
    height: 100vh;
    z-index: 10000;
    background-color: $base;
    transition: left .4s;

    &.is-active {
        left: 0;

        .slick-slider {
            transform: translateX(0);
        }
    }

    .slick-slider {
        margin: 0 !important;
        padding: 0;
        transition: transform .4s ease .2s;
        transform: translateX(-100%);

        &:before {
            display: none;
        }
    }

    .slick-arrow {
        height: 100vh;
        top: 0;
        background-color: darken($base, 3%);

        @media screen {
            transition: background-color $time, color $time;

            &:hover {
                color: $blue;
                background-color: darken($base, 5%);
            }
        }

        &.slick-prev-button {
            left: 0;
        }
        &.slick-next-button {
            right: 0;
        }



        @media screen and (max-width: 659px) {
            font-size: 40px;

        }
    }

    .slick-list {
        margin: 0;
    }

    .slick-slide {
        height: 100vh;
        margin: 0;
        line-height: 100vh;
        text-align: center;

        img {
            display: inline-block;
            vertical-align: middle;
            max-width: 98vw;
            max-height: 98vh;
        }

    }

    .pps-close {
        position: absolute;
        width: 72px;
        height: 72px;
        top: 0;
        right: 0;
        z-index: 10000;
        font-size: 26px;
        color: rgba(#fff,.5);
        text-align: center;
        //
        //svg {
        //    width: 26px;
        //    height: 26px;
        //    fill: rgba(#fff, .5);
        //    transition: fill $time;
        //}

        @media screen {
            transition: color $time;

            &:hover {
                color: $blue;
            }
        }
        //&:hover {
        //    color: $blue;
        //    //svg {
        //    //    fill: $blue;
        //    //}
        //}
    }

}

.section-relate-slider {
    padding-bottom: 1px;

    .slick-slider {
        .slick-list {
            margin-bottom: 20px;
        }
        .slick-dots {
            padding-top: 0;
        }
    }

}