//	Layout

.sitepage {
	max-width: 100vw;
	overflow-x: hidden;

	&.filter-page {

		.container {
			max-width: 1440px;
			padding: 0 20px;
			//max-width: 100%;
		}

		.header {
			.nav {
				right: 20px;
			}

			.select-lang {
				right: calc(100vw - 240px);

				//@media screen and (max-width: 1000px) {
				//	right: calc(100vw - 310px);
				//}

				@media screen and (max-width: 1023px) {
					right: -20px;
				}
			}
		}

		.footer {
			.container {
				max-width: 1000px;
			}
		}

		@media screen and (max-width: 1023px){
			.header {
				.nav {
					right: 0;
				}
			}
		}

		@media screen and (max-width: 350px) {
			.container {
				width: 100%;
				padding: 0 15px;
				max-width: 100%;
			}
		}

	}

	&.body-dd-open {
		.main.no-home {
			position: relative;
			z-index: 1000;
		}
	}

	@media screen {
		&.is-reveal-open {
			transition: filter $time;
			filter: blur(1px);
		}
	}
}

.container {
	max-width: 1000px;
	width: 100%;
	margin: 0 auto;
	position: relative;

	@media screen and (max-width: 1023px) {
		max-width: 100%;
		padding: 0 15px;

		.subhead-nav & {
			padding: 0 0 0 15px;
		}
	}
}



.header {
	@extend %bvh;
}

.main {
	min-height: calc(100vh - 194px);
	&.no-home {
		padding-top: 63px;
	}
}

.svg-sprite {
	position: absolute;
	top: -10000px;
	left: -100000px;
}

.footer {
	position: relative;
	z-index: 6;
}

.page-text-divider {
	display: block;
	height: 0;
	visibility: hidden;
	opacity: 0;
}

.filter-map {
	background-color: lighten($red, 30%);
	position: fixed;
	top: 0;
	left: 700px;
	right: 0;
	bottom: 0;
	z-index: 5;

	@media screen and (max-width: 1000px) {
		display: none;
	}
}



