[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: $iconFont !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-adv:before {
    content: "\e900";
}
.icon-angle_left:before {
    content: "\e901";
}
.icon-angle_right:before {
    content: "\e902";
}
.icon-arrow_down:before {
    content: "\e903";
}
.icon-arrow_left:before {
    content: "\e904";
}
.icon-arrow_right:before {
    content: "\e905";
}
.icon-ava:before {
    content: "\e906";
}
.icon-balcony:before {
    content: "\e907";
}
.icon-basket:before {
    content: "\e908";
}
.icon-bathroom:before {
    content: "\e909";
}
.icon-boiler:before {
    content: "\e90a";
}
.icon-cog:before {
    content: "\e90b";
}
.icon-condition:before {
    content: "\e90c";
}
.icon-done:before {
    content: "\e90d";
}
.icon-dryer:before {
    content: "\e90e";
}
.icon-edit:before {
    content: "\e90f";
}
.icon-fb:before {
    content: "\e910";
}
.icon-fireplace:before {
    content: "\e911";
}
.icon-gift:before {
    content: "\e912";
}
.icon-hairdryer:before {
    content: "\e913";
}
.icon-heart:before {
    content: "\e914";
}
.icon-house_phone:before {
    content: "\e915";
}
.icon-info:before {
    content: "\e916";
}
.icon-interntet:before {
    content: "\e917";
}
.icon-iron:before {
    content: "\e918";
}
.icon-jacuzzi:before {
    content: "\e919";
}
.icon-mail:before {
    content: "\e91a";
}
.icon-marker:before {
    content: "\e91b";
}
.icon-minus:before {
    content: "\e91c";
}
.icon-mobile:before {
    content: "\e91d";
}
.icon-new_adv:before {
    content: "\e91f";
}
.icon-new_del:before {
    content: "\e921";
}
.icon-new_done:before {
    content: "\e922";
}
.icon-new_heart:before {
    content: "\e924";
}
.icon-new_mail:before {
    content: "\e926";
}
.icon-new_mod:before {
    content: "\e927";
}
.icon-new_pay:before {
    content: "\e92e";
}
.icon-new_phone:before {
    content: "\e92f";
}
.icon-new_shield:before {
    content: "\e930";
}
.icon-new_stats:before {
    content: "\e937";
}
.icon-new_user:before {
    content: "\e938";
}
.icon-notification:before {
    content: "\e93b";
}
.icon-oven:before {
    content: "\e93c";
}
.icon-pause:before {
    content: "\e93d";
}
.icon-pay:before {
    content: "\e93e";
}
.icon-phone:before {
    content: "\e93f";
}
.icon-photo:before {
    content: "\e940";
}
.icon-play:before {
    content: "\e941";
}
.icon-plus:before {
    content: "\e942";
}
.icon-protection:before {
    content: "\e943";
}
.icon-remove:before {
    content: "\e944";
}
.icon-search:before {
    content: "\e945";
}
.icon-settings:before {
    content: "\e946";
}
.icon-shield:before {
    content: "\e947";
}
.icon-shower:before {
    content: "\e948";
}
.icon-star:before {
    content: "\e949";
}
.icon-stats:before {
    content: "\e94a";
}
.icon-svch:before {
    content: "\e94b";
}
.icon-terrace:before {
    content: "\e94c";
}
.icon-tv:before {
    content: "\e94d";
}
.icon-tw:before {
    content: "\e94e";
}
.icon-user:before {
    content: "\e94f";
}
.icon-views:before {
    content: "\e950";
}
.icon-vk:before {
    content: "\e951";
}
.icon-washer:before {
    content: "\e952";
}
.icon-wifi:before {
    content: "\e953";
}
.icon-winner:before {
    content: "\e954";
}