@media screen and (min-width: 1000px) {

	%before {
		content:  '';
		display:  block;
		position: absolute;
	}

	.header {


		.dropdown-type:not(.select-inbox) {

			ul li {
				a {
					transition:  background-color $time, color $time;

					&:hover {
						color:            $base;
						background-color: #ededed;
					}
				}
				&:first-child {
					&:hover {
						&:after {
							border-bottom-color: #ededed;
						}
					}
				}
			}

			.dt-is-trigger {

				span {
					position:    relative;
					display:     inline-block;
					line-height: 40px;

					&:before {
						content:          '';
						display:          block;
						position:         absolute;
						bottom:           7px;
						left:             0;
						width:            0;
						height:           1px;
						background-color: rgba(#fff, .4);
						transition:       width $time;
					}
				}

				&:hover {
					span:before {
						width: 100%;
					}
				}

			}
		}

		.select-inbox {
			.dt-is-trigger {
				&:hover {
					svg {
						fill: #fff;
					}
				}
			}
			ul li .dt-is-end {
				&:hover {
					color: #fff;
					background-color: darken(#40454c, 5%);
				}
			}
			.si-message-item {
				transition: background-color $time;

				&:hover {
					background-color: #eee;
				}
			}
		}
        //
		//.select-user-zone {
		//	ul li .dt-is-end {
		//		&:hover {
		//			color: #fff !important;
		//			background-color: darken(#40454c, 5%) !important;
		//		}
		//	}
		//}


		.nav {

			.nav-link-fave {
				&:hover {
					svg {
						fill: #fff;
					}
				}
			}

			.link-hover {

				a {
					position:    relative;
					display:     inline-block;
					line-height: 40px;

					&:before {
						content:          '';
						display:          block;
						position:         absolute;
						bottom:           7px;
						left:             0;
						width:            0;
						height:           1px;
						background-color: rgba(#fff, .4);
						transition:       width $time;
					}

					&:hover {
						&:before {
							width: 100%;
						}
					}
				}

			}
		}

	}

	.footer {

		.foot-service a,
		.foot-nav ul li a {
			&:before {
				content:          '';
				display:          block;
				position:         absolute;
				bottom:           -1px;
				height:           1px;
				left:             0;
				width:            0;
				background-color: rgba(#fff, .5);
				transition:       width $time;
			}

			&:hover {
				&:before {
					width: 100%;
				}
			}
		}

		.foot-creator a {
			transition: color $time;

			&:before {
				content:          '';
				display:          block;
				position:         absolute;
				bottom:           -1px;
				left:             0;
				width:            100%;
				height:           1px;
				background-color: rgba(#4b4e52, .8);
				transition:       background-color $time;
			}

			&:hover {
				color: rgba(#fff, .8);

				&:before {
					background-color: rgba(#4b4e52, .3);
				}
			}
		}

	}

	.social-link a {
		&:hover {
			svg {
				fill: rgba(#fff, .8);
			}
		}
	}

	//.section-content-page {
	//	.scp-trigger {
	//		transition: color $time;
	//		&:hover {
	//			color: darken($blue, 8%);
	//			svg {
	//				fill: darken($blue, 8%);
	//			}
	//		}
	//	}
	//}

	.section-town-ua li a {
		transition: color $time;
		&:hover {
			color: $blue;
		}
	}

}



