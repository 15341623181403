////////////////////////////////////////
//		Extend
////////////////////////////////////////

%clear {
	&:after {
		content: '';
		display: table;
		clear: both;
	}
}


%before {
	content: '';
	display: block;
	position: absolute;
}


%ibt {
	display: inline-block;
	vertical-align: top;
}


%ibm {
	display: inline-block;
	vertical-align: middle;
}


%tabl {
	display: table;
	width: 100%;
	font-size: 0 !important;
}


%bvh {
	-webkit-backface-visibility: hidden;
}

%tooltip {
	& > .tooltip {
		opacity: 1;
		visibility: visible;

		& {
			&.tt-top,
			&.tt-bottom {
				margin-top: 0;
			}

			&.tt-right,
			&.tt-left {
				margin-left: 0;
				margin-right: 0;
			}

		}
	}
}






