////////////////////////////////////////
//		Var
////////////////////////////////////////


$time: .2s;


//	Fonts
//_______
//______

$fontName: 'Some Sans';

$fontLight: 'MuseoLight';
$font: 'MuseoLight';
$fontBold: 'MuseoBold';
$fontBlack: 'MuseoBlack';

$iconFont: 'icomoon';



//	Color
//________
//_______


$base: #26282b;
$red: #c0392b;
$light_red: #bf392b;
$yellow: #ffc000;
$green: #37c17f;
$aqua: #31c0ba;


//////////////////////
$bg_form_srch: #2980b9;
$bg_blqt: #f8f9f9;
//////////////////////


$bgcolor: #f4f5f7;
$text_color: #3d3d3c;
$head_color: #34393c;
$blue: #45a7f1;
$grey: #a8adb0;

$gray: #9b9b9b;



//  Metro Color
$mred: #cf384d;
$mblue: #477ee3;
$mgreen: #27b30f;

//	Social Color
$fbcolor: #395799;
$vkcolor: #4e75a0;
$gpcolor: #D34231;
$twcolor: #21a8ef;
$pintcolor: #bd081c;




