//	Form

select,
textarea,
input {
    font-family: $fontName, Arial, sans-serif;
    font-size: 14px;
    color: $base;
    box-sizing: border-box;
}

button {
    background: transparent;
    border: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
    font-family: $fontName, Arial, sans-serif;
    font-size: 14px;
    height: 40px;
    color: $base;
    border-radius: 2px;
    box-sizing: border-box;
    transition: background-color $time;

    &.button-blue {
        color: #fff;
        background-color: $blue;

        &:hover {
            background-color: darken($green, 5%);
        }
    }

    &.button-green {
        background-color: $green;
        color: #fff;

        &:hover {
            background-color: darken($green, 5%);
        }
    }

    &.button-blue,
    &.button-green {
        padding: 0 20px;

        &:active {
            box-shadow: inset 0 1px 2px rgba(#000, .2);
        }

    }

    &.button-fw {
        padding: 0;
        text-align: center;
        width: 100%;
    }

}

//.section-surrender {
//    .button.button-border {
//        transform: skewX(0deg) skewY(0deg);
//    }
//}

.select2-container--open {
    .select2-dropdown--below {
        margin-top: 2px;
    }

    .select2-dropdown--above {
        margin-top: -2px;
    }

    .select2-dropdown {
        border: 1px solid #d6dcdf !important;
        border-radius: 2px !important;
    }
}

.select2-selection--multiple {
    border-radius: 2px !important;
    border: 0 !important;
}


.form-field {
    position: relative;
    user-select: none;

    & + span {
        display: none;
        color: #f04;
        line-height: 1.2;
        font-size: 13px;
        padding-bottom: 15px;

        & + span {
            display: none;
            color: $green;
            line-height: 1.2;
            font-size: 13px;
            padding-bottom: 15px;
        }
    }

    .label-stable {
        //font-family:   $fontLight;
        font-size: 13px;
        display: block;
        margin-bottom: 10px;
        color: $grey;
    }

    .form-field-example {
        display: block;
        margin-top: 10px;
        font-size: 11px;
        color: rgba($base, .5);
    }

    .label-common {
        display: block;
        font-size: 14px;
        color: rgba($base, .8);

        span {
            color: rgba(#94bfed, .8);
        }
    }

    input[type="file"] {
        position: absolute;
        top: 0;
        left: -10000px;
    }

    input[type="text"],
    input[type="email"],
    input[type="search"],
    input[type="number"],
    input[type="password"],
    textarea {
        height: 40px;
        width: 100%;
        border: 1px solid #d6dcdf;
        border-radius: 2px;
        padding: 0 15px;
        transition: border-color $time;

        &:focus {
            border-color: darken(#d6dcdf, 10%);
        }

        &.field-complete-good {
            border-color: #27ad5f;
            box-shadow: inset 0 1px 1px rgba(#27ad5f, .05);
        }

        &.field-complete-error {
            border-color: #bf392b;
            box-shadow: inset 0 1px 1px rgba(#bf392b, .05);
        }
    }

    textarea {
        height: 80px;
        resize: none;
        overflow: auto;
        padding: 10px 20px;
    }

    input[type="number"] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    &.ff-price {

        .dropdown-type {
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 3;
            width: 80px;

            .dt-is-list {
                font-size: 12px;
            }
        }

    }

    &.ff-mini {
        input[type="text"] {
            text-align: center;
            padding: 0;
        }
    }

    &.ff-town {
        svg {
            fill: $blue;
            width: 21px;
            height: 21px;
            position: absolute;
            top: 20px;
            left: 9px;
            margin-top: -10px;
            z-index: 1;
        }

        input[type="search"] {
            padding-left: 95px;
            border-width: 0;
            margin-top: 0;
            width: 100% !important;
            line-height: 40px;

            //&:focus {
            //    padding-left: 96px;
            //}
        }

        .select2-selection--multiple {
            border: 0;
        }

        .select2-search--inline {
            float: none;
            display: block;
        }

        .select2-selection__rendered {
            display: block !important;
            padding: 0 !important;

            li:not(.select2-search--inline) {
                display: none;
            }
        }

        label {
            position: absolute;
            font-size: 14px;
            color: #3d3d3c;
            top: 50%;
            left: 40px;
            z-index: 1;
            line-height: 1;
            margin-top: -7px;
        }

        @media screen and (max-width: 480px) {
            label {
                display: none;
            }
            input[type="search"] {
                padding-left: 30px;
            }
        }

    }

    .ff-town-autocomplete {
        position: absolute;
        top: 41px;
        background-color: #ffffff;
        left: 0;
        right: 0;
        border-radius: 2px;
        z-index: 10;
        border: 1px solid darken(#ededed, 10%);
        min-height: 30px;
        max-height: 150px;

        opacity: 0;
        transform-origin: 50% 0;
        transform: rotateX(90deg);
        visibility: hidden;
        transition: opacity $time, visibility $time, transform $time;

        &.is-open {
            opacity: 1;
            visibility: visible;
            transform: rotateX(0deg);
        }

        ul {
            display: block;
            //min-height: 30px;
            //max-height: 150px;

            li {
                display: block;
                cursor: pointer;
                line-height: 30px;
                padding: 0 15px;
                font-size: 14px;
                color: $base;
                transition: background-color $time;

                a {
                    color: inherit;
                }

                &:hover {
                    background-color: #ededed;
                }

                &.current {
                    background-color: #fff;
                    color: $base;
                }
            }
        }
    }

    &.phone-field {
        .phone_pre_mask-profile,
        .phone_pre_mask {
            display: none;
        }
    }

    &.ff-error {

        & + span {
            display: block;

            & + span {
                display: none;
            }
        }

        .label-common {
            color: $red;
        }
        input[type="text"],
        input[type="email"],
        input[type="search"],
        input[type="number"],
        input[type="password"],
        textarea {
            border-color: $red !important;
        }

        &.ff-select {
            .dt-is-trigger {
                border-color: $red;
            }
        }

    }

}

#form-field-city {
    .svg-marker {
        position: absolute;
        width: 24px;
        top: 30px;
        height: 24px;
        z-index: 1;
        left: 8px;
        fill: $blue;
    }
    .icon-marker {
        position: absolute;
        //width: 24px;
        font-size: 24px;
        top: 30px;
        //height: 24px;
        z-index: 1;
        left: 8px;
        color: $blue;
    }
}

//	Dropdown Select Style
.dropdown-type {
    display: block;
    height: 40px;
    line-height: 40px;
    position: relative;

    .dt-is-trigger {
        color: #fff;
        position: relative;

        i {
            &.icon-arrow_down {
                font-size: 9px;
                color: #fff;
                margin-left: 5px;
            }
        }

        svg {
            width: 9px;
            height: 9px;
            fill: #fff;
            margin-left: 5px;
        }

    }

    .dt-is-list {
        position: absolute;
        top: 35px;
        left: -8px;
        right: -8px;
        width: auto;
        z-index: 5;
        opacity: 0;
        transform-origin: 50% 0;
        border: 1px solid rgba(#000, .2);
        background-color: #fff;
        transform: rotateX(90deg);
        visibility: hidden;
        transition: opacity $time, visibility $time, transform $time;
    }

    .dt-is-list-arrow {
        &:before {
            @extend %before;
            border-style: solid;
            border-width: 0 7px 6px 7px;
            border-color: rgba(#000, 0) rgba(#000, 0) rgba(#000, .2) rgba(#000, 0);

            //border:              6px solid rgba(#000, 0);
            //border-left-width:   7px;
            //border-right-width:  7px;
            //border-bottom-color: rgba(#000, .01);
            //border-top:          0;
            top: -6px;
            right: 4px;
            z-index: 10;
        }
        &:after {
            @extend %before;
            border-style: solid;
            border-width: 0 7px 6px 7px;
            border-color: rgba(#000, 0) rgba(#000, 0) #fff rgba(#000, 0);
            //border:              6px solid rgba(#fff, 0);
            //border-left-width:   7px;
            //border-right-width:  7px;
            //border-bottom-color: #fff;
            //border-top:          0;
            top: -5px;
            right: 4px;
            z-index: 11;
            transition: border-color $time;
        }
    }

    ul {

        li {
            display: block;

            a {
                display: block;
                line-height: 40px;
                height: auto;
                padding: 0 8px;
                position: relative;
                color: rgba($base, .7);
                //transition:  background-color $time, color $time;
                //
                &:hover {
                    color: $base;
                    background-color: #ededed;
                }

                &.dt-is-selected {
                    a {
                        color: $base;
                    }
                }

            }

            &:first-child {
                a {
                    &:before {
                        @extend %before;
                        border-style: solid;
                        border-width: 0 7px 6px 7px;
                        border-color: rgba(#000, 0) rgba(#000, 0) rgba(#000, .2) rgba(#000, 0);
                        //border:              6px solid rgba(#000, 0);
                        //border-left-width:   7px;
                        //border-right-width:  7px;
                        //border-bottom-color: rgba(#000, .01);
                        //border-top:          0;
                        top: -6px;
                        right: 4px;
                        z-index: 10;
                    }
                    &:after {
                        @extend %before;
                        border-style: solid;
                        border-width: 0 7px 6px 7px;
                        border-color: rgba(#000, 0) rgba(#000, 0) #fff rgba(#000, 0);
                        //border:              6px solid rgba(#fff, 0);
                        //border-left-width:   7px;
                        //border-right-width:  7px;
                        //border-bottom-color: #fff;
                        //border-top:          0;
                        top: -5px;
                        right: 4px;
                        z-index: 11;
                        transition: border-color $time;
                    }

                    &:hover {
                        &:after {
                            border-bottom-color: #ededed;
                        }
                    }
                }
            }

        }

    }

    &.dropdown-type-select {

        .dt-is-list {
            top: 55px;
            left: 0;
            right: 0;
            border-color: #d6dcdf;

            ul li {
                &:first-child {
                    &:before {
                        border-bottom-color: #d6dcdf;
                        right: 8px;
                    }
                    &:after {
                        right: 8px;
                    }
                }
            }
        }

        .dt-is-trigger {
            width: 100%;
            text-align: left;
            padding: 0 30px 0 15px;
            border: 1px solid #d6dcdf;
            background-color: #fff;
            border-radius: 2px;
            font-size: 12px;
            color: $base;
            position: relative;

            i {
                &.icon-arrow_down {
                    color: #d6dcdf;
                    position: absolute;
                    top: 50%;
                    right: 10px;
                    margin-top: -4px;
                }
            }

            //svg {
            //    fill: #d6dcdf;
            //    position: absolute;
            //    top: 50%;
            //    right: 10px;
            //    margin-top: -5px;
            //}

            span {
                display: inline-block;
                height: 38px;
                line-height: 38px;
                visibility: visible;
                opacity: 1;
                transition: visibility $time, opacity $time;
            }

            ins {
                position: absolute;
                line-height: 1;
                top: -6px;
                text-decoration: none;
                left: 10px;
                padding: 0 5px;
                background-color: #fff;
                z-index: 2;
                visibility: hidden;
                opacity: 0;
                transition: visibility $time, opacity $time;
            }
        }

        &.dt-is-open {
            .dt-is-trigger.label-outside {
                span {
                    visibility: hidden;
                    opacity: 0;
                }
                ins {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }

        &.dt-is-complete {
            .dt-is-trigger {

                span {
                    visibility: visible;
                    opacity: 1;
                }
                ins {
                    visibility: visible;
                    opacity: 1;
                }

            }
        }

        ul li {
            position: relative;
            line-height: 30px;
            height: auto;
            padding: 0 8px;
            color: rgba($base, .7);
            cursor: pointer;
            transition: background-color $time, color $time;

            &:first-child {
                &:before {
                    @extend %before;
                    border-style: solid;
                    border-width: 0 7px 6px 7px;
                    border-color: rgba(#000, 0) rgba(#000, 0) rgba(#000, .01) rgba(#000, 0);

                    //border:              6px solid rgba(#000, 0);
                    //border-left-width:   7px;
                    //border-right-width:  7px;
                    //border-bottom-color: rgba(#000, .01);
                    //border-top:          0;
                    top: -6px;
                    right: 4px;
                    z-index: 10;
                }

                &:after {
                    @extend %before;
                    border-style: solid;
                    border-width: 0 7px 6px 7px;
                    border-color: rgba(#000, 0) rgba(#000, 0) #fff rgba(#000, 0);

                    //border:              6px solid rgba(#fff, 0);
                    //border-left-width:   7px;
                    //border-right-width:  7px;
                    //border-bottom-color: #fff;
                    //border-top:          0;
                    top: -5px;
                    right: 4px;
                    z-index: 11;
                    transition: border-color $time;
                }

                &:hover {
                    &:after {
                        border-bottom-color: #ededed;
                    }
                }

            }

            &:hover {
                color: $base;
                background-color: #ededed;
            }

            &.dt-is-selected {
                color: $base;
            }

        }

    }

    &.dropdown-multi-select {

        .checkbox-list {
            padding: 10px 15px;
        }

        .dt-is-list {
            top: 55px;
            left: 0;
            right: 0;
            border-color: #d6dcdf;

            .form-field {
                &:first-child {
                    &:before {
                        @extend %before;
                        border-style: solid;
                        border-width: 0 7px 6px 7px;
                        border-color: rgba(#000, 0) rgba(#000, 0) rgba(#000, .2) rgba(#000, 0);
                        top: -16px;
                        right: 4px;
                        z-index: 10;
                    }
                    &:after {
                        @extend %before;
                        border-style: solid;
                        border-width: 0 7px 6px 7px;
                        border-color: rgba(#000, 0) rgba(#000, 0) #fff rgba(#000, 0);
                        top: -15px;
                        right: 4px;
                        z-index: 11;
                    }
                }
            }
        }

        .dt-is-trigger {
            width: 100%;
            text-align: left;
            padding: 0 30px 0 15px;
            border: 1px solid #d6dcdf;
            background-color: #fff;
            border-radius: 2px;
            font-size: 12px;
            color: $base;
            position: relative;

            i {
                &.icon-arrow_down {
                    color: #d6dcdf;
                    position: absolute;
                    top: 50%;
                    right: 10px;
                    margin-top: -4px;
                }
            }

            svg {
                fill: #d6dcdf;
                position: absolute;
                top: 50%;
                right: 10px;
                margin-top: -5px;
            }

            span {
                display: inline-block;
                height: 38px;
                line-height: 38px;
                visibility: visible;
                opacity: 1;
                transition: visibility $time, opacity $time;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 80%;

                @media screen and (max-width: 480px) {
                    max-width: 200px;
                }
            }

            ins {
                position: absolute;
                line-height: 1;
                top: -6px;
                text-decoration: none;
                left: 10px;
                padding: 0 5px;
                background-color: #fff;
                z-index: 2;
                visibility: hidden;
                opacity: 0;
                transition: visibility $time, opacity $time;
            }
        }

        &.dt-is-open {
            .dt-is-trigger.label-outside {
                span {
                    visibility: hidden;
                    opacity: 0;
                }
                ins {
                    visibility: visible;
                    opacity: 1;
                }
            }

            &.dt-is-complete {
                .dt-is-trigger.label-outside {
                    span {
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }

        }

        &.dt-is-complete {
            .dt-is-trigger {

                span {
                    visibility: visible;
                    opacity: 1;
                }
                ins {
                    visibility: visible;
                    opacity: 1;
                }

            }
        }

    }

    &.dt-is-open {
        .dt-is-list {
            opacity: 1;
            visibility: visible;
            transform: rotateX(0deg);
        }
    }

}

.button {
    display: inline-block;
    vertical-align: top;
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
    font-size: 14px;
    font-weight: 700;
    border-radius: 2px;
    transition: background-color $time;

    &.button-blue {
        color: #fff;
        background-color: $blue;

        &:hover {
            background-color: darken($blue, 5%);
        }
    }

    &.button-border {
        border: 1px solid rgba(#313131, .4);
        background-color: rgba(#fff, 0);
        color: rgba($base, .6);
        font-size: 12px;
        text-transform: uppercase;

        @media screen {
            transition: border-color $time, color $time, transform $time;
            //transform: skewX(-5deg) skewY(5deg);

            &:hover {
                border-color: rgba($base, .6);
                color: rgba($base, .8);
                //transform: skewX(0deg) skewY(0deg);
                //padding: 0 40px;
            }
        }


    }

    &.button-fw {
        padding: 0;
        text-align: center;
        width: 100%;
    }

    &:active {
        box-shadow: inset 0 1px 2px rgba(#000, .2);
    }

}

//	Checkbox-style

.ff-checkbox {
    display: block;;
    position: relative;
    line-height: 30px;

    input[type="checkbox"] {
        position: absolute;
        top: 0;
        left: -100000px;

        &:checked {
            & + label {
                &:before {
                    background-color: $blue;
                    border-color: $blue;
                }
                &:after {
                    opacity: 1;
                }
            }
        }
    }

    label {
        cursor: pointer;
        display: inline-block;
        vertical-align: top;
        padding-left: 20px;
        font-size: 12px;
        color: rgba($text_color, .6);

        &:before {
            @extend %before;
            width: 12px;
            height: 12px;
            border: 1px solid rgba($text_color, .4);
            border-radius: 2px;
            //background-color: #fff;
            top: 8px;
            left: 0;
            //background-color: #f04040;
            transition: background-color $time, border-color $time;
        }

        &:after {
            @extend %before;
            top: 9px;
            left: 4px;
            width: 4px;
            height: 8px;
            border: 2px solid #fff;
            border-left: 0;
            border-top: 0;
            transform: rotate(45deg);
            background-color: rgba(#000, 0);
            opacity: 0;
            transition: opacity $time;
        }

    }

    &.ff-checkbox-nofill {
        label {
            &:before {
                width: 16px;
                height: 16px;
                background-color: rgba(#fff, 0);
                transition: background-color $time, border-color $time;
            }
            &:after {
                border: 1px solid $blue;
                border-left: 0;
                border-top: 0;
                top: 11px;
                left: 6px;
            }
        }

        input[type="checkbox"] {
            &:checked {
                & + label {
                    &:before {
                        background-color: rgba(#fff, 0);
                        border-color: $blue;
                    }
                    &:after {
                        opacity: 1;
                    }
                }
            }
        }
    }
}

.section-super-filter {

    .form-field.ff-town {
        //input[type=search] {
        //    border-color:
        //}
    }

}
