//  Profile Style

.user-card {
    background-color: #2b3138;

    .row {
        @include row-grid(0);
    }

    figure {
        @extend %ibt;
        width: 440px;
        min-height: 110px;
        margin-left: 0;
        position: relative;
        //background-color: #353b42;

        &.is-photo-inside {
            padding-left: 135px;
        }

        figcaption {
            padding-top: 15px;
            padding-bottom: 10px;
            font-size: 10px;
            color: rgba(#fff, .8);

            p {
                font-size: 12px;
                margin-bottom: 10px;
                color: $green;
            }

            h2 {
                display: block;
                position: relative;
                font-size: 22px;
                font-weight: 400;
                color: #fff;
                margin-bottom: 5px;

                button {
                    width: 30px;
                    height: 30px;
                    color: rgba(#fff, .2);
                    font-size: 16px;


                    @media screen {
                        transition: color $time;

                        &:hover {
                                color: #fff;
                        }
                    }
                }
            }

        }

        .progress-confidence {
            padding-right: 20px;
            margin-bottom: 0;
            p {
                font-size: 10px;
                margin-bottom: 5px;
                color: rgba(#fff, .8);
            }

            div {
                height: 5px;
                border: 1px solid rgba(#fff, .3);
                border-radius: 5px;
                background-color: transparent;

                span {
                    height: 3px;
                    display: block;
                    width: 30%;
                    border-radius: 3px;
                    background-color: #aeccec;
                }
            }

        }

        .user-thumb {
            width: 80px;
            height: 80px;
            padding: 3px;
            position: absolute;
            left: 25px;
            top: 50%;
            margin-top: -38px;
            background-color: #fff;
            border-radius: 45px;

            img {
                width: 74px;
                height: 74px;
                object-fit: cover;
                border-radius: 40px;
            }
        }

    }

    dl {
        @extend %ibt;
        width: calc((100% - 440px) / 3);
        height: 110px;
        font-size: 12px;
        text-align: center;
        position: relative;

        @media screen {
            b {
                transition: transform $time*10;
                transform: scale(1);
            }

            &:hover {
                b {
                    transform: scale(1.2);
                }
            }
        }

        dt {
            padding-top: 20px;
            color: rgba(#fff, .7);
            font-size: 12px;

            b {
                font-size: 22px;
                font-weight: 400;
                padding: 9px 0;
                color: #fff;
                display: block;

                small {
                    font-size: 14px;
                }

                //&.greent-text {
                //    color: $green;
                //}
                //
                &.blue-text {
                    color: $blue;
                }
            }
        }

        dd {
            position: absolute;
            bottom: 5px;
            z-index: 1;
            left: 0;
            width: 100%;
            text-align: center;
            height: 40px;
            line-height: 40px;

            a {
                display: inline-block;
                height: 20px;
                line-height: 20px;
                width: 100px;
                border-radius: 2px;
                background-color: $green;
                vertical-align: middle;
                color: #fff;
                font-size: 10px;
                font-weight: 700;
                text-transform: uppercase;

                &.bg-blue {
                    background-color: $blue !important;
                }

                @media screen {
                    transition: background-color $time, color $time;

                    &:hover {
                        background-color: darken($green, 5%);

                        &.bg-blue {
                            background-color: darken($blue, 5%) !important;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 800px) {

        figure {
            width: 100%;

            .progress-confidence {
                padding-right: 0;
            }

            .button-edti-profile {
                position: absolute;
                top: 0;
                right: -6px;

                svg {
                    fill: #fff;
                }
            }
        }

        dl {
            width: calc((100% / 3) - 0px);
        }
    }

    @media screen and (max-width: 480px) {
        h2 {
            font-size: 18px;
        }
        figure {

            &.is-photo-inside {
                padding-left: 100px;
            }

            .user-thumb {
                width: 60px;
                height: 60px;
                padding: 3px;
                position: absolute;
                left: 15px;
                top: 20px;
                margin: 0;

                img {
                    width: 54px;
                    height: 54px;
                }
            }
        }
        dl {
            width: 100%;
        }

        & + .section {
            & > .container {
                padding: 0;
            }
        }
    }
}

.notification {

    .noti-item {
        position: relative;
        padding: 20px 50px;
        margin-bottom: 20px;

        p {
            font-weight: 700;
            color: rgba(#fff, .8);
        }

        i.icon-info {
            font-size: 20px;
            color: #fff;
            position: absolute;
            top: 50%;
            margin-top: -11px;
            left: 20px;
        }

        //svg {
        //    width: 20px;
        //    height: 20px;
        //    position: absolute;
        //    top: 50%;
        //    margin-top: -11px;
        //    left: 20px;
        //    fill: #fff;
        //}

        .noti-remove {
            position: absolute;
            width: 40px;
            height: 40px;
            top: 50%;
            margin-top: -20px;
            right: 15px;
            font-size: 14px;
            color: rgba(#fff, .4);
            transition: color $time;
            //
            //svg {
            //    width: 14px;
            //    height: 14px;
            //    left: 50%;
            //    margin-top: -7px;
            //    margin-left: -7px;
            //    fill: rgba(#fff, .4);
            //    transition: fill $time;
            //}

            &:hover {
                //svg {
                    color: rgba(#fff, .8);
                //}
            }
        }
    }

}

.pt-security {
    border: 1px dashed $green;
    background-color: #fff;
    padding: 20px 20px 10px;
    font-size: 14px;
    color: rgba($base, .8);

    div {
        position: relative;
        padding-left: 30px;
        height: 40px;
        line-height: 40px;

        &:first-child {
            line-height: 16px;
            height: 45px;
            margin-bottom: -10px;

            i {
                margin-top: -20px;
            }
        }
    }

    i {
        font-size: 20px;
        color: $green;
        position: absolute;
        top: 50%;
        margin-top: -10px;
        left: 0;
    }
    //
    //svg {
    //    width: 20px;
    //    height: 20px;
    //    fill: $green;
    //    position: absolute;
    //    top: 50%;
    //    margin-top: -10px;
    //    left: 0;
    //}

    @media screen and (max-width: 970px) {
        display: none;
    }
}

.profile-tab {
    padding-top: 40px;
    @include row-grid(0);

    .pt-nav {
        @extend %ibt;
        width: 230px;
        padding-right: 20px;
        margin-bottom: 20px;

        .pt-tab-link {
            border-top: 1px solid rgba(#ccc,.2);

            &:first-child {
                border-top: 0;
            }
        }

        a {
            font-size: 14px;
            font-weight: 400;
            color: rgba($base, .5);
            display: block;
            line-height: 50px;
            padding: 0 20px;

            i {
                font-size: 20px;
                width: 20px;
                text-align: center;
                vertical-align: text-bottom;
                margin-right: 5px;
            }
            //svg {
            //    width: 20px;
            //    height: 20px;
            //    fill: rgba($base, .5);
            //    vertical-align: text-bottom;
            //    margin-right: 5px;
            //}

            @media screen {
                transition: color $time;

                //i {
                //    transition: color $time;
                //}

                &:hover {
                    color: $base;
                    i {
                        color: $mblue;
                    }
                }
            }

            &.is-active {
                background-color: #fff;
                color: $base;
                i {
                    color: $mblue;
                }
            }
        }

        .add-object {
            margin: 25px 0;
            padding: 0;
            width: 100%;
            text-align: center;
        }

    }

    .pt-content {
        @extend %ibt;
        width: calc(100% - 230px);
        font-size: 12px;
        margin-bottom: 20px;

        .white-bg,
        .bg-white {
            background-color: #fff;
            padding: 20px 20px 30px;
        }
    }

    @media screen and (max-width: 970px) {
        padding-top: 20px;

        .pt-nav {
            width: 72px;

            a {
                padding: 0;
                width: 100%;
                text-align: center;

                svg {
                    margin: 0;
                }

                span {
                    display: none;
                }
            }

            button {
                font-size: 24px;

                span {
                    display: none;
                }
            }
        }

        .pt-content {
            width: calc(100% - 72px);

            .pt-balance .white-bg {
                padding: 0;
            }
        }
    }

    @media screen and (max-width: 480px) {
        .pt-nav {
            //@include row-grid(0px);
            display: block;
            width: 100%;
            margin-bottom: 20px;
            padding-right: 0;

            a {
                @include grid-item(6, 0px, 0px);
                padding: 0;
                text-align: center;
                border: 0 !important;

                svg {
                    margin-right: 0;
                }
            }

            .add-object {
                @include grid-item(6, 0px, 0px);
                height: 50px;
                border-radius: 0;
                margin: 0;
                float: right;
            }

        }
        .pt-content {
            width: 100%;
            margin-bottom: 0;

            .white-bg,
            .bg-white {
                background-color: #fff;
                padding: 20px 10px 30px;
            }
        }
    }
}

.pt-item {
    position: absolute;
    top: -100000px;
    left: -10000000px;

    &.is-active {
        position: relative;
        top: auto;
        left: auto;
    }

    .pt-heading {
        font-size: 18px;
        font-weight: 700;
        color: rgba($base, .5);
        padding-bottom: 15px;
        border-bottom: 1px solid #ededed;
        margin-bottom: 20px;
    }

    &.pt-profile {
        .form-field {
            position: relative;
            padding-left: 185px;
            margin-bottom: 20px;

            .label-common {
                position: absolute;
                top: 0;
                left: 0;
                width: 160px;
                text-align: right;
                line-height: 40px;
            }

            .dropdown-type {
                width: auto;
                display: inline-block;
            }

            &.ff-profile-avatar {
                min-height: 100px;

                figure {
                    display: block;
                    width: 80px;
                    height: 80px;
                    position: absolute;
                    left: 52px;
                    top: 20px;
                    cursor: pointer;
                    overflow: hidden;

                    i {
                        position: absolute;
                        top: 0;
                        left: 0;
                        //width: 80px;
                        //height: 80px;
                        font-size: 80px;
                        color: rgba($base, .2);
                        z-index: 1;
                        transition: color $time, opacity $time, visibility $time;
                    }

                    //svg {
                    //    position: absolute;
                    //    top: 0;
                    //    left: 0;
                    //    width: 80px;
                    //    height: 80px;
                    //    fill: rgba($base, .2);
                    //    z-index: 1;
                    //    transition: fill $time, opacity $time, visibility $time;
                    //}

                    input {
                        position: absolute;
                        top: 0;
                        right: 0;
                        font-size: 600px;
                        opacity: 0;
                        cursor: pointer;
                        z-index: 3;
                    }

                    img {
                        display: block;
                        left: 0;
                        position: absolute;
                        width: 80px;
                        height: 80px;
                        border-radius: 40px;
                        z-index: 2;
                        //opacity: 0;
                        //visibility: hidden;
                        transition: opacity $time;
                        //
                        //&.is-complete {
                        //    opacity: 1;
                        //    visibility: visible;
                        //}
                    }

                    &:hover {
                        i {
                            color: rgba($base, .5);
                        }
                        img {
                            opacity: .8;
                        }
                    }

                }

                .ff-social {
                    @include row-grid(0);
                    width: 310px;
                    position: absolute;
                    top: 0;
                    right: 0;

                    p {
                        font-size: 12px;
                        color: rgba($base, .5);
                        font-weight: 700;
                        line-height: 15px;
                        margin-bottom: 10px;

                        &.pop_up_error {
                            display: none;
                            color: $red;
                        }
                    }

                    button {
                        width: calc(50% - 7px);
                        margin-right: 14px;
                        color: #fff;
                        font-size: 12px;
                        text-transform: uppercase;
                        transition: background-color $time;

                        i {
                            font-size: 17px;
                            margin-right: 5px;
                            vertical-align: text-bottom;
                        }
                        //svg {
                        //    fill: #fff;
                        //    width: 17px;
                        //    height: 17px;
                        //    vertical-align: text-bottom;
                        //    margin-right: 5px;
                        //}

                        & + button {
                            margin-right: 0;
                        }

                        &.fb-acc {
                            background-color: $fbcolor;

                            i {
                                font-size: 13px;
                                transform: translateY(-2px);
                            }
                            //svg {
                            //    width: 13px;
                            //    height: 13px;
                            //    transform: translateY(-2px);
                            //}
                            &:hover {
                                background-color: darken($fbcolor, 10%);
                            }
                        }

                        &.vk-acc {
                            background-color: $vkcolor;
                            &:hover {
                                background-color: darken($vkcolor, 10%);
                            }
                        }
                    }
                }

                .button-load-avatar {
                    height: 40px;
                    line-height: 40px;
                    padding: 0 20px;
                    display: inline-block;
                    vertical-align: top;
                    color: #fff;
                    font-size: 14px;
                    border-radius: 2px;
                    cursor: pointer;
                    margin-top: 40px;
                    background-color: $blue;
                    transition: background-color $time;

                    &:hover {
                        background-color: darken($blue, 10%);
                    }
                }

                &.is-complete {
                    figure {
                        i {
                            opacity: 0;
                            visibility: hidden;
                        }
                        img {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }

            }

        }

        .form-submit {
            padding-left: 185px;
            margin: 30px 0 30px 50px;
        }

        @media screen and (max-width: 960px) {
            .form-field {
                &.ff-profile-avatar {
                    min-height: initial;
                    .ff-social {
                        position: relative;
                        margin-top: 10px;
                        top: 0;
                        left: 0;
                        right: auto;
                        width: 100%;
                    }
                    .button-load-avatar {
                        margin-top: 10px;
                    }
                }
            }

        }
        @media screen and (max-width: 600px) {
            .form-field {
                padding-left: 0;

                .label-common {
                    position: relative;
                    top: 0;
                    left: 0;
                    line-height: 30px;
                    display: block;
                    width: 100%;
                    text-align: left;
                }

                &.ff-profile-avatar {
                    figure {
                        position: relative;
                        top: 0;
                        left: 0;
                    }
                }
            }
            .form-submit {
                padding-left: 0;
                margin-left: 0;
            }
        }

    }

}

.pt-post {

}

.pt-nav-style {
    position: relative;
    padding: 0 0 10px 0;
    margin-bottom: 40px;
    border-bottom: 1px solid #ededed;

    button {
        font-size: 14px;
        color: $grey;
        margin: 0 5px;
        text-transform: lowercase;
        position: relative;
        transition: color $time;
        padding: 0 15px;

        &:before {
            @extend %before;
            bottom: -11px;
            left: 0;
            width: 0;
            height: 1px;
            background-color: $blue;
            transition: width $time;
        }

        &:hover {
            color: $blue;
        }

        &.is-active {
            color: $blue;

            &:before {
                width: 100%;
            }
        }
    }

    &.pt-post-nav {
        margin-bottom: 0;

        @media screen and (max-width: 970px) {
            padding-top: 0;
            text-align: left;

            button {
                & {
                    margin: 0;
                }
            }
        }

        @media screen and (max-width: 480px) {
            button {
                padding: 0 7px;
                font-size: 12px;
            }
        }
    }

    &.pt-message-nav {
        text-align: right;

        button {
            text-align: center;

            &:first-child {
                margin-right: 0;
            }
        }

        .pt-message-count {
            font-size: 14px;
            color: rgba($base, .5);
            display: block;
            position: absolute;
            top: 50%;
            margin-top: -11px;
            left: 0;
        }

        @media screen and (max-width: 600px) {
            text-align: left;
            padding-top: 30px;

            .pt-message-count {
                top: 5px;
                margin-top: 0;
            }
        }

        @media screen and (max-width: 480px) {
            button {
                margin: 0;
                padding: 0 8px;
            }
        }
    }
}

.ppl-head-cell {
    @include row-grid(0);
    border-bottom: 2px solid rgba($base, .5);
    padding-bottom: 10px;

    b {
        @extend %ibt;
        font-size: 12px;
        color: rgba($base, .8);
        padding: 0 10px;

        &:nth-child(1) {
            width: 85px;
        }

        &:nth-child(2) {
            width: calc(100% - 85px - 100px - 150px);
        }

        &:nth-child(3) {
            width: 100px;
        }

        &:nth-child(4) {
            width: 150px;
        }
    }

    @media screen and (max-width: 900px) {
        b {
            &:nth-child(1) {
                display: none;
            }
            &:nth-child(2) {
                width: calc(100% - 100px - 150px);
            }
        }
    }

    @media screen and (max-width: 900px) {
        display: none;
    }

}

.pt-post-list {
    margin-bottom: 20px;
    min-height: calc(100vh - 200px);
}

.ppl-item {
    padding: 15px 0;
    background-color: #fff;
    position: relative;
    border-top: 1px solid #f5f5f5;

    &:first-child {
        border-top: 0;
    }

    &.is-hidden {
        display: none;
    }

    &.ppl-to-top {
        figure {
            padding-right: 155px;
        }
    }

    figure {
        position: relative;
        padding-left: 85px;
        padding-right: 155px;
        min-height: 50px;

        .pp-thumb {
            position: absolute;
            top: 0;
            left: 0;
            width: 70px;
            height: 50px;

            img {
                display: block;
                width: 100%;
                height: 50px;
                object-fit: cover;
            }
        }

        .pp-top-box {
            position: absolute;
            top: 0;
            right: 0;
            width: 150px;
            padding-left: 15px;
            padding-top: 15px;

            button {
                padding: 0;
                width: 100%;
                height: 30px;
                line-height: initial;
                font-size: 12px;
                text-align: center;
            }
        }
    }

    .pp-flat-info {
        overflow: hidden;

        li {
            display: block;
            width: 100%;
            text-overflow: ellipsis;
            font-size: 14px;
            overflow: hidden;
            line-height: 17px;
            white-space: nowrap;
        }
    }

    .pp-stat {
        width: 130px;
        float: right;
        padding: 15px 15px 0 10px;
        margin: 0;
        line-height: 17px;
        color: rgba($base, .5);
        position: relative;

        &:before {
            @extend %before;
            height: 30px;
            width: 1px;
            background-color: #f6f6f6;
            z-index: 1;
            bottom: 4px;
            right: 1px;
        }

        p {
            color: rgba($base, .5);
            font-size: 12px;
            margin-bottom: 0;
            padding: 0;
        }

        i {
            position: relative;
            top: 2px;
        }

        //svg {
        //    width: 12px;
        //    height: 12px;
        //    fill: currentColor;
        //}

        span {
            color: currentColor;

            br {
                display: none;
            }
        }

        &.is-actived {
            color: $green;
        }

        &.is-no-actived {
            color: $red;
        }

        &.is-moderate {
            color: $yellow;
        }
    }

    .pp-price {
        width: 110px;
        line-height: 17px;
        color: rgba($base, .8);
        position: relative;
        padding: 14px 15px 0;
        float: right;

        &:before {
            @extend %before;
            height: 30px;
            width: 1px;
            background-color: #f6f6f6;
            z-index: 1;
            bottom: 4px;
            right: 0;
        }

        b {
            font-size: 14px;
        }
    }

    .pp-control {
        padding-right: 10px;
        overflow: auto;

        button {
            height: 20px;
            font-size: 12px;
            font-weight: 400;
            width: initial;
            margin-right: 7px;
            color: $blue;
            padding: 0;
            text-align: left;

            &:last-child {
                margin-right: 0;
            }

            svg {
                width: 13px;
                height: 13px;
                fill: $blue;
                vertical-align: baseline;
            }

            @media screen {
                transition: color $time;
                svg {
                    transition: fill $time;
                }
                &:hover {
                    color: darken($mblue, 5%);
                    svg {
                        fill: darken($mblue, 5%);
                    }
                }
            }

            &.pp-svg-view {
                svg {
                    height: 9px;
                }
            }
        }

    }

    .pp-views {
        float: right;
        line-height: 20px;
        color: rgba($base, .5);

        span {
            margin-left: 15px;
            font-size: 12px;
        }
    }

    .pp-foot {
        clear: both;
        padding-top: 10px;
        @extend %clear;
    }

    @media screen and (max-width: 800px) {
        figure {
            margin-bottom: 60px;
        }
        .pp-flat-info {
            position: absolute;
            top: 60px;
            left: 0;
        }
    }

    @media screen and (max-width: 625px) {
        .pp-flat-info {
            right: 135px;
        }
        .pp-stat {
            position: absolute;
            top: 100%;
            float: none;
            right: 0;

            &:before {
                display: none;
            }
        }

        .pp-foot {
            .pp-views {
                width: 170px;
                span {
                    white-space: nowrap;
                }
            }
        }
    }

    @media screen and (max-width: 374px) {
        &.ppl-to-top {
            figure {
                padding-right: 130px;
            }
        }
        figure {
            padding-left: 70px;

            .pp-top-box {
                width: 130px;
                padding-left: 10px;
                padding-top: 18px;
            }
        }

        .pp-price {
            width: 100px;
            padding: 17px 10px 0 15px;
        }

        .pp-flat-info {
            right: 0;
        }

        .pp-stat {
            top: -20px;
            left: 75px;
            width: initial;
            padding-right: 0;
            text-align: right;

            svg {
                position: relative;
                top: 2px;
            }

            p {
                display: inline;
                margin-right: 5px;
            }
        }

    }

}

@-moz-document url-prefix(){
    .ppl-item .pp-stat svg {
        position: relative;
        top: 2px;
    }
    .ppl-item .pp-control button.cabinet_play_btn svg {
        top: 1px;
        position: relative;
        height: 14px;
    }
}

.form-refill {
    background-color: #eeeeee;
    padding: 30px 20px;
    margin-bottom: 20px;
    position: relative;

    .form-refill-desc {
        display: block;
        position: absolute;
        bottom: -30px;
        left: 0;
        width: 100%;
        text-align: center;
        color: rgba($base, .5);
    }

    .form-field {
        padding-left: 200px;
        margin-right: 160px;

        .label-common {
            text-align: center;
            font-weight: 700;
            color: rgba($base, .5);
            width: 205px;
            font-size: 18px;
            position: absolute;
            left: 0;
            line-height: 40px;
        }

        input[type="text"] {
            margin-right: 50px;
            width: calc(100% - 50px);
            text-align: center;
            font-weight: 700;
            font-size: 18px;
            color: rgba($base, .5);
        }

        .ff-text {
            display: block;
            position: absolute;
            right: 0;
            top: 50%;
            line-height: 1;
            font-size: 18px;
            font-weight: 700;
            margin-top: -9px;
            color: rgba($base, .5);
        }

    }

    .form-submit {
        text-align: right;
        width: 140px;
        position: absolute;
        right: 30px;
        top: 33px;
    }

    @media screen and (max-width: 900px) {

        .form-field {
            padding-left: 140px;
            margin-right: 150px;

            .label-common {
                text-align: left;
                width: 140px;
            }
        }
        .form-submit {
            right: 20px;
        }
    }

    @media screen and (max-width: 600px) {
        padding-bottom: 20px;

        .form-field {
            padding-left: 0;
            margin-right: 0;
            margin-top: 20px;
            margin-bottom: 10px;

            .label-common {
                top: -40px;
                left: 0;
                width: 100%;
                font-size: 16px;
            }
        }
        .form-submit {
            position: relative;
            width: 100%;
            right: auto;
            top: 0;
            text-align: left;
        }
        .form-refill-desc {
            line-height: 1.2;
        }
    }

}

.pt-balance {
    .pt-heading {
        text-align: center;
        border-bottom: 0;
        padding: 45px 0 35px;
        margin-bottom: 0;
    }
}

.pt-balance-head {
    @include row-grid(0);
    border-bottom: 2px solid rgba($base, .5);
    padding-bottom: 10px;

    b {
        display: inline-block;
        vertical-align: top;
        font-weight: 700;
        font-size: 14px;
        text-align: center;
        color: rgba($base, .8);

        &:nth-child(1) {
            width: 140px;
        }

        &:nth-child(2) {
            width: calc(100% - 140px - 200px);
        }

        &:nth-child(3) {
            width: 100px;
        }

        &:nth-child(4) {
            width: 100px;
            text-align: left;
        }
    }

    @media screen and (max-width: 600px) {
        display: none;
    }
}

.ptb-row {
    @include row-grid(0);

    &:nth-child(2n+1) {
        background-color: rgba(#000, .05);
    }

    div {
        display: inline-block;
        vertical-align: top;
        font-size: 12px;
        line-height: 38px;
        text-align: center;

        &:nth-child(1) {
            width: 140px;
            font-size: 14px;
            color: rgba(#bababb, .8);

            b {
                font-weight: 400;
                color: rgba($base, .8);
            }
        }

        &:nth-child(2) {
            width: calc(100% - 140px - 200px);
            color: rgba($base, .5);
        }

        &:nth-child(3) {
            width: 100px;
        }

        &:nth-child(4) {
            width: 100px;
            text-align: left;
            font-size: 14px;

            svg {
                width: 13px;
                height: 13px;
                margin: 0 5px 0 0;

                &.svg-minus,
                &.svg-plus {
                    width: 9px;
                    height: 9px;
                    margin: 0 7px 0 2px;
                }

                &.svg-minus {
                    fill: $red;

                    & + span {
                        color: $red;
                    }
                }

                &.svg-plus {
                    fill: $green;
                }

                &.svg-winner {
                    fill: $yellow;
                    stroke: $yellow;
                }

                &.svg-gift {
                    fill: $blue;
                }

            }

        }
    }

    @media screen and (max-width: 600px) {
        div {
            display: block;
            width: 100% !important;
            text-align: left;
            padding-left: 15px;
        }
    }

}

.pt-balance-foot {
    padding: 30px 0 40px;
    font-size: 14px;
    text-align: right;
    font-weight: 700;
    color: rgba(#7e7f81, .5);

    b {
        font-size: 24px;
        color: rgba($base, .8);
    }

    small {
        font-size: 14px;
        color: rgba($base, .8);
    }

    @media screen and (max-width: 600px) {
        text-align: left;

        span {
            display: block;
        }
    }
}

.pt-message-list {
    margin-bottom: 30px;

    article {
        position: relative;
        background-color: #fff;
        border-bottom: 1px solid #ededed;
        transition: background-color $time;

        &:hover {
            background-color: rgba(#f2f8fc, .8);

            .ptml-remove {
                opacity: 1;
                visibility: visible;
            }
        }

        @media screen and (max-width: 1000px) {
            .ptml-remove {
                opacity: 1;
                visibility: visible;
                right: 0;
            }

            &:hover {
                background-color: #fff;
            }
        }
    }

    figure {
        position: relative;
        min-height: 95px;
        padding: 20px 60px 20px 20px;

        img {
            display: block;
            max-width: 55px;
            position: absolute;
            top: 20px;
            left: 20px;

            & + figcaption {
                display: block;
                width: calc(100% - 75px);
                margin-left: 75px;
            }
        }

        @media screen and (max-width: 600px) {
            padding-right: 20px;
            padding-left: 0;

            img {
                left: 0;
            }
        }
    }

    .ptml-head {
        margin-bottom: 15px;
        position: relative;

        h5 {
            font-weight: 700;
            font-size: 14px;
            color: #575757;
            margin-bottom: 5px;

            a {
                display: inline-block;
                color: #575757;
                transition: color $time;

                &:hover {
                    color: $base;
                }
            }
        }

        ins {
            text-decoration: none;
            font-size: 12px;
            color: rgba(#9b9b9b, .6);
            display: inline-block;
            vertical-align: top;
        }

        .ptml-id {
            position: absolute;
            bottom: 0;
            right: 0;
            color: $blue;
            height: 14px;
            font-size: 12px;
            line-height: 14px;
            border-bottom: 1px dashed;
            transition: color $time;

            &:hover {
                color: darken($blue, 15%);
            }
        }

    }

    .ptml-content {
        p {
            line-height: 18px;
            font-size: 14px;
            color: #9b9b9b;
        }
    }

    .ptml-remove {
        height: initial;
        position: absolute;
        top: 20px;
        right: 20px;
        visibility: hidden;
        opacity: 0;
        transition: visibility $time, opacity $time;

        svg {
            width: 12px;
            height: 12px;
            fill: rgba($base, .5);
            transition: fill $time;
        }

        &:hover {
            svg {
                fill: $base;
            }
        }
    }

    .ptml-reply {
        height: 25px;
        font-size: 12px;
        font-weight: 700;
        color: rgba($base, .5);
        transition: color $time;

        &:hover {
            color: $blue;
        }
    }

    @media screen and (max-width: 600px) {
        .ptml-head {
            .ptml-id {
                position: relative;
                right: auto;
                top: 0;
                margin-top: 5px;
            }
            ins {
                display: block;
            }
        }
    }
}

.pt-stat {

    .pt-heading {
        margin-left: -20px;
        margin-right: -20px;
        padding-left: 20px;
        margin-bottom: 35px;
        border-bottom-color: #cacaca;

        .dropdown-object {
            position: absolute;
            top: 0;
            right: 0;
            height: 54px;
            z-index: 20;

            .dt-is-trigger {
                padding: 0 20px;
                height: 54px;
                box-sizing: border-box;
                border: 1px solid;
                border-color: #fff #fff #cacaca #cacaca;
                border-radius: 0;
            }

            &.dt-is-open {
                .dt-is-trigger {
                    background-color: #eee;
                    border-color: #cacaca #cacaca #eee #cacaca;
                }
            }

            .dt-is-list {
                top: 54px;
                right: 0;
                left: auto;
                width: 320px;
                background-color: #eeeeee;
                border-top: 0;
                border-color: #cacaca;
                max-height: 300px;
                overflow-y: scroll;
            }

            .t-right {
                margin-right: 10px;
                padding: 0;

                a {
                    display: inline-block;
                    vertical-align: top;
                    font-size: 12px;
                    font-weight: 700;
                    color: $blue;
                    transition: color $time;

                    &:hover {
                        color: darken($blue, 15%);
                    }
                }
            }
        }

        @media screen and (max-width: 600px) {
            margin-bottom: 90px;

            .dropdown-object {
                width: 100%;
                left: 0;
                right: auto;
                top: 53px;

                .dt-is-trigger {
                    width: 100%;
                    border-color: #cacaca #fff;
                }

                .dt-is-list {
                    width: 100%;
                }
            }

        }

    }

    .pt-stat-info {
        font-size: 14px;
        color: rgba($base, .8);
        margin-bottom: 50px;

        span {
            font-size: 12px;
            margin-right: 30px;
            font-weight: 700;
        }
    }

}

.dropdown-object {
    .ff-checkbox-nofill {
        border-bottom: 1px solid #fff;
        margin: 0 10px;
        padding: 12px 0;

        label {
            padding-left: 120px;

            &:before {
                top: 22px;
            }

            &:after {
                top: 25px !important;
            }
        }

        p {
            font-weight: 400;
            line-height: 18px;
        }

        i {
            display: block;
            position: absolute;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: normal;
            text-align: center;
            left: 55px;
            width: 50px;
            line-height: 1;
            top: 50%;
            margin-top: -6px;
        }

        svg {
            width: 15px;
            height: 15px;
            stroke: $yellow;
            fill: $yellow;
            position: absolute;
            top: 50%;
            margin-top: -8px;
            left: 30px;
        }

    }
}

.pt-chart-control {
    position: relative;
    margin-bottom: 40px;

    .ff-checkbox {
        display: inline-block;
        vertical-align: top;
        line-height: 34px;

        label {
            font-size: 14px;
            padding-left: 25px;
        }

        & + .ff-checkbox {
            margin-left: 30px;
        }
    }

}

.pt-chart-view {
    position: relative;
    margin-left: -15px;
    margin-right: -15px;

    .ptcc-tab {
        border-radius: 2px;
        background-color: #9f9f9f;
        padding: 1px;
        height: 30px;
        width: 214px;
        @extend %tabl;
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 1;

        button {
            height: 28px;
            padding: 0;
            width: 70px;
            margin-left: 1px;
            text-align: center;
            font-size: 11px;
            text-transform: uppercase;
            color: $grey;
            background-color: #fff;
            border-radius: 0;
            transition: background-color $time, color $time;

            &:first-child {
                border-radius: 2px 0 0 2px;
                margin-left: 0;
            }

            &:last-child {
                border-radius: 0 2px 2px 0;
            }

            &:hover {
                background-color: #f9f9f9;
            }

            &.is-active {
                background-color: #9f9f9f;
                color: #fff;
            }
        }
    }

    svg {
        .highcharts-tooltip + text[text-anchor="end"] {
            display: none;
        }
    }

    .highcharts-container {
        width: 100% !important;
    }

    .highcharts-legend {
        left: -25px !important;
    }

    .highcharts-legend-item {
        width: 235px;
        height: 34px;

        label {
            line-height: 34px;
            padding-left: 25px;
        }

        .ff-checkbox {
            margin-left: 40px;
            margin-top: -6px;

            &.is-active {
                label {
                    &:before {
                        border-color: $blue;
                    }
                    &:after {
                        opacity: 1;
                        visibility: visible;
                    }
                }

            }

        }

    }

    @media screen and (max-width: 800px) {
        .ptcc-tab {
            right: auto;
            left: 15px;
            top: -30px;
        }
    }
}

.save_div_message {
    display: inline-block;
    line-height: 40px;
    padding: 0 20px;
    opacity: 0;

    @media screen and (max-width: 625px) {
        display: block;
    }
}

#profile_info_form {
    .form-submit {
        @media screen and (max-width: 625px) {
            text-align: center;
        }
    }
}