//	Footer

.footer {
    background-color: $base;
    color: #fff;
    padding: 60px 0 50px;
    position: relative;
    backface-visibility: hidden;
    box-shadow: inset 0 1px 4px rgba(#000, .8);

    .container {
        position: relative;
    }

    .row {
        @include row-grid(0);

        .social-link {
            @extend %ibt;
            width: 145px;

            @media screen and (max-width: 760px) {
                position: absolute;
                top: 100px;
                right: 70px;
            }
        }

    }

    .foot-nav {
        @extend %ibt;
        @extend %clear;
        width: calc(100% - 145px - 165px - 200px);

        @media screen and (max-width: 760px) {
            width: calc(100% - 165px - 200px);
        }
        @media screen and (max-width: 600px) {
            display: none;
        }

        ul {
            display: block;
            float: left;
            margin-left: 50px;
            width: calc(50% - 50px);
            list-style-type: none;

            li {
                display: block;
                margin-bottom: 10px;
            }

            a {
                @extend %ibt;
                font-size: 13px;
                color: #fff;
                line-height: 18px;
                position: relative;
                white-space: nowrap;
            }

            @media screen and (max-width: 1000px) {
                width: calc(40% - 50px);
            }

            @media screen and (max-width: 860px) {
                width: calc(100% - 70px);
                float: none;
                margin-left: 70px;
            }

            @media screen and (max-width: 760px) {
                width: calc(100% - 40px);
                float: none;
                margin-left: 40px;
            }

        }
    }

    .foot-creator {
        display: block;
        position: absolute;
        top: 70px;
        left: 0;
        width: 165px;
        color: #4b4e52;
        font-size: 10px;
        line-height: 14px;

        @media screen and (max-width: 1000px) {
            left: 15px;
        }

        a {
            display: inline-block;
            vertical-align: top;
            color: inherit;
            position: relative;
        }
    }

    .foot-copyright {
        @extend %ibt;
        width: 165px;
        color: #4b4e52;
        font-size: 10px;
        line-height: 14px;

        .logo-foot {
            width: 108px;
            height: 47px;
            position: relative;
            display: block;
            overflow: hidden;
            background-color: #3f4144;
            margin-bottom: 20px;

            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: url(../../img/logo-dark__hard.png) center no-repeat;
                background-size: cover;
                z-index: 3;
            }

            &:after {
                content:             '';
                display:             block;
                position:            absolute;
                top:                 0;
                left:                0;
                width:               100%;
                height:              94px;
                background: url(../../img/logo-dark__hard-hover.png) 0 0 no-repeat;
                background-size:     cover;
                z-index:             2;
                //transform: translateY(0) translateZ(0);
            }

            @media screen {
                &:before {
                    //transition: transform $time;
                }
                &:after {
                    transition: top $time;
                }
                &:hover {
                    &:before {
                        //transform: translateY(-100%);
                    }
                    &:after {
                        //transform: translateY(-50%);
                        top: -47px
                    }
                }
            }
        }

        img {
            display: block;
            max-height: 47px;
            width: auto;
            visibility: hidden;
        }

    }

    .foot-service {
        @extend %ibt;
        width: 200px;
        font-size: 13px;
        line-height: 18px;

        span {
            display: block;
            font-size: 16px;
            font-weight: 700;
            color: rgba(#fff, .4);
            padding: 13px 0 11px;
        }

        a {
            display: inline-block;
            vertical-align: top;
            color: #fff;
            position: relative;
            white-space: nowrap;
        }

    }

    @media screen and (max-width: 600px) {

        .foot-copyright,
        .foot-nav,
        .foot-creator,
        .foot-service,
        .row .social-link {
            display: block;
            width: auto;
            margin: 0 auto 30px;
            position: relative;
            text-align: center;
        }

        .row .social-link {
            width: 145px;
            top: auto;
            right: auto;
        }

        .foot-creator {
            margin-bottom: 0;
            top: auto;
            left: 0;
            position: relative;
            font-size: 12px;
            line-height: 20px;
        }

        .foot-copyright {
            display: block;

            .logo-foot {
                margin: 0 auto;

                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 200%;
                    background: url(../../img/logo-dark__hard-hover.png) 0 0 no-repeat;
                    background-size: 100% auto;
                    z-index: 2;
                    transform: translateY(-50%);
                }

            }
        }

        .foot-nav {
            margin-left: 0;
            width: 100%;

            ul {
                margin: 0;
                width: 100%;
            }
        }

    }

}

.social-link {
    text-align: right;

    ul {
        display: table;
        font-size: 0 !important;
        width: auto;
        padding: 1px;
        background-color: #939495;

        li {
            @extend %ibt;
            width: auto;
            margin: 0 0 0 1px;

            &:first-child {
                margin-left: 0;
            }

            //
            //svg {
            //    width: 18px;
            //    height: 18px;
            //    fill: rgba(#fff, .4);
            //    transition: fill $time;
            //    vertical-align: middle;
            //    text-overflow: ellipsis;
            //    //position: absolute;
            //    //top: 50%;
            //    //left: 50%;
            //    //transform: translate(-50%, -50%);
            //}

            a {
                display: block;
                height: 46px;
                width: 46px;
                font-size: 18px;
                position: relative;
                text-align: center;
                line-height: 46px;
                background-color: $base;
                color: rgba(#fff, .4);
                transition: color $time;

                @media screen {
                    transition: color $time;

                    &:hover {
                        color: #fff;
                    }
                }
            }

        }
    }

}



